import * as React from 'react'
import { Grid } from 'semantic-ui-react'

import Constants from '../../utils/constants'
import withSkippable from '../../enhancers/skippable'
import { RadioField } from '../formfields'
import Divider from '../system-wide/divider'

class RadiolistQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  renderColumns = () => {
    let cols = this.props.options.cols || Constants.DEFAULTS.COLS
    let colSize = Math.min(Math.floor(Constants.FULLWIDTH / cols), 16)
    let radios = []
    let items = this.props.items
    let { values: elements } = items[0] || []

    elements.forEach((elem, i) => {
      let questionSkipped = this.props.questionSkipped || false
      if (elem.type === 'separator') {
        radios.push(
          <Grid.Column key={i} mobile={Constants.FULLWIDTH}>
            <Divider disabled={this.props.disabled || questionSkipped} value={elem.value} />
          </Grid.Column>
        )
      } else {
        let radioProps = {
          itemKey: elem.key,
          colSize,
          label: elem.value,
          name: `${this.props.name}-${items[0].key}`,
          'data-name': items[0].key,
          parentDisabled: this.props.disabled,
          jsonref: elem.jsonref,
          questionName: this.props.name,
          currentPageName: this.props.currentPageName,
          hasComment: elem.type === 'comment' || elem.type === 'optionalcomment',
          questionSkipped,
        }
        radios.push(<RadioField key={`item_${i}`} {...radioProps} />)
      }
    })

    if (this.props.reverseItems) radios.reverse()
    return radios
  }

  render() {
    return <Grid.Row className="resurvey question body">{this.renderColumns()}</Grid.Row>
  }
}

export default withSkippable(RadiolistQuestionBody)
