import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import _MediaQuery from 'react-responsive'

import withSkippable from '../../../enhancers/skippable'
import { handleSkippedDataChange } from '../../../store/survey-data'
import MobileDetect from '../../../utils/mobile-detect'

import MobileBody from './mobile.js'
import Matrix from './matrix'
import VerticalMatrix from './vmatrix'

import '../matrix/index.css'

const MediaQuery = MobileDetect.isTablet ? React.Fragment : _MediaQuery

class _MSelectQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  componentDidUpdate(prevProps) {
    const pName = this.props.currentPageName
    const { allowskip } = this.props.options
    if (this.props.replyValue !== prevProps.replyValue) {
      let childQuestions = this.props.childQuestions || []
      childQuestions.forEach(q => {
        this.props.dispatch(handleSkippedDataChange(pName, q.name, this.props.replyValue, allowskip))
      })
    }
  }

  render() {
    const { direction } = this.props.options
    const isVertical = typeof direction !== 'undefined' && direction === 'vertical'
    const { dispatch, replyValue, ...props } = this.props

    return (
      <React.Fragment>
        <MediaQuery minWidth={768}>
          <Grid.Row>
            <Grid.Column>
              <div className="matrix container">
                {isVertical === false && <Matrix {...props} />}
                {isVertical === true && <VerticalMatrix {...props} />}
              </div>
            </Grid.Column>
          </Grid.Row>
        </MediaQuery>

        {MobileDetect.isTablet === false && (
          <MediaQuery maxWidth={767}>
            <MobileBody {...props} />
          </MediaQuery>
        )}
      </React.Fragment>
    )
  }
}

const SkippableMSelectBody = withSkippable(_MSelectQuestionBody)
export default SkippableMSelectBody
