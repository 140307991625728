import * as React from 'react'
import { Provider } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import get from 'lodash.get'
import { Helmet } from 'react-helmet'
import { ResurveyTheme } from 'resurvey-themes'

import { store, resetStores } from '../store'
import SurveyRenderer from './survey-renderer'
import { loadSurvey } from '../store/survey-parser'
import { setCurrentPage, setMode, setOutOfQuota } from '../store/survey-internal-state'
import strings from '../locales'
import generateTranspileFn from '../utils/babel-helpers'

const currentYear = new Date().getFullYear()

class Resurvey extends React.Component {
  static defaultProps = {
    staticLoader: 'main-loader',
    definition: {},
    outofquota: false,
    preseed: {},
    onPageSubmit: () => true,
    onSurveyCompleted: () => true,
    onSurveyStopped: () => true,
    onCheckQuota: () => ({ name: '', target: 0, value: 0, active: true, closed: false }),
    onError: () => {},
    mode: 'production',
    transpileFn: code => code,
    readOnly: false,
    forceTitles: false,
  }

  state = {
    primaryColor: '',
    headerColor: '',
    parseStatus: 'PENDING',
    hasError: false,
    errorMsg: '',
  }

  unsubscribe
  transpile

  constructor(props) {
    super(props)
    this.unsubscribe = store.subscribe(this.onReduxStoreChanged)
  }

  setError = e => {
    this.setState({ hasError: true, errorMsg: e.message })
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMsg: error.message }
  }

  componentDidCatch(error, info) {
    this.props.onError(error)
  }

  onReduxStoreChanged = () => {
    let status = store.getState().parser.status
    if (status === 'SUCCESS' || status === 'ERROR') {
      this.unsubscribe()
      this.setState({ parseStatus: status })
    }
  }

  async componentDidMount() {
    this.transpile = await generateTranspileFn(this.props.definition)
    store.dispatch(setMode(this.props.mode))
    store.dispatch(setOutOfQuota(this.props.outofquota, this.props.onSurveyCompleted))

    try {
      store.dispatch(
        loadSurvey(this.props.definition, this.props.data || {}, this.props.snapshot || {}, this.props.preseed)
      )
    } catch (error) {
      console.log('LOAD SURVEY ERROR')
    }
  }

  componentWillUnmount() {
    resetStores()
  }

  forcePage = page => {
    let initialPageIdx = store.getState().internalState.pages.findIndex(p => p.name === page)
    if (initialPageIdx >= 0) store.dispatch(setCurrentPage(initialPageIdx))
  }

  getSnapshot = () => {
    return store.getState()
  }

  scroller
  scrollToTop = () => {
    if (!this.scroller) return
    this.scroller.scrollTop = 0
  }

  render() {
    let { definition } = store.getState()
    if (definition.survey === '') return null
    let { staticLoader, cssUrl, ...surveyProps } = this.props
    let themeConfigs = get(definition, ['theme_configs'])
    let theme = this.props.theme ? this.props.theme : get(definition, ['theme'], '')
    if (!theme) theme = 'default'

    // FORCE RESURVEY ADMIN THEME INTO RETRACE AND FIX SECTION
    if (theme === 'admin-preview') themeConfigs = undefined

    if (this.state.parseStatus === 'ERROR' || this.state.hasError) {
      return (
        <React.Fragment>
          <div style={{ marginTop: '5rem', textAlign: 'center', fontFamily: 'Arial', color: '#555' }}>
            <div
              style={{
                fontSize: '3rem',
                lineHeight: '3.5rem',
                height: '3.5rem',
                fontWeight: 'bold',
                color: 'white',
                background: '#777',
                width: '3.5rem',
                borderRadius: '2rem',
                margin: 'auto',
              }}>
              !
            </div>
            <h3>{strings.unexpected_error}</h3>
            <h5>{this.state.errorMsg}</h5>
          </div>
          <style>
            {`.${staticLoader} {
                display: none;
              }`}
          </style>
        </React.Fragment>
      )
    } else if (this.state.parseStatus === 'SUCCESS') {
      return (
        <ResurveyTheme theme={theme} themeConfigs={themeConfigs} cssUrl={cssUrl} staticLoader={staticLoader}>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          </Helmet>
          <div className="main container" ref={r => (this.scroller = r)}>
            <div className="resurvey container">
              <Grid container columns={1}>
                <Grid.Column>
                  <Provider store={store}>
                    <SurveyRenderer
                      {...surveyProps}
                      scrollToTop={this.scrollToTop}
                      transpile={this.transpile}
                      onError={this.setError}
                    />
                  </Provider>
                </Grid.Column>
              </Grid>
            </div>
            <div className="resurvey footer">
              Powered by ReSurvey - &copy;
              {currentYear}
            </div>
          </div>
        </ResurveyTheme>
      )
    } else {
      return null
    }
  }
}

export default Resurvey
