import * as React from 'react'
import { Grid, Form } from 'semantic-ui-react'
import striptags from 'striptags'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import { CommentField } from './comment-field'

export class CheckboxField extends React.PureComponent {
  state = {}

  handleChange = (e, { value }) => {
    let label = striptags(this.props.label, ['img'], '').trim()
    try {
      this.props.dispatch(
        handleDataChange(
          this.props.currentPageName,
          this.props.questionName,
          this.props.name,
          value === 1 ? 1 : null,
          label ? label : '1'
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    return (
      <Grid.Column
        {...Constants.getWidths(this.props.colSize || Constants.FULLWIDTH)}
        style={withToggleStyle}
        className={this.props.className}>
        <Form.Checkbox
          id={`${this.props.currentPageName}-${this.props.questionName}-${this.props.name}`}
          name={this.props.name}
          value={this.props.replyValue === 1 ? 0 : 1}
          checked={this.props.replyValue === 1}
          {...(this.props.hasError ? { error: true } : {})}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          label={
            <label
              className={this.props.labelClassName}
              htmlFor={`${this.props.currentPageName}-${this.props.questionName}-${this.props.name}`}>
              <HTMLText value={this.props.label || ''} className={this.props.labelClassName} />
            </label>
          }
        />
        {this.props.hasComment && (
          <CommentField
            forceVisible={this.props.checked}
            name={`${this.props.name}_comment`}
            questionName={this.props.questionName}
            currentPageName={this.props.currentPageName || ''}
            jsonref={`${this.props.currentPageName}.${this.props.questionName}.${this.props.name}_comment`}
          />
        )}
      </Grid.Column>
    )
  }
}
CheckboxField.displayName = 'CheckboxField'
