import * as React from 'react'
import { connect } from 'react-redux'
import { Grid, Form } from 'semantic-ui-react'
import striptags from 'striptags'

import HTMLText from '../components/system-wide/html-text'
import { handleSkippedDataChange } from '../store/survey-data'

const mapStateToProps = (state, ownProps) => {
  let replyValue = state.data[ownProps.currentPageName][ownProps.name]['skipped']
  return {
    replyValue,
  }
}

class _SkippableWrapper extends React.PureComponent {
  state = {}

  handleChange = (e, { value }) => {
    let label = striptags(this.props.options.allowskip, ['img'], '').trim()
    try {
      this.props.dispatch(
        handleSkippedDataChange(
          this.props.currentPageName,
          this.props.name,
          value === 1 ? 1 : null,
          label ? label : '1'
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    let replyName = 'skipped'
    let replyLabel = this.props.options.allowskip
    let WrappedComponent = this.props.wrappedComponent
    return (
      <React.Fragment>
        <WrappedComponent {...this.props} questionSkipped={this.props.replyValue === 1} />
        <Grid.Row key={`skip_${this.props.name}_${replyName}`} className="resurvey question skippable">
          <Grid.Column>
            <Form.Checkbox
              id={`${this.props.currentPageName}-${this.props.name}-skipped`}
              name={replyName}
              value={this.props.replyValue === 1 ? 0 : 1}
              checked={this.props.replyValue === 1}
              disabled={this.props.disabled}
              onChange={this.handleChange}
              label={
                <label htmlFor={`${this.props.currentPageName}-${this.props.name}-skipped`}>
                  <HTMLText value={replyLabel} />
                </label>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    )
  }
}

const SkippableWrapper = connect(mapStateToProps)(_SkippableWrapper)

const withSkippable = Component => props => {
  if (typeof props.options === 'undefined' || typeof props.options.allowskip === 'undefined') {
    return <Component {...props} />
  } else {
    return <SkippableWrapper {...props} wrappedComponent={Component} />
  }
}
export default withSkippable
