import * as React from 'react'
import { connect } from 'react-redux'
import { Grid, Form } from 'semantic-ui-react'
import Constants from '../../../utils/constants'
import HTMLText from '../../system-wide/html-text'
import Interpolator from '../../../utils/interpolator'
import DebounceInput from './../utils/debounce-input'
import range from 'lodash.range'
import withToggle from '../../../enhancers/toggleable'
import { setToggleableState } from '../../../store/survey-toggleable'
import { store } from '../../../store'
import { handleChange, onToggleablesChange, onMaxRepsChanged } from './helpers'

const commentStyle = { marginBottom: '.75rem' }
const textFieldStyle = { marginTop: '0.3rem' }

const mapStateToProps = (state, ownProps) => {
  let data = state.data[ownProps.currentPageName][ownProps.questionName]
  let toggleables = state.toggleables[ownProps.currentPageName][ownProps.questionName]['repliesStatus']
  let enabledItems = Object.keys(toggleables).filter(key => toggleables[key] === true).length

  let interpolator = new Interpolator()
  let max_reps = typeof ownProps.max_reps !== 'undefined' ? interpolator.render(ownProps.max_reps.value) : false
  let max = max_reps && max_reps <= enabledItems ? max_reps : enabledItems

  let parentDisabled = ownProps.parentDisabled
  let disabled = ownProps.disabled || enabledItems === 0
  let questionStatus = state['validators']['questionStatuses'][ownProps.currentPageName][ownProps.questionName]
  let errors = state['validators']['errorList'][ownProps.currentPageName][ownProps.questionName]
  let hasError = errors.indexOf(ownProps.jsonref) !== -1 && questionStatus === 'INVALID'

  return {
    data,
    items: ownProps.items,
    enabledItems,
    max,
    disabled,
    parentDisabled,
    hasError,
    toggleables,
  }
}

class _ItemCommentLabel extends React.PureComponent {
  render() {
    let textStyle = {
      opacity: this.props.disabled || this.props.fieldDisabled ? '0.45' : '1',
    }
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    return (
      <Grid.Column className="multiple" {...Constants.getWidths(5)} style={{ ...textFieldStyle, ...withToggleStyle }}>
        <span style={textStyle} className={this.props.labelClassName}>
          <HTMLText value={this.props.label} />
        </span>
        {this.props.label && this.props.hasComment && (
          <DebounceInput
            debounceTimeout={Constants.DEBOUNCE_TIMEOUT}
            style={commentStyle}
            element={Form.Input}
            //{...(this.props.hasError ? { error: true } : {})}
            fluid
            name={`${this.props.name}_comment`}
            value={this.props.commentValue}
            maxLength={80}
            disabled={this.props.disabled || this.props.fieldDisabled}
            onChange={this.props.onChange}
          />
        )}
      </Grid.Column>
    )
  }
}

const ItemCommentLabel = withToggle(_ItemCommentLabel)

class _Select extends React.PureComponent {
  componentDidMount() {
    if (typeof this.props.commentValue === 'undefined') return
    store.dispatch(
      setToggleableState(
        this.props.currentPageName,
        this.props.questionName,
        this.props.name,
        this.props.commentValue !== ''
      )
    )
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.commentValue === 'undefined') return
    if (
      (prevProps.questionSkipped === true && this.props.questionSkipped === false) ||
      (prevProps.questionDisabled === true && this.props.questionDisabled === false) ||
      (prevProps.commentValue !== '' && this.props.commentValue === '') ||
      (prevProps.commentValue === '' && this.props.commentValue !== '')
    ) {
      store.dispatch(
        setToggleableState(
          this.props.currentPageName,
          this.props.questionName,
          this.props.name,
          this.props.commentValue !== ''
        )
      )
    }
  }

  render() {
    let selectDisabled =
      this.props.disabled ||
      this.props.fieldDisabled ||
      (this.props.hasComment && this.props.commentValue === '') ||
      false
    let options = this.props.options.map(e => {
      return (
        <option value={e} key={`key-${e}`}>
          {e}
        </option>
      )
    })
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    return (
      <Grid.Column
        className={`multiple ${this.props.className ?? ''}`}
        {...Constants.getWidths(3)}
        style={withToggleStyle}>
        <div className="select-wrapper" style={{ width: '5rem', margin: 'auto' }}>
          <select
            className={`ui form ${selectDisabled ? 'disabled' : ''} field ${
              this.props.hasError && !selectDisabled ? 'error' : ''
            }`}
            name={this.props.name}
            jsonref={this.props.jsonref}
            disabled={this.props.disabled || selectDisabled}
            onChange={this.props.onChange}
            placeholder="---"
            value={this.props.value}
            style={this.props.hasComment ? { marginTop: '1.35rem' } : {}}>
            {[
              <option value={''} key={`key-null`}>
                ---
              </option>,
              ...options,
            ]}
          </select>
        </div>
      </Grid.Column>
    )
  }
}
const Select = withToggle(_Select, 'ReplyItem')

export class _NativeSelect extends React.PureComponent {
  onChange = e => {
    if (e.nativeEvent.type === 'input')
      this.props.onChange(e, { name: this.props.name, value: e.target.value, isComment: true })
    else this.props.onChange(e, { name: this.props.name, value: e.target.value, isComment: false })
  }

  render() {
    let fieldDisabled =
      this.props.disabled ||
      this.props.questionDisabled ||
      this.props.parentDisabled ||
      this.props.questionSkipped ||
      false

    return (
      <React.Fragment key={this.props.jsonref}>
        <ItemCommentLabel {...this.props} fieldDisabled={fieldDisabled} onChange={this.onChange} />
        <Select
          {...this.props}
          fieldDisabled={fieldDisabled}
          onChange={this.onChange}
          parentDisabled={this.props.commentValue === ''}
        />
      </React.Fragment>
    )
  }
}
_NativeSelect.displayName = 'NativeSelect'

const NativeSelect = _NativeSelect

export class _RankingField extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = handleChange.bind(this)
  }

  componentDidUpdate(oldProps) {
    if (this.props.enabledItems < oldProps.enabledItems) {
      onToggleablesChange(this.props)
    } else if (this.props.enabledItems === oldProps.enabledItems && parseInt(oldProps.max) > parseInt(this.props.max)) {
      // remove last replies if max_reps is changed due to interpolation
      onMaxRepsChanged(this.props)
    }
  }

  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    let fields = this.props.items.map(item => {
      let value = Object.keys(this.props.data).find(k => this.props.data[k] === item.key) || ''
      let commentValue = this.props.data[`${item.key}_comment`]

      return (
        <Grid.Row key={item.key} className="resurvey question body" style={withToggleStyle}>
          <NativeSelect
            label={item.value}
            fluid
            hasError={this.props.hasError}
            options={range(1, parseInt(this.props.max) + 1)}
            name={item.key}
            key={item.key}
            itemKey={item.key}
            jsonref={item.jsonref}
            value={value}
            commentValue={commentValue}
            onChange={this.handleChange}
            placeholder="---"
            hasComment={item.has_comment}
            currentPageName={this.props.currentPageName}
            questionName={this.props.questionName}
            questionSkipped={this.props.questionSkipped}
            questionWidth={this.props.questionWidth}
            questionDisabled={this.props.disabled}
            className={this.props.className}
          />
        </Grid.Row>
      )
    })

    return <React.Fragment>{fields}</React.Fragment>
  }
}

export const RankingField = connect(mapStateToProps)(_RankingField)
