import * as React from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'semantic-ui-react'

import { setStopStatus } from '../store/survey-internal-state'
import strings from '../locales'

const mapStateToProps = state => {
  return {
    stopStatus: state.internalState.stopStatus,
  }
}

export class QuitConfirmModal extends React.Component {
  handleClose = async () => {
    await this.props.dispatch(setStopStatus('HIDE'))
  }

  handleConfirm = async () => {
    await this.props.dispatch(setStopStatus('CONFIRMED'))
  }

  render() {
    if (!this.props.mountNode) return null
    return (
      <Modal
        basic
        size="small"
        mountNode={this.props.mountNode}
        open={this.props.stopStatus === 'SHOW'}
        onClose={this.handleClose}
        closeOnEscape={true}
        closeOnDimmerClick={true}>
        <Modal.Header style={{ textAlign: 'center' }}>{strings.stop_confirm}</Modal.Header>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button
            basic
            inverted
            icon="cancel"
            labelPosition="right"
            content={strings.no}
            onClick={this.handleClose}
            style={{ width: '5rem', marginLeft: '0' }}
          />
          <Button
            basic
            inverted
            color="red"
            icon="checkmark"
            labelPosition="right"
            style={{ width: '5rem' }}
            content={strings.yes}
            onClick={this.handleConfirm}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

export default connect(mapStateToProps)(QuitConfirmModal)
