import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import get from 'lodash.get'

import { RatingField as _RatingField } from '../../formfields/rating-field'
import { CommentItemField } from '../../formfields/comment-field'
import Qtext from '../../qtext.js'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'
import Constants from '../../../utils/constants'

const RatingField = withToggle(connectField(_RatingField))

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.name]['_rowcomment'] }
}
class _List extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const rowKey = this.props.name.split('_row_')[1]

    return (
      <Grid.Row style={withToggleStyle}>
        {this.props.items.map(item => (
          <RatingField
            key={`item-${item.jsonref}`}
            label={item.value}
            name={item.key}
            parentDisabled={this.props.parentDisabled || this.props.disabled || this.props.rowComment === ''}
            jsonref={`${this.props.currentPageName}.${this.props.group}_col_${item.key}`}
            questionName={this.props.name}
            maxRating={this.props.maxRating}
            includeZero={this.props.includeZero}
            renderAs={this.props.renderAs}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped || false}
            className={`matrix-cell cell-${rowKey}-${item.key}`}
          />
        ))}
      </Grid.Row>
    )
  }
}
_List.displayName = '_List'

const RatingListWithComment = withToggle(connect(mstp)(_List))
const RatingList = withToggle(_List)

const MobileBody = props => {
  let childQuestions = props.childQuestions || []
  const maxRating = get(props, ['options', 'maxrating'], Constants.DEFAULTS.MAXRATING)
  const renderAs = get(props, ['options', 'render_as'], 'rating')
  const includeZero = get(props, ['options', 'include_zero'], false)

  return childQuestions.map(q => {
    const childProps = {
      key: `key-${q.name}`,
      name: q.name,
      group: props.name,
      parentDisabled: props.questionSkipped || props.disabled,
      currentPageName: props.currentPageName,
      jsonref: q.jsonref,
      items: q.items,
      maxRating,
      includeZero,
      renderAs,
      qtext: q.qtext[0],
      hasComment: typeof q.has_comment !== 'undefined',
      withToggleStyle: props.visible ? {} : { display: 'none' },
    }

    return <MobileMRatingItem {...childProps} />
  })
}

const MobileMRatingItem = withToggle(props => {
  let { qtext, hasComment, withToggleStyle, ...listProps } = props
  let List = hasComment ? RatingListWithComment : RatingList
  return (
    <React.Fragment key={props.name}>
      <Grid.Row style={withToggleStyle}>
        <Grid.Column>
          <Qtext {...qtext} {...props} />
          {hasComment === true && (
            <CommentItemField
              label=""
              disabled={props.questionSkipped || props.disabled}
              visible={props.visible}
              name={`_rowcomment`}
              questionName={props.name}
              currentPageName={props.currentPageName}
              jsonref={`${props.currentPageName}.${props.name}.comment_matrix`}
            />
          )}
        </Grid.Column>
      </Grid.Row>

      <List {...listProps} />
    </React.Fragment>
  )
})

export default MobileBody
