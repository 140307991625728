import get from 'lodash.get'
import set from 'lodash.set'
import shuffle from 'lodash.shuffle'
import without from 'lodash.without'
import cloneDeep from 'lodash.clonedeep'

import { setSurveyDefinition } from './survey-definition'
import {
  setCurrentPage,
  setExpressions,
  initExpressionValues,
  setPages,
  setItems,
  setUniqueGroups,
  setSteplist,
  setRandomizationsGroups,
  updateSurveyVersions,
} from './survey-internal-state'
import { setSurveyData } from './survey-data'
import { initValidators } from './survey-validation'
import { initToggleable } from './survey-toggleable'
import mdRender from '../utils/mdrender'
import Constants from '../utils/constants'
import strings from '../locales'

// Action types
export const PARSE_PENDING = 'survey/PARSE_PENDING'
export const PARSE_SUCCESS = 'survey/PARSE_SUCCESS'
export const PARSE_ERROR = 'survey/PARSE_ERROR'

export const statuses = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

// Initial state
export const initialState = {
  loading: false,
  status: statuses.PENDING,
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PARSE_PENDING:
      return { ...state, loading: true, status: statuses.PENDING }
    case PARSE_SUCCESS:
      return { ...state, loading: false, status: statuses.SUCCESS }
    case PARSE_ERROR:
      return { ...state, loading: false, status: statuses.ERROR }
    default:
      return state
  }
}

export const _filterEmptyPages = page => page.type === 'page' && typeof page.content !== 'undefined'

const _extractExpressions = source => {
  const exprMarks = ['displayif', 'hideif', 'enableif', 'disableif']
  let slice = {}
  exprMarks.forEach(mark => {
    if (typeof source[mark] !== 'undefined') {
      slice[mark] = source[mark]
    }
  })
  return slice
}

const _getExpressions = source => {
  const exprMarks = ['displayif', 'hideif', 'enableif', 'disableif']
  let slice = {}
  exprMarks.forEach(mark => {
    if (typeof source[mark] !== 'undefined') {
      slice[`${source.jsonref}.${mark}`] = source[mark]
    }
  })
  return slice
}

/**
 * Initialize data with values taken from a preloaded state or
 * with emty default values
 */
export const setupInitialData = (pages, preloadedState, preseed) => {
  let initialData = {}
  initialData['PRESEED'] = { ...preseed, __qtype: 'preseed' }
  initialData['TIMERS'] = { __qtype: 'timers', __total: get(preloadedState, ['TIMERS', '__total'], 0) }
  pages.forEach(page => {
    // initialize timers (excluding end pages)
    if (['__end_survey', '__stop_survey', '__outofquota_survey'].indexOf(page.name) === -1) {
      initialData['TIMERS'][`${page.name}`] = get(preloadedState, ['TIMERS', page.name], 0)
    }

    //initialize questions data
    initialData[`${page.name}`] = {}
    page.content.forEach(question => {
      let replies = {}
      replies['__qtype'] = question.type
      if (question.type === 'date') {
        let format = 0
        if (/Y+/.test(question.options.format)) format = 4
        if (/M+/.test(question.options.format)) format += 2
        if (/D+/.test(question.options.format)) format += 2
        replies['__dateFormat'] = format
      }

      if (typeof question.options !== 'undefined') {
        if (typeof question.options.allowskip !== 'undefined') {
          replies['skipped'] = get(preloadedState, [page.name, question.name, 'skipped'], null)
          replies['skipped_label'] = get(preloadedState, [page.name, question.name, 'skipped_label'], '')
        }
      }
      if (['matrix', 'mmatrix', 'mrating', 'mnumber', 'mtext', 'mranking', 'mselect'].indexOf(question.type) !== -1) {
        initialData[`${page.name}`][`${question.name}`] = replies
        return
      }

      if (['dragndrop', 'ranking'].indexOf(question.type) !== -1) {
        let defaultValue = null
        for (let i = 1; i <= question.items.length; i++) {
          replies[i] = get(preloadedState, [page.name, question.name, i], defaultValue)
          replies[`${i}_label`] = get(preloadedState, [page.name, question.name, `${i}_label`], '')
          if (question.items[i - 1].has_comment) {
            let key = question.items[i - 1].key
            replies[`${key}_comment`] = get(preloadedState, [page.name, question.name, `${key}_comment`], '')
          }
          if (typeof question.has_comment !== 'undefined') {
            replies['_rowcomment'] = get(preloadedState, [page.name, question.name, '_rowcomment'], '')
          }
          initialData[`${page.name}`][`${question.name}`] = replies
        }
        return initialData
      }

      question.items.forEach(item => {
        // discard separators
        if (item.type !== 'separator') {
          let defaultValue = ''
          if (
            [
              'number',
              'rating',
              'radiolist',
              'checkboxlist',
              'select',
              'script',
              'date',
              'searchtable',
              'search',
              'video',
            ].indexOf(question.type) !== -1
          ) {
            defaultValue = null
          }

          if (question.type === 'geolocation') {
            defaultValue = {
              latitude: null,
              longitude: null,
              accuracy: null,
              isGeolocationAvailable: true,
              isGeolocationEnabled: false,
            }
          }

          replies[item.key] = get(preloadedState, [page.name, question.name, item.key], defaultValue)

          //TODO fix this
          if (
            ['text', 'number', 'memo', 'rating', 'select', 'date', 'time'].indexOf(question.type) !== -1 &&
            typeof item.has_comment !== 'undefined'
          ) {
            replies[`${item.key}_comment`] = get(preloadedState, [page.name, question.name, `${item.key}_comment`], '')
          }
          if (
            ['radiolist', 'checkboxlist', 'rating', 'text', 'number', 'select'].indexOf(question.type) !== -1 &&
            typeof question.has_comment !== 'undefined'
          ) {
            replies['_rowcomment'] = get(preloadedState, [page.name, question.name, '_rowcomment'], '')
          }

          // Searchtable
          if (question.type === 'searchtable') {
            item.values.forEach(v => {
              replies[`${item.key}_label_${v.value}`] = get(
                preloadedState,
                [page.name, question.name, `${item.key}_label_${v.value}`],
                ''
              )
            })
          }

          // init label values
          if (['radiolist', 'checkboxlist', 'select', 'search'].indexOf(question.type) !== -1) {
            replies[`${item.key}_label`] = get(preloadedState, [page.name, question.name, `${item.key}_label`], '')
            if (item.type === 'comment' || item.type === 'optionalcomment') {
              replies[`${item.key}_comment`] = get(
                preloadedState,
                [page.name, question.name, `${item.key}_comment`],
                ''
              )
            }
            if (typeof item.values !== 'undefined') {
              item.values.forEach(value => {
                if (value.type === 'comment' || value.type === 'optionalcomment') {
                  if (question.type === 'radiolist' && get(question, ['options', 'use_replist'], false)) {
                    // MSELECT
                    // init comment for mselect
                    replies[`${item.key}_${value.key}_comment`] = get(
                      preloadedState,
                      [page.name, question.name, `${item.key}_${value.key}_comment`],
                      ''
                    )
                  } else {
                    replies[`${value.key}_comment`] = get(
                      preloadedState,
                      [page.name, question.name, `${value.key}_comment`],
                      ''
                    )
                  }
                }
              })
            }
          }
        }
      })

      initialData[`${page.name}`][`${question.name}`] = replies
    })
  })
  return initialData
}

/**
 * Generate the toggleables slice for questions into the given pages array
 */
export const setupToggleables = (pages, expressions) => {
  let expressionList = Object.values(expressions)
    .reduce((acc, p) => {
      return [...acc, ...Object.keys(p)]
    }, [])
    .map(k => k.replace(/\.displayif|\.hideif|\.enableif|\.disableif/, ''))
  let toggleables = {}

  pages.forEach(page => {
    toggleables[`${page.name}`] = {}
    page.content.forEach(question => {
      // setup question status
      toggleables[`${page.name}`][`${question.name}`] = {
        questionStatus: expressionList.includes(`${page.name}.${question.name}`) ? false : true,
        repliesStatus: {},
      }

      if (
        question.type === 'mmatrix' ||
        question.type === 'mrating' ||
        question.type === 'mranking' ||
        question.type === 'mtext' ||
        question.type === 'mnumber' ||
        question.type === 'matrix' ||
        question.type === 'mselect'
      )
        return
      question.items.forEach(item => {
        if (item.type === 'separator') return
        if (question.type !== 'radiolist') {
          let initialValue = expressionList.includes(`${page.name}.${question.name}.${item.key}`) ? false : true
          toggleables[`${page.name}`][`${question.name}`]['repliesStatus'][item.key] = initialValue
        }
        if (item.type === 'comment' || item.type === 'optionalcomment') {
          toggleables[`${page.name}`][`${question.name}`]['repliesStatus'][`${item.key}_comment`] = false
        }
        if (typeof item.values !== 'undefined') {
          if (question.type === 'radiolist' && get(question, ['options', 'use_replist'], false)) {
            let initialValue = expressionList.includes(`${page.name}.${question.name}.${item.key}`) ? false : true
            toggleables[`${page.name}`][`${question.name}`]['repliesStatus'][`${item.key}`] = initialValue
          }
          item.values.forEach(value => {
            if (value.type === 'separator') return
            // check comment and optional comment
            if (value.type === 'comment' || value.type === 'optionalcomment') {
              if (question.type === 'radiolist' && get(question, ['options', 'use_replist'], false)) {
                // MSELECT
                // mselect comment toggleables
                toggleables[`${page.name}`][`${question.name}`]['repliesStatus'][`${item.key}_${value.key}_comment`] =
                  false
              } else {
                toggleables[`${page.name}`][`${question.name}`]['repliesStatus'][`${value.key}_comment`] = false
              }
            }
            if (question.type === 'radiolist') {
              let initialValue = expressionList.includes(`${page.name}.${question.name}.${item.key}.:${value.key}`)
                ? false
                : true
              toggleables[`${page.name}`][`${question.name}`]['repliesStatus'][`${item.key}.:${value.key}`] =
                initialValue
            }
          })
        }
      })
    })
  })
  return toggleables
}

/**
 * Generate an object containing validations for each question
 * Adds default required validation to questions
 */
export const setupValidators = pages => {
  let validators = { configs: {}, pageStatuses: {}, questionStatuses: {}, errors: {}, errorList: {} }
  pages.forEach(page => {
    validators['pageStatuses'][`${page.name}`] = 'FRESH'
    validators['configs'][`${page.name}`] = {}
    validators['questionStatuses'][`${page.name}`] = {}
    validators['errors'][`${page.name}`] = {}
    validators['errorList'][`${page.name}`] = {}

    page.content.forEach(question => {
      validators['configs'][`${page.name}`][`${question.name}`] = []
      validators['questionStatuses'][`${page.name}`][`${question.name}`] = 'INACTIVE'
      validators['errors'][`${page.name}`][`${question.name}`] = []
      validators['errorList'][`${page.name}`][`${question.name}`] = []

      let options = get(question, ['options'], {})

      let requiredDefaultValue = question.type === 'info' ? false : true
      validators['configs'][`${page.name}`][`${question.name}`].push({
        type: 'required',
        value: get(question, ['options', 'required'], requiredDefaultValue),
      })

      if (typeof options.min !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'min',
          value: options.min.value,
        })
      }
      if (typeof options.max !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'max',
          value: options.max.value,
        })
      }
      if (typeof options.min_sum !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'min_sum',
          value: options.min_sum.value,
          msg: options.min_sum.msg,
        })
      }
      if (typeof options.max_sum !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'max_sum',
          value: options.max_sum.value,
          msg: options.max_sum.msg,
        })
      }
      if (typeof options.exact_sum !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'exact_sum',
          value: options.exact_sum.value,
          msg: options.exact_sum.msg,
        })
      }
      if (typeof options.min_checked !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'min_checked',
          value: options.min_checked.value,
          msg: options.min_checked.msg,
        })
      }
      if (typeof options.max_checked !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'max_checked',
          value: options.max_checked.value,
          msg: options.max_checked.msg,
        })
      }
      if (typeof options.min_reps !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'min_reps',
          value: options.min_reps.value,
          msg: options.min_reps.msg,
        })
      }
      if (typeof options.max_reps !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'max_reps',
          value: options.max_reps.value,
          msg: options.max_reps.msg,
        })
      }
      if (typeof options.exact_checked !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'exact_checked',
          value: options.exact_checked.value,
          msg: options.exact_checked.msg,
        })
      }
      if (typeof options.matchemail !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'matchemail',
          msg: options.matchemail,
        })
      }
      if (typeof options.matchurl !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'matchurl',
          msg: options.matchurl,
        })
      }
      if (typeof options.matchpattern !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'matchpattern',
          pattern: options.matchpattern.pattern,
          flags: options.matchpattern.flags,
          msg: options.matchpattern.msg,
        })
      }
      if (typeof options.min_watch !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'min_watch',
          value: options.min_watch.value,
          msg: options.min_watch.msg || strings['video_min_watch_msg'],
        })
      }
      if (typeof options.valid_if !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'valid_if',
          value: options.valid_if,
        })
      }
      if (typeof options.invalid_if !== 'undefined') {
        validators['configs'][`${page.name}`][`${question.name}`].push({
          type: 'invalid_if',
          value: options.invalid_if,
        })
      }

      question.items.forEach(item => {
        // check comment and optional comment
        if (item.type === 'comment' || item.type === 'optionalcomment') {
          // implicit validator on mandatory comment
          if (item.type === 'comment') {
            validators['configs'][`${page.name}`][`${question.name}`].push({
              type: 'required',
              value: true,
              for: `${item.key}_comment`,
            })
          }
        }
        if (typeof item.values !== 'undefined') {
          item.values.forEach(value => {
            // implicit validator on mandatory comment
            if (value.type === 'comment') {
              validators['configs'][`${page.name}`][`${question.name}`].push({
                type: 'required',
                value: true,
                for:
                  question.type === 'radiolist' && get(question, ['options', 'use_replist'], false) /*IS MSELECT*/
                    ? `${item.key}_${value.key}_comment`
                    : `${value.key}_comment`,
              })
            }
          })
        }
      })
    })
  })
  return validators
}

/**
 * Parse expressions found into pages and generate an object of the form:
 * {'P0': {'P0.Q2.1.displayif': "<expression>", ...}...}
 */
export const parseExpressions = pages => {
  let expressions = {}
  pages.forEach(page => {
    // expressions for pages
    expressions[`${page.name}`] = { ..._getExpressions(page) }

    page.content.forEach(question => {
      expressions[`${page.name}`] = { ...expressions[`${page.name}`], ..._getExpressions(question) }
      // expressions for qtext
      question.qtext.forEach(qtextItem => {
        expressions[`${page.name}`] = {
          ...expressions[`${page.name}`],
          ..._getExpressions(qtextItem),
        }
      })

      // expression for items
      if (
        question.type !== 'mmatrix' &&
        question.type !== 'mrating' &&
        question.type !== 'mranking' &&
        question.type !== 'mtext' &&
        question.type !== 'matrix' &&
        question.type !== 'mnumber' &&
        question.type !== 'mselect'
      ) {
        question.items.forEach(item => {
          if (!('group' in question)) {
            expressions[`${page.name}`] = { ...expressions[`${page.name}`], ..._getExpressions(item) }
          }

          //ITEM_COMMENTS
          if (
            ['text', 'number', 'memo', 'rating', 'select', 'date', 'time'].indexOf(question.type) !== -1 &&
            typeof item.has_comment !== 'undefined'
          ) {
            let expr = `${page.name}.${question.name}.${item.key}_comment != ""`
            expressions[`${page.name}`][`${page.name}.${question.name}.${item.key}_comment.enableif`] = expr
          }

          if (
            ['radiolist', 'checkboxlist', 'rating', 'text', 'number', 'ranking', 'select'].indexOf(question.type) !==
              -1 &&
            typeof question.has_comment !== 'undefined'
          ) {
            let expr = `${page.name}.${question.name}._rowcomment != ""`
            expressions[`${page.name}`][`${page.name}.${question.name}.comment_matrix.enableif`] = expr
          }

          // check comment and optional comment
          if (item.type === 'comment' || item.type === 'optionalcomment') {
            if (question.type !== 'radiolist' && question.type !== 'checkboxlist') {
              let expr = `${page.name}.${question.name}.${item.key} != ''`
              expressions[`${page.name}`][`${page.name}.${question.name}.${item.key}_comment.displayif`] = expr
            }
            if (question.type === 'checkboxlist') {
              let expr = `${page.name}.${question.name}.${item.key} == 1`
              expressions[`${page.name}`][`${page.name}.${question.name}.${item.key}_comment.displayif`] = expr
            }
          }

          // DO NOT EXECUTE FOR MATRIX QUESTIONS
          // expressions for values inside items
          if (typeof item.values !== 'undefined') {
            item.values.forEach(value => {
              if (!('group' in question)) {
                expressions[`${page.name}`] = {
                  ...expressions[`${page.name}`],
                  ..._getExpressions(value),
                }
              }

              if (value.type === 'comment' || value.type === 'optionalcomment') {
                let expr = `${page.name}.${question.name}.${item.key} == '${value.key}'`
                if (question.type === 'radiolist' && get(question, ['options', 'use_replist'], false)) {
                  // MSELECT: expression for mselect comment
                  expressions[`${page.name}`][
                    `${page.name}.${question.name}.${item.key}_${value.key}_comment.displayif`
                  ] = expr
                } else {
                  expressions[`${page.name}`][`${page.name}.${question.name}.${value.key}_comment.displayif`] = expr
                }
              }
            })
          }
        }) // End forEach(item)
      }

      // expressions for matrix/mmatrix cols
      if (['matrix', 'mmatrix', 'mrating', 'mnumber', 'mtext', 'mranking', 'mselect'].indexOf(question.type) !== -1) {
        let values = question.values || []
        let configs = values.reduce((acc, item) => {
          return { ...acc, [item.key]: _extractExpressions(item) }
        }, {})
        values.forEach(value => {
          let expr = Object.keys(configs[value.key]).reduce((acc, mark) => {
            return { ...acc, [`${page.name}.${question.name}_col_${value.key}.${mark}`]: configs[value.key][mark] }
          }, {})

          expressions[`${page.name}`] = {
            ...expressions[`${page.name}`],
            ...expr,
          }
        })
      }

      // expressions for mselect replist items
      if (question.type === 'mselect') {
        question.options.use_replist.forEach(value => {
          expressions[`${page.name}`] = {
            ...expressions[`${page.name}`],
            ..._getExpressions({ ...value, jsonref: `${page.name}.${question.name}_rlitem.${value.key}` }),
          }
        })
      }
    }) //END forEach(questions)
  })

  return expressions
}

/**
 * Add a localized qtext message for end survey page
 */
const setQtextOnDefaultPages = _definition => {
  let definition = cloneDeep(_definition)
  definition.content = definition.content.map(page => {
    if (page.name === '__end_survey') {
      page.content[0].qtext[0].value = mdRender(strings['survey_end_msg'])
    }
    if (page.name === '__stop_survey') {
      let msg = page.content[0].qtext[0].value !== '' ? page.content[0].qtext[0].value : strings['survey_stop_msg']
      page.content[0].qtext[0].value = mdRender(msg)
    }
    if (page.name === '__outofquota_survey') {
      let msg =
        page.content[0].qtext[0].value !== '' ? page.content[0].qtext[0].value : strings['survey_outofquota_msg']
      page.content[0].qtext[0].value = mdRender(msg)
    }
    return page
  })
  return definition
}

/* *** START RANDOMIZE HELPERS *** */
const _shuffleGroupIndexes = groups => {
  const obj = {}
  for (const group in groups) {
    const indexes = Array.from({ length: groups[group] }, (_, i) => i)
    obj[group] = shuffle(indexes)
  }
  return obj
}

const _mergeGroups = (mainGroups, toMerge) => {
  for (const g in toMerge) {
    mainGroups[g] = [...(mainGroups[g] || []), toMerge[g]]
  }
}

const _generatePermutations = randomizationsGroups => {
  // calculate cardinalities
  let cardinalities = {}
  for (let g in randomizationsGroups) {
    cardinalities[g] = randomizationsGroups[g].reduce((acc, el) => {
      return Math.max(el.length, acc)
    }, 0)
  }

  // handle copy sort (eg.: G1;G0)
  let sortKeys = Object.keys(cardinalities).filter(k => /;/.test(k))
  for (let c of sortKeys) {
    let s = c.split(';')[1]
    let max = Math.max(cardinalities[s], cardinalities[c])
    cardinalities[s] = max
    cardinalities[c] = max
  }
  for (let c of sortKeys) {
    let s = c.split(';')[1]
    cardinalities[c] = cardinalities[s]
  }

  // shuffle group indexes
  let maxRandoms = _shuffleGroupIndexes(cardinalities)
  for (let c of sortKeys) {
    let s = c.split(';')[1]
    maxRandoms[c] = maxRandoms[s]
  }

  const positions = {}
  for (const g in maxRandoms) {
    positions[g] = {}
    const rem = []
    for (let i = maxRandoms[g].length; i > 0; i--) {
      positions[g][i] = without(maxRandoms[g], ...rem)
      rem.push(i - 1)
    }
  }
  return positions
}

const _generateRandomizationsGroups = definition => {
  let groups = {}
  let matrixGroups = {}
  let p = {}
  definition.content.forEach(page => {
    if (!_filterEmptyPages(page)) return false

    // aggregate pages with same rnd value
    let randValue = get(page, 'rnd', false)
    if (randValue) {
      let a = get(p, [randValue], [])
      set(p, [randValue], [...a, page.jsonref])
    }

    // aggregate questions with same rnd value
    let q = {}
    let qMatrix = {}
    page.content.forEach(question => {
      let randValue = get(question, 'rnd', false)
      if (randValue) {
        if ('group' in question) {
          // use a separate accumulator for matrix subquestions
          let a = get(qMatrix, [`${question.group}|${randValue}`], [])
          set(qMatrix, [`${question.group}|${randValue}`], [...a, question.jsonref])
        } else {
          let a = get(q, [randValue], [])
          set(q, [randValue], [...a, question.jsonref])
        }
      }

      // aggregate items with same rnd value
      let i = {}
      question.items.forEach(item => {
        let randValue = get(item, 'rnd', false)
        if (randValue) {
          let a = get(i, [randValue], [])
          set(i, [randValue], [...a, item.jsonref])
        }

        // aggregate values with same rnd value
        if (typeof item.values !== 'undefined') {
          let j = {}
          item.values.forEach(value => {
            let randValue = get(value, 'rnd', false)
            if (randValue) {
              let a = get(j, [randValue], [])
              set(j, [randValue], [...a, value.jsonref])
            }
          })
          _mergeGroups(groups, j)
        }
      })
      _mergeGroups(groups, i)
    })
    _mergeGroups(groups, q)
    _mergeGroups(matrixGroups, qMatrix)
  })
  _mergeGroups(groups, p)

  // MERGE groups AND matrixGroups
  Object.keys(matrixGroups).forEach(k => {
    let rndGroup = k.split('|')[1]
    groups[rndGroup] = [...(groups?.[rndGroup] ?? []), ...matrixGroups[k]]
  })

  return groups
}

const _getCoordFromJsonref = (definition, jsonref) => {
  let tks = jsonref.split('.')
  let pageJsonref = tks[0]
  let questionJsonref = `${tks[0]}.${tks[1]}`

  for (let pageIdx = 0; pageIdx < definition.content.length; pageIdx++) {
    let currentPage = definition.content[pageIdx]
    if (currentPage.jsonref === jsonref) {
      return ['content', pageIdx]
    }
    if (currentPage.jsonref !== pageJsonref) continue

    for (let questionIdx = 0; questionIdx < currentPage.content.length; questionIdx++) {
      let currentQuestion = currentPage.content[questionIdx]
      if (currentQuestion.jsonref === jsonref) {
        return ['content', pageIdx, 'content', questionIdx]
      }
      if (currentQuestion.jsonref !== questionJsonref) continue

      for (let itemIdx = 0; itemIdx < currentQuestion.items.length; itemIdx++) {
        let item = currentQuestion.items[itemIdx]
        if (item.jsonref === jsonref) {
          return ['content', pageIdx, 'content', questionIdx, 'items', itemIdx]
        }

        if (typeof item.values !== 'undefined') {
          for (let valueIdx = 0; valueIdx < item.values.length; valueIdx++) {
            if (item.values[valueIdx].jsonref === jsonref) {
              return ['content', pageIdx, 'content', questionIdx, 'items', itemIdx, 'values', valueIdx]
            }
          }
        }
      }
    }
  }

  throw new Error('Unable to translate jsonref')
}

const _applyRndAppend = definition => {
  definition = cloneDeep(definition)

  const _checkAndSwitchPositions = function (element, keyName = 'name') {
    let randAppend = get(element, 'rnd_append', false)
    if (randAppend) {
      randAppend.forEach((jsonref, idx) => {
        let name = jsonref.split('.').pop()
        let found = this.find(el => el[keyName] === name)
        let foundIdx = this.findIndex(el => el[keyName] === name)
        this.splice(foundIdx, 1)
        let sourceIdx = this.findIndex(el => el[keyName] === element[keyName])
        this.splice(sourceIdx + idx + 1, 0, found)
      })
    }
  }

  definition.content.forEach(function (page) {
    if (!_filterEmptyPages(page)) return false

    _checkAndSwitchPositions.call(this, page, 'name')

    page.content.forEach(function (question) {
      _checkAndSwitchPositions.call(this, question, 'name')

      question.items.forEach(function (item) {
        if (item.values) {
          item.values.forEach(function (value) {
            _checkAndSwitchPositions.call(this, value, 'key')
          }, item.values)
        } else {
          _checkAndSwitchPositions.call(this, item, 'key')
        }
      }, question.items)
    }, page.content)
  }, definition.content)

  return definition
}
/* *** END RANDOMIZE HELPERS *** */

/**
 * Shuffle elements (pages, questions and items) based on random groups
 */
export const randomize = (definition, dispatch, { prevGroups, prevPermutations }) => {
  // evaluate randomizations groups and permutations
  let groups = _generateRandomizationsGroups(definition)
  let permutations = _generatePermutations(groups)

  // restore old positions from snapshot if available
  // NOTE: due to jsonb object resorting we need to use evaluated state from survey definition
  if (Object.keys(prevPermutations).length > 0) {
    groups = Object.keys(groups).reduce((acc, k) => ({ ...acc, [k]: prevGroups[k] }), {})
    permutations = Object.keys(permutations).reduce((acc, k) => ({ ...acc, [k]: prevPermutations[k] }), {})
  }

  // exchange positions
  let newDefinition = cloneDeep(definition)
  for (let [name, groupList] of Object.entries(groups)) {
    for (let orderedJsonrefs of groupList) {
      let orderedCoords = orderedJsonrefs.map(jsonref => _getCoordFromJsonref(newDefinition, jsonref)) //eslint-disable-line
      let shuffledPositions = permutations[name][orderedJsonrefs.length]
      let shuffledJsonrefs = orderedJsonrefs.map((_jsonref, i) => orderedJsonrefs[shuffledPositions[i]])
      let shuffledElements = shuffledJsonrefs.map(
        jsonref => cloneDeep(get(newDefinition, _getCoordFromJsonref(newDefinition, jsonref))) //eslint-disable-line
      )
      // console.log(`${name}: [${orderedJsonrefs.join()}] -> [${shuffledJsonrefs.join()}]`)
      for (let i = 0; i < orderedCoords.length; i++) {
        set(newDefinition, orderedCoords[i], shuffledElements[i])
      }
    }
  }

  // apply rnd_append
  newDefinition = _applyRndAppend(newDefinition)

  dispatch(setRandomizationsGroups({ groups, permutations }))
  return newDefinition
}

/**
 * Generate an easy navigable object of items.
 * This slice is used for managing UNIQUE prop
 * e.g.: {'P0': {Q1: {...item}}}
 */
export const parseItems = pages => {
  let items = {}
  pages.forEach(page => {
    items[page.name] = {}
    page.content.forEach(question => {
      if (['matrix', 'mmatrix', 'mrating', 'mnumber', 'mtext', 'mranking', 'mselect'].indexOf(question.type) !== -1)
        return
      items[page.name][question.name] = {}
      question.items.forEach(item => {
        if (item.type !== 'separator') {
          items[page.name][question.name][item.key] = item
          if (item.type === 'comment' || item.type === 'optionalcomment') {
            items[page.name][question.name][`${item.key}_comment`] = item
          }
        }
      })
    })
  })
  return items
}

/**
 * Generate groups needed for validation of questions
 * having /unique
 */
export const parseUniqueGroups = items => {
  let uniqueGroups = {}
  for (let p in items) {
    for (let q in items[p]) {
      let itemKeys = Object.keys(items[p][q]).filter(k => !/_comment/.test(k))
      let filteredItems = []
      itemKeys.forEach(k => filteredItems.push(items[p][q][k]))
      let groups = filteredItems.reduce(
        (acc, it) => {
          if (typeof it.unique !== 'undefined') {
            return [...acc, [it.key]]
          } else {
            let [first, ...rest] = acc
            return [[...first, it.key], ...rest]
          }
        },
        [[]]
      )
      set(uniqueGroups, [p, q], groups)
    }
  }
  return uniqueGroups
}

export const loadSurvey = (_definition, data, snapshot, preseed) => dispatch => {
  dispatch({ type: PARSE_PENDING })
  let definition = cloneDeep(_definition)

  // SET SURVEY LOCALE
  let lang = get(definition, ['lang'], 'nodef') //USE BROWSER LOCALES
  lang = /default/i.test(lang) ? Constants.DEFAULT_LANG : lang
  if (lang !== 'nodef') strings.setLanguage(lang)

  try {
    let pages = []
    let versions = get(snapshot, ['internalState', 'surveyVersions'], [])

    definition.required_msg = mdRender(definition.required_msg || strings['survey_required_msg'])
    definition.required_comment_msg = mdRender(
      definition.required_comment_msg || strings['survey_required_comment_msg']
    )
    definition.number_min_msg = mdRender(definition.number_min_msg || strings['survey_number_min_msg'])
    definition.number_max_msg = mdRender(definition.number_max_msg || strings['survey_number_max_msg'])
    definition.survey_completed_msg = mdRender(definition.survey_completed_msg || strings['survey_end_msg'])
    definition.survey_title = mdRender(definition.survey_title || '')
    definition = setQtextOnDefaultPages(definition)

    let prevGroups = {}
    let prevPermutations = {}

    if (versions.length && versions[versions.length - 1] === definition.version) {
      prevGroups = get(snapshot, ['internalState', 'randomizations', 'groups'], {})
      prevPermutations = get(snapshot, ['internalState', 'randomizations', 'permutations'], {})
    }

    definition = randomize(definition, dispatch, { prevGroups, prevPermutations })

    pages = definition.content.filter(_filterEmptyPages)
    let items = parseItems(pages)
    let uniqueGroups = parseUniqueGroups(items)
    let expressions = parseExpressions(pages)
    let validators = setupValidators(pages)
    let toggleables = setupToggleables(pages, expressions)
    let initialData = setupInitialData(pages, data, preseed)

    dispatch(setPages(pages))
    dispatch(setItems(items))
    dispatch(setUniqueGroups(uniqueGroups))
    dispatch(setExpressions(expressions))
    dispatch(initValidators(validators))
    dispatch(initToggleable(toggleables))
    dispatch(setSurveyData(initialData))
    dispatch(setSurveyDefinition(definition))

    let stepList = get(snapshot, ['internalState', 'stepList'], [])
    dispatch(setSteplist(stepList))

    let lastLeavedPageIdx = get(snapshot, ['internalState', 'currentPageIdx'], -1)
    let oldToggleables = get(snapshot, ['toggleables'], false)
    let oldExpressionValues = get(snapshot, ['internalState', 'expressionValues'], false)
    // check if snapshot don't contains old randomizations objects (positions, roundingGroups, matrixRoundingGroups)
    let hasNewRandomizations = get(snapshot, ['internalState', 'randomizations', 'positions'], false) === false

    let currentPageIdx = 0
    if (
      versions.length &&
      versions[versions.length - 1] === definition.version &&
      lastLeavedPageIdx !== -1 &&
      oldToggleables !== false &&
      oldExpressionValues !== false &&
      hasNewRandomizations === true
    ) {
      console.log('REYDRATE SAVED REDUX STATE')
      // REYDRATE expressionValues AND toggleables
      dispatch(initToggleable(oldToggleables))
      dispatch(initExpressionValues(oldExpressionValues))
      // RESTART FROM LAST LEAVED PAGE

      let isSubsurvey = typeof pages[lastLeavedPageIdx].content.find(q => q.type === 'subsurvey') !== 'undefined'
      if (isSubsurvey) {
        for (let i = stepList.length - 2; i >= 0; i--) {
          let pIdx = pages.findIndex(p => p.name === stepList[i])
          if (
            typeof pages[pIdx].page_options.execute === 'undefined' &&
            typeof pages[pIdx].content.find(q => q.type === 'subsurvey') === 'undefined'
          ) {
            currentPageIdx = pIdx
            break
          }
        }
      } else {
        currentPageIdx = lastLeavedPageIdx
      }
      let exitType = get(snapshot, ['internalState', 'exitType'], '')
      let customEndPage = get(snapshot, ['internalState', 'customEndPage'], '')
      if (['COMPLETED', 'SCREENOUT'].includes(exitType) && customEndPage !== '') {
        currentPageIdx = pages.findIndex(p => p.name === customEndPage)
      }
      if (exitType === 'OUTOFQUOTA') {
        currentPageIdx = pages.findIndex(p => p.name === '__outofquota_survey')
      }
    }
    dispatch(setCurrentPage(currentPageIdx))

    if (!!definition.version && !versions.includes(definition.version)) {
      versions.push(definition.version)
    }
    dispatch(updateSurveyVersions(versions))

    dispatch({ type: PARSE_SUCCESS })
  } catch (err) {
    console.log(err)
    dispatch({ type: PARSE_ERROR })
    throw new Error(err)
  }
}
export default reducer
