import * as React from 'react'
import { connect } from 'react-redux'
import HTMLText from '../../system-wide/html-text'
import { Table } from 'semantic-ui-react'

import withToggle from '../../../enhancers/toggleable'
import { getSummaryExpression } from '../../../utils/summary-helpers'

const TableCell = Table.Cell
const TableRow = Table.Row

const cla = { textAlign: 'left' }

const mstp = (state, ownProps) => {
  let rowComment = state.data[ownProps.currentPageName][ownProps.questionName]['_rowcomment']
  return {
    parentDisabled: typeof rowComment === 'undefined' ? false : rowComment === '',
  }
}
const _ColSummaryCell = props => {
  const withToggleStyle = props.visible ? {} : { display: 'none' }
  return (
    <TableCell key={props.jsonref} width={props.width} className={props.className} style={withToggleStyle}>
      <div style={{ opacity: props.disabled || props.parentDisabled ? '0.45' : '1' }}>
        <HTMLText
          id={props.jsonref + '.' + props.type}
          style={{ textAlign: 'center', width: '100%', display: 'inline-block' }}
          value={getSummaryExpression(props.type, props.format, props.cells)}
        />
      </div>
    </TableCell>
  )
}

const ColSummaryCell = withToggle(connect(mstp)(_ColSummaryCell))

class ColSummary extends React.PureComponent {
  render() {
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    let columns = []
    let colSpan = 0
    columns.push(
      <TableCell key={`${this.props.type}-0`} style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.options[this.props.type]} />
        </span>
      </TableCell>
    )

    if (this.props.isVertical) {
      colSpan = Object.keys(this.props.options).reduce(
        (acc, key) => (['colsum', 'colprod', 'colavg'].indexOf(key) !== -1 ? acc + 1 : acc),
        0
      )
      this.props.childQuestions.forEach(q => {
        let cells = q.items.map(item => item.jsonref)
        columns.push(
          <ColSummaryCell
            key={q.jsonref + '.' + this.props.type}
            jsonref={q.jsonref}
            currentPageName={this.props.currentPageName}
            questionName={q.name}
            type={this.props.type}
            cells={cells}
            format={this.props.format}
          />
        )
      })
    } else {
      colSpan = Object.keys(this.props.options).reduce(
        (acc, key) => (['sum', 'prod', 'avg'].indexOf(key) !== -1 ? acc + 1 : acc),
        0
      )

      this.props.childQuestions[0].items.forEach((item, i) => {
        let cells = this.props.childQuestions.map(q => q.items[i].jsonref)
        columns.push(
          <ColSummaryCell
            key={
              this.props.currentPageName + '.' + this.props.questionName + '_col_' + item.key + '.' + this.props.type
            }
            jsonref={this.props.currentPageName + '.' + this.props.questionName + '_col_' + item.key}
            currentPageName={this.props.currentPageName}
            questionName={this.props.questionName}
            type={this.props.type}
            cells={cells}
            format={this.props.format}
          />
        )
      })
    }

    if (colSpan > 0) {
      columns.push(<Table.Cell key="summary-pad" className="summary-pad" colSpan={colSpan} />)
    }

    return <TableRow>{columns}</TableRow>
  }
}

export default ColSummary
