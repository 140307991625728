import * as React from 'react'
import { connect } from 'react-redux'
import { Button, Checkbox, Form, Grid, Icon } from 'semantic-ui-react'
import axios from 'axios'
import { TableVirtuoso } from 'react-virtuoso'

import Constants from '../../utils/constants'
import Interpolator from '../../utils/interpolator'
import DebounceInput from '../formfields/utils/debounce-input'
import strings from '../../locales'
import withSkippable from '../../enhancers/skippable'
import { handleSearchtableDataChange } from '../../store/survey-data'

import './searchtable.css'

const interpolator = new Interpolator()

const mapStateToProps = (state, ownProps) => {
  let data = state.data[ownProps.currentPageName][ownProps.name]
  let replies = ownProps.items.reduce((acc, el) => {
    if (data[el.key] !== null) {
      acc.push(data[el.key])
    }
    return acc
  }, [])
  let itemKeys = ownProps.items.reduce((acc, el) => [...acc, el.key], [])

  let questionSkipped = ownProps.questionSkipped || false
  let gridDisabled = ownProps.disabled || questionSkipped
  let gridBodyDisabled =
    ownProps.options.hide_labels === false ? gridDisabled || replies.length === itemKeys.length : gridDisabled

  let interpolations = []
  if (typeof ownProps.options.filter !== 'undefined') {
    interpolations = ownProps.options.filter
      .split(',')
      .map(item => {
        return interpolator.render(item.trim())
      })
      .filter(v => v !== '...')
  }

  return {
    gridDisabled,
    gridBodyDisabled,
    data,
    replies,
    itemKeys,
    interpolations,
    mode: state.internalState.mode,
  }
}

class SearchtableQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  state = {
    searchFilter: '',
    noResults: false,
    data: [],
    origData: [],
    initialized: false,
  }

  async componentDidMount() {
    let url = interpolator.render(this.props.options.src)
    let tks = url.split('/')

    try {
      if (tks[0] !== '' || tks[1] !== 'media' || tks[2] === '') {
        this.throwError(`SEARCHTABLE: ${strings.error_invalid_src}`)
      }

      let fields = this.props.values.reduce((acc, v) => [...acc, v.value], [])

      let data = []
      if (this.props.mode === 'test') {
        // generate mock data
        for (let i = 1; i <= 100; i++) {
          let d = { id: `${i}` }
          fields.forEach(f => {
            d[f] = `${f}_${i}`
          })
          data.push(d)
        }
      } else {
        let response = await axios({
          method: 'post',
          url: `/resources/${url}`,
          data: { fields },
          validateStatus: null,
        })
        if (response.status !== 200) {
          this.throwError(`SEARCHTABLE: ${strings[response.data.status] || strings.network_error_msg}`)
        }
        data = response.data
      }

      if (!data.length) this.throwError(`SEARCHTABLE: ${strings.error_empty_datafile}`)

      this.applyFilters(data)
      this.setState({ initialized: true })
    } catch (e) {
      this.throwError(`SEARCHTABLE: ${strings.network_error_msg}`)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.gridDisabled !== prevProps.gridDisabled) {
      this.setState({ searchFilter: '', data: this.state.origData, noResults: false })
    }
    if (
      this.state.searchFilter !== prevState.searchFilter ||
      this.props.interpolations.toString() !== prevProps.interpolations.toString()
    ) {
      this.applyFilters()
    }
  }

  throwError = msg => {
    this.setState(() => {
      throw new Error(msg)
    })
  }

  prepareData = () => {
    let newData = {}
    Object.keys(this.props.data).forEach(k => {
      switch (true) {
        case k === '__qtype':
        case k === 'skipped':
        case k === 'skipped_label':
          newData[k] = this.props.data[k]
          break

        case /_label/.test(k):
          newData[k] = ''
          break

        default:
          newData[k] = null
      }
    })
    return newData
  }

  _handleChange = (checked, rowId) => {
    if (
      checked === true &&
      (this.props.replies.indexOf(rowId) !== -1 || this.props.replies.length === this.props.itemKeys.length)
    )
      return

    let newData = this.prepareData()
    let newReplies = checked ? [...this.props.replies, rowId] : this.props.replies.filter(el => el !== rowId)

    newReplies.forEach((r, i) => {
      let currentKey = this.props.itemKeys[i] // aa
      let currentRecord = this.state.origData.find(el => el.id === r)
      Object.keys(currentRecord).forEach(k => {
        newData[k === 'id' ? currentKey : `${currentKey}_label_${k}`] = currentRecord[k]
      })
    })

    this.props.dispatch(handleSearchtableDataChange(this.props.currentPageName, this.props.name, newData))
  }

  handleCellClick = (checked, rowId) => () => {
    if (this.props.gridDisabled || this.props.gridBodyDisabled) {
      return false
    }
    this._handleChange(!checked, rowId)
  }

  handleRemove = (e, { 'data-rowid': rowId }) => {
    this._handleChange(false, rowId)
  }

  applyFilters = (origData = this.state.origData) => {
    // filter by search terms
    let regExp = new RegExp(this.state.searchFilter, 'i')
    let newData =
      this.state.searchFilter !== ''
        ? origData.filter(el => {
            let searchable = Object.values(el).join('')
            return regExp.test(searchable)
          })
        : origData

    // filter by interpolated citations
    if (this.props.interpolations.length !== 0) {
      newData = newData.filter(el => this.props.interpolations.indexOf(el.id) === -1)
      this.props.interpolations.forEach(id => {
        if (this.props.replies.indexOf(id) !== -1) this._handleChange(false, id)
      })
    }
    this.setState({ origData, data: newData, noResults: newData.length === 0 })
  }

  handleSearchFilterChange = e => {
    this.setState({ searchFilter: e.target.value })
  }
  resetSearchFilter = () => {
    this.setState({ searchFilter: '' })
  }

  renderLabels = () => {
    return this.props.replies.map(el => {
      let currentRecord = this.state.origData.find(r => r.id === el)
      let { id, ...printedRecord } = currentRecord
      let text = Object.values(printedRecord).join(', ')
      text = text.length > 50 ? `${text.substring(0, 47)}...` : text
      return (
        <Button key={el} primary icon labelPosition="right" size="mini">
          {text}
          <Icon name="delete" data-rowid={id} onClick={this.handleRemove} />
        </Button>
      )
    })
  }

  render() {
    let { gridDisabled, gridBodyDisabled, values, replies, name, options } = this.props
    let { data, searchFilter, noResults } = this.state
    return (
      <Grid.Row className="resurvey question body" style={{ paddingLeft: '1rem' }}>
        <div className="ui icon small input input-search" style={{ marginBottom: '0.5rem' }}>
          <DebounceInput
            debounceTimeout={Constants.DEBOUNCE_TIMEOUT}
            element={Form.Input}
            {...(noResults ? { error: true } : {})}
            name={name}
            value={searchFilter}
            maxLength={80}
            disabled={gridBodyDisabled}
            onChange={this.handleSearchFilterChange}
            placeholder={strings.search_input_placeholder}
          />
          {searchFilter.trim() === '' && (
            <Icon name="search" className={gridBodyDisabled ? 'disabled' : ''} disabled={gridBodyDisabled} />
          )}
          {searchFilter.trim() !== '' && (
            <Icon
              name="close"
              link
              onClick={this.resetSearchFilter}
              className={gridBodyDisabled ? 'disabled' : ''}
              disabled={gridBodyDisabled}
            />
          )}
        </div>
        <div style={{ height: '25rem', width: '100%', marginBottom: '1rem' }}>
          <TableVirtuoso
            style={{ height: '25rem', width: '100%' }}
            data={data}
            increaseViewportBy={500}
            className={`searchtable ${gridDisabled ? 'disabled' : ''} ${gridBodyDisabled ? 'body_disabled' : ''}`}
            fixedHeaderContent={() => (
              <tr className={`${gridDisabled ? 'disabled' : ''}`}>
                <th>
                  <div />
                </th>
                {values.map((v, i) => (
                  <th key={`th-${i}`}>
                    <div>{v.value}</div>
                  </th>
                ))}
              </tr>
            )}
            itemContent={(index, data) => {
              let checked = replies.indexOf(data.id) !== -1
              return (
                <>
                  <td
                    className={`${gridBodyDisabled ? 'disabled' : ''}`}
                    style={{ background: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                    onClick={this.handleCellClick(checked, data.id)}>
                    <Checkbox disabled={gridBodyDisabled || gridDisabled} checked={checked} />
                  </td>
                  {values.map((v, i) => {
                    return (
                      <td
                        key={`td-${i}`}
                        className={`${gridBodyDisabled ? 'disabled' : ''}`}
                        style={{ background: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                        onClick={this.handleCellClick(checked, data.id)}>
                        {data[v.value]}
                      </td>
                    )
                  })}
                </>
              )
            }}
          />
        </div>
        {this.state.initialized === true && options.hide_labels === false && (
          <div className="label-container">{this.renderLabels()}</div>
        )}
      </Grid.Row>
    )
  }
}

export default withSkippable(connect(mapStateToProps)(SearchtableQuestionBody))
