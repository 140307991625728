import * as React from 'react'
import ReactPlayer from 'react-player/lazy'
import { findDOMNode } from 'react-dom'
import { Grid, Button, Icon } from 'semantic-ui-react'
import screenfull from 'screenfull'
import { isMobile } from 'react-device-detect'
import Slider from 'rc-slider'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'

import 'rc-slider/assets/index.css'

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad(string) {
  return ('0' + string).slice(-2)
}

class Player extends React.Component {
  shouldComponentUpdate(nextProps) {
    // return true
    return (
      nextProps.playing !== this.props.playing ||
      nextProps.muted !== this.props.muted ||
      nextProps.disabled !== this.props.disabled
    )
  }
  constructor(props) {
    super(props)
    this.playerRef = React.createRef()
  }

  componentDidMount() {
    this.props.setPlayer(this.playerRef.current)
  }

  render() {
    const videoStyle = {
      margin: 'auto',
      opacity: this.props.disabled ? '0.45' : '1',
      pointerEvents: this.props.disabled ? 'none' : 'unset',
    }
    return (
      <ReactPlayer
        ref={this.playerRef}
        url={this.props.url}
        muted={this.props.muted}
        playing={this.props.playing}
        onProgress={this.props.onProgress}
        progressInterval={this.props.progressInterval}
        onError={this.props.onError}
        onDuration={this.props.onDuration}
        width={this.props.width}
        style={videoStyle}
        controls={this.props.controls}
        onPlay={this.props.onPlay}
        onPause={this.props.onPause}
      />
    )
  }
}

export class VideoField extends React.Component {
  static defaultProps = {
    hasComment: false,
  }

  state = {
    playing: true,
    volume: 1,
    muted: false,
    played: 0,
    duration: 0,
    error: false,
  }

  constructor(props) {
    super(props)
    if (props.disabled) this.state.playing = false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({ playing: !this.props.disabled })
      if (!this.props.disabled) {
        let { played, duration } = this.state
        this.player.seekTo(Math.floor((duration * played) / 100))
      }
    }
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
    let { played, duration } = this.state
    this.player.seekTo(Math.floor((duration * played) / 100))
  }

  componentDidMount() {
    if (this.props.minWatch === 0) {
      try {
        this.props.dispatch(handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, 1))
      } catch (e) {
        this.setState(() => {
          throw e
        })
      }
    }
  }

  handleOnProgress = e => {
    // console.log('PROGRESS: ' + e.played)
    if (e.played === 0) return
    let duration = this.player.getDuration()
    this.setState({ played: e.played * 100, duration })
    if (this.props.replyValue === 1) return
    let watched = e.playedSeconds >= parseInt(this.props.minWatch) ? 1 : 0
    try {
      this.props.dispatch(
        handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, watched)
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  handleSeekChange = value => {
    this.player.seekTo(value / 100)
    this.setState({ played: value })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }
  handleOnError = e => {
    console.log(e)
    this.setState({ error: true })
  }
  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player))
  }

  renderSlider = () => {
    const { played, duration } = this.state
    return (
      <React.Fragment>
        <Slider
          style={{ flex: 1, margin: '0 .75rem' }}
          disabled={this.props.replyValue === 1 ? false : true}
          value={played}
          onChange={this.handleSeekChange}
        />
        <div style={{ margin: 'auto' }}>
          <span style={{ marginRight: '0.5rem' }}>
            {typeof this.player !== 'undefined' && duration > 0
              ? format((duration * played) / 100) + '/' + format(duration)
              : '--:--/--:--'}
          </span>
        </div>
      </React.Fragment>
    )
  }

  setPlayer = player => {
    this.player = player
  }

  onPlay = () => this.setState({ playing: true })
  onPause = () => this.setState({ playing: false })

  render() {
    const { inputWidth } = Constants.getDefaultFieldWidths(this.props.questionWidth)
    const videoStyle = {
      opacity: this.props.disabled ? '0.45' : '1',
      pointerEvents: this.props.disabled ? 'none' : 'unset',
    }
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const { playing, muted } = this.state

    return (
      <React.Fragment>
        <Grid.Column
          className="multiple"
          {...Constants.getWidths(inputWidth)}
          style={{ ...withToggleStyle, margin: 'auto' }}>
          {this.state.error && (
            <div style={{ textAlign: 'center', marginTop: '1.5em' }}>Impossibile riprodurre il video</div>
          )}
          <div style={{ padding: '.5rem', background: '#eee', borderRadius: '.2rem' }}>
            <div className="customReactPlayer">
              <div className={this.props.replyValue === 1 ? '' : 'hideProgress'}>
                <Player
                  setPlayer={this.setPlayer}
                  url={this.props.src}
                  muted={muted}
                  playing={playing}
                  disabled={this.props.disabled}
                  onProgress={this.handleOnProgress}
                  progressInterval={250}
                  onError={this.handleOnError}
                  onDuration={this.handleDuration}
                  width="100%"
                  controls={false}
                  onPlay={this.onPlay}
                  onPause={this.onPause}
                />
              </div>
            </div>
            {isMobile === true && (
              <div
                name="mobile-player-controls"
                style={{ marginTop: '.5rem', display: 'flex', alignItems: 'center', ...videoStyle }}>
                {this.renderSlider()}
              </div>
            )}
            <div
              name="player-controls"
              style={{ marginTop: '.5rem', display: 'flex', alignItems: 'center', ...videoStyle }}>
              <Button
                name="play"
                primary
                size="tiny"
                icon
                onClick={this.handlePlayPause}
                style={{ float: 'left', width: '2rem' }}
                disabled={this.props.disabled}>
                <Icon name={playing ? 'pause' : 'play'} />
              </Button>
              <Button
                name="mute"
                primary
                size="tiny"
                icon
                onClick={this.handleToggleMuted}
                style={{ float: 'left', width: '2rem' }}
                disabled={this.props.disabled}>
                <Icon name={muted ? 'volume off' : 'volume up'} />
              </Button>
              {isMobile === true && <div style={{ flex: 1 }} />}
              {isMobile === false && this.renderSlider()}
              <Button
                name="fullscreen"
                primary
                size="tiny"
                icon
                onClick={this.handleClickFullscreen}
                style={{ float: 'right', width: '2rem', margin: 0 }}
                disabled={this.props.disabled}>
                <Icon name={'expand'} />
              </Button>
            </div>
          </div>
        </Grid.Column>
      </React.Fragment>
    )
  }
}
VideoField.displayName = 'VideoField'
Slider.displayName = 'Slider'

export default VideoField
