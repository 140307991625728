import * as React from 'react'
import { Table } from 'semantic-ui-react'
import get from 'lodash.get'

import MatrixHeader from './header'
import MatrixBody from './body'
import Constants from '../../../utils/constants'

const Matrix = props => {
  const skipped = props.questionSkipped || false
  const tableStyle = props.disabled || skipped ? { opacity: '.55' } : {}
  const maxrating = get(props, ['options', 'maxrating'], Constants.DEFAULTS.MAXRATING)
  const renderAs = get(props, ['options', 'render_as'], 'rating')
  const includeZero = get(props, ['options', 'include_zero'], false)
  return (
    <Table
      id={`table-${props.currentPageName}-${props.name}`}
      definition
      unstackable
      size="small"
      style={tableStyle}
      textAlign="center">
      <MatrixHeader
        elements={props.items[0].values || []}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        maxRating={maxrating}
        includeZero={includeZero}
        renderAs={renderAs}
        isVertical={false}
      />
      <MatrixBody
        childQuestions={props.childQuestions || []}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        maxRating={maxrating}
        includeZero={includeZero}
        renderAs={renderAs}
        isVertical={false}
      />
    </Table>
  )
}

export default Matrix
