import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import Constants from '../../utils/constants'
import withSkippable from '../../enhancers/skippable'
import { CommentItemToggleableHook } from '../../enhancers/toggleable'
import { DateField } from '../formfields'

class DateQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  render() {
    let dates = this.props.items.map((r, i) => {
      let { mindate, maxdate, format, placeholder, width } = this.props.options
      mindate = mindate === 'NOW' ? moment().format(format) : mindate
      maxdate = maxdate === 'NOW' ? moment().format(format) : maxdate
      return (
        <Grid.Row key={r.key} className="resurvey question body">
          <DateField
            name={r.key}
            key={`item-${r.jsonref}`}
            questionName={this.props.name}
            questionWidth={width || Constants.FULLWIDTH}
            label={r.value}
            parentDisabled={this.props.disabled}
            hasComment={r.has_comment}
            jsonref={r.jsonref}
            format={format}
            placeholder={placeholder}
            minDate={mindate}
            maxDate={maxdate}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped}
          />
          {r.has_comment && (
            <CommentItemToggleableHook
              currentPageName={this.props.currentPageName}
              questionName={this.props.name}
              name={r.key + '_comment'}
              jsonref={r.jsonref + '_comment'}
            />
          )}
        </Grid.Row>
      )
    })

    if (this.props.reverseItems) dates.reverse()
    return dates
  }
}

export default withSkippable(DateQuestionBody)
