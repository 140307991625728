import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import { CheckboxField as _CheckboxField } from '../../formfields/checkbox-field'
import { CommentItemField } from '../../formfields/comment-field'
import HTMLText from '../../system-wide/html-text'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'

import MatrixCell from '../matrix/cell'

const CheckboxField = connectField(_CheckboxField)
const TableRow = Table.Row
const TableCell = Table.Cell

const cla = { textAlign: 'left' }

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.questionName]['_rowcomment'] }
}
class _MatrixRow extends React.PureComponent {
  static defaultProps = {
    hasComment: false,
  }

  render() {
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    let columns = []
    columns.push(
      <TableCell key="mmatrix-row-0" style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.qtext} />
        </span>
        {this.props.hasComment === true && (
          <CommentItemField
            label={this.props.qtext}
            name={`_rowcomment`}
            visible={this.props.visible}
            disabled={this.props.disabled}
            questionName={this.props.questionName}
            currentPageName={this.props.currentPageName || ''}
            jsonref={`${this.props.currentPageName}.${this.props.questionName}.comment_matrix`}
          />
        )}
      </TableCell>
    )

    this.props.items.forEach((elem, i) => {
      const cellJsonRef = `${this.props.currentPageName}.${this.props.group}_col_${elem.key}`
      const jsonref = elem.jsonref

      const itemKey = this.props.questionName.split('_row_')[1]
      const valueKey = elem.key
      const cellKey = `cell-${itemKey}-${valueKey}`

      const cbProps = {
        itemKey: elem.key,
        label: elem.value,
        name: elem.key,
        jsonref,
        disabled: this.props.disabled,
        questionName: this.props.questionName,
        currentPageName: this.props.currentPageName,
        className: `matrix-cell ${cellKey}`,
        labelClassName: 'hidden-label',
        hasComment: elem.type === 'comment' || elem.type === 'optionalcomment',
      }

      columns.push(
        <MatrixCell
          key={`item_${i}`}
          jsonref={cellJsonRef}
          parentDisabled={this.props.disabled || this.props.rowComment === ''}
          questionName={this.props.questionName}
          currentPageName={this.props.currentPageName}
          component={CheckboxField}
          childProps={cbProps}
        />
      )
    })
    return <TableRow style={withToggleStyle}>{columns}</TableRow>
  }
}
_MatrixRow.displayName = '_MatrixRow'

export const MatrixRowWithComment = withToggle(connect(mstp)(_MatrixRow))
export const MatrixRow = withToggle(_MatrixRow)
