import MobileDetect from './mobile-detect'

export default class Constants {
  static DEFAULT_LANG = 'it'

  static DEBOUNCE_TIMEOUT = 200
  static FULLWIDTH = 16

  static LABEL = {
    FULLWIDTH: 5,
    WIDTH: 7,
  }

  static INPUT = {
    FULLWIDTH: 11,
    WIDTH: 9,
  }

  static DEFAULTS = {
    COLS: 1,
    MAXRATING: 5,
    TEXT_MAX_LENGTH: 80,
    MEMO_MAX_LENGTH: 500,
    NUMBER_MIN: 0,
    NUMBER_MAX: 9999,
    NUMBER_FORMAT: '0000',
  }

  static getDefaultFieldWidths(questionWidth) {
    let labelWidth, inputWidth
    if (questionWidth === this.FULLWIDTH) {
      labelWidth = this.LABEL.FULLWIDTH
      inputWidth = this.INPUT.FULLWIDTH
    } else {
      labelWidth = this.LABEL.WIDTH
      inputWidth = this.INPUT.WIDTH
    }
    return { labelWidth, inputWidth }
  }

  static getWidths(other, mobile = Constants.FULLWIDTH) {
    return {
      mobile: MobileDetect.isTablet ? other : mobile,
      tablet: other,
      computer: other,
      largeScreen: other,
      widescreen: other,
    }
  }
}
