import * as React from 'react'
import { useGeolocated } from 'react-geolocated'
import { Modal, Loader } from 'semantic-ui-react'

import HTMLText from '../system-wide/html-text'
import withToggle from '../../enhancers/toggleable'
import { handleDataChange } from '../../store/survey-data'

const Qtext = withToggle(props => <HTMLText style={props.active ? {} : { display: 'none' }} value={props.value} />)

const GeolocationProvider = ({ onChangeLocation = console.log }) => {
  let [ready, setReady] = React.useState(false)

  let { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: { enableHighAccuracy: true, maximumAge: 0 },
    isOptimisticGeolocationEnabled: false,
    onError: () => setReady(true),
    onSuccess: () => setReady(true),
  })

  React.useEffect(() => {
    if (ready) onChangeLocation({ coords, isGeolocationEnabled, isGeolocationAvailable })
  }, [ready, coords, isGeolocationAvailable, isGeolocationEnabled, onChangeLocation])

  return null
}

export class GeolocationField extends React.Component {
  state = {
    dimmed: false,
  }

  componentDidMount() {
    if (!navigator.geolocation) {
      this.handleChange({ isGeolocationEnabled: false, isGeolocationAvailable: false })
    } else {
      this.setState({ dimmed: true })
    }
  }

  handleChange = ({ coords = {}, isGeolocationAvailable, isGeolocationEnabled }) => {
    let { latitude = null, longitude = null, accuracy = null } = coords
    let value = { latitude, longitude, accuracy, isGeolocationEnabled, isGeolocationAvailable }

    try {
      this.props.dispatch(handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, value))
      this.setState({ dimmed: false })
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    if (navigator.geolocation) {
      return (
        <>
          <GeolocationProvider onChangeLocation={this.handleChange} />
          <Modal basic open={this.state.dimmed} size="small">
            <Modal.Content>
              <Loader>
                {this.props.qtext.map((qtext, i) => {
                  return (
                    <Qtext
                      key={i}
                      currentPageName={this.props.currentPageName}
                      parentDisabled={this.props.parentDisabled}
                      {...qtext}
                    />
                  )
                })}
              </Loader>
            </Modal.Content>
          </Modal>
        </>
      )
    } else {
      return null
    }
  }
}
GeolocationField.displayName = 'GeolocationField'

export default GeolocationField
