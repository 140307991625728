import * as React from 'react'

import { GeolocationField } from '../formfields'

class GeolocationQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  render() {
    return this.props.items.map((r, i) => {
      if (!this.props.active) return false
      return (
        <GeolocationField
          name={r.key}
          key={`item-${r.jsonref}`}
          questionName={this.props.name}
          label={r.value}
          parentDisabled={this.props.disabled}
          jsonref={r.jsonref}
          currentPageName={this.props.currentPageName}
          questionSkipped={false}
          qtext={this.props.qtext}
        />
      )
    })
  }
}

export default GeolocationQuestionBody
