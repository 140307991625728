import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'

import surveyParserReducer from './survey-parser'
import surveyInternalStateReducer from './survey-internal-state'
import surveyDefinitionReducer from './survey-definition'
import surveyDataReducer from './survey-data'
import surveyValidatorsReducer from './survey-validation'
import surveyToggleableReducer from './survey-toggleable'
import SurveyRespondentTrackerReducer from './survey-respondent-tracker'

export const RESET_STORE = 'survey/RESET_STORE'

const reducers = combineReducers({
  parser: surveyParserReducer,
  internalState: surveyInternalStateReducer,
  definition: surveyDefinitionReducer,
  data: surveyDataReducer,
  validators: surveyValidatorsReducer,
  toggleables: surveyToggleableReducer,
  respondent_tracker: SurveyRespondentTrackerReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    return reducers(undefined, action)
  }
  return reducers(state, action)
}

const composeEnhancers =
  typeof window === 'object' && process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'RSPlayer MainStore' })
    : compose

const middlewares = applyMiddleware(thunk)
const enhancers = composeEnhancers(middlewares)

const initStore = () => createStore(rootReducer, enhancers)

export const resetStores = () => {
  store.dispatch({ type: RESET_STORE })
}

export const store = initStore()
