import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import range from 'lodash.range'
import { _NativeSelect as RankingSelect } from '../../formfields/ranking-field'
import {
  prepareData,
  onToggleablesChange,
  onMaxRepsChanged,
  handleChange,
} from '../../formfields/ranking-field/helpers'
import { CommentItemField } from '../../formfields/comment-field'
import { handleRankingDataChange } from '../../../store/survey-data'
import HTMLText from '../../system-wide/html-text'
import Interpolator from '../../../utils/interpolator'
import withToggle from '../../../enhancers/toggleable'
import MatrixCell from './cell'

const TableRow = Table.Row
const TableCell = Table.Cell

const cla = { textAlign: 'left' }

const mstp = (state, props) => {
  let data = state.data[props.currentPageName][props.questionName]
  let toggleables = state.toggleables[props.currentPageName][props.questionName]['repliesStatus']
  let enabledItems = Object.keys(toggleables).filter(key => toggleables[key] === true).length

  let interpolator = new Interpolator()
  let max_reps = typeof props.maxReps !== 'undefined' ? interpolator.render(props.maxReps) : false
  let max = max_reps && max_reps <= enabledItems ? max_reps : enabledItems

  let parentDisabled = props.parentDisabled
  let disabled = props.disabled || enabledItems === 0
  let questionStatus = state['validators']['questionStatuses'][props.currentPageName][props.questionName]
  let errors = state['validators']['errorList'][props.currentPageName][props.questionName]
  let hasError = errors.indexOf(props.jsonref) !== -1 && questionStatus === 'INVALID'
  let rowComment = props.hasComment
    ? state.data[props.currentPageName][props.questionName]['_rowcomment'] || ''
    : undefined

  return {
    data,
    items: props.items,
    enabledItems,
    max,
    disabled,
    parentDisabled,
    hasError,
    toggleables,
    rowComment,
  }
}

class _MatrixRow extends React.PureComponent {
  static defaultProps = {
    hasComment: false,
  }

  constructor(props) {
    super(props)
    this.handleChange = handleChange.bind(this)
  }

  componentDidUpdate(oldProps) {
    if (this.props.enabledItems < oldProps.enabledItems) {
      onToggleablesChange(this.props)
    } else if (this.props.enabledItems === oldProps.enabledItems && parseInt(oldProps.max) > parseInt(this.props.max)) {
      // remove last replies if max_reps is changed due to interpolation
      onMaxRepsChanged(this.props)
    } else if (this.props.rowComment === '' && oldProps.rowComment !== '') {
      let newData = prepareData(this.props)
      this.props.dispatch(handleRankingDataChange(this.props.currentPageName, this.props.questionName, newData))
    }
  }

  render() {
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    let columns = []

    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    columns.push(
      <TableCell key="mrating-row-0" style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.qtext} />
        </span>
        {this.props.hasComment === true && (
          <CommentItemField
            label={this.props.qtext}
            name={`_rowcomment`}
            visible={this.props.visible}
            disabled={this.props.disabled}
            questionName={this.props.questionName}
            currentPageName={this.props.currentPageName || ''}
            jsonref={`${this.props.currentPageName}.${this.props.questionName}.comment_matrix`}
          />
        )}
      </TableCell>
    )

    this.props.items.forEach((elem, i) => {
      const jsonref = elem.jsonref
      const cellJsonRef = `${this.props.currentPageName}.${this.props.group}_col_${elem.key}`
      let value = Object.keys(this.props.data).find(k => this.props.data[k] === elem.key) || ''
      let commentValue = this.props.data[`${elem.key}_comment`]

      const itemKey = this.props.questionName.split('_row_')[1]
      const valueKey = elem.key
      const cellKey = `cell-${itemKey}-${valueKey}`

      const cbProps = {
        itemKey: elem.key,
        label: elem.value,
        name: elem.key,
        key: elem.key,
        jsonref,
        fluid: true,
        parentDisabled: this.props.disabled || this.props.rowComment === '',
        questionName: this.props.questionName,
        currentPageName: this.props.currentPageName,
        className: `matrix-cell ${cellKey}`,
        labelClassName: 'hidden-label',
        options: range(1, parseInt(this.props.max) + 1),
        onChange: this.handleChange,
        hasError: this.props.hasError,
        hasComment: elem.has_comment,
        value,
        commentValue,
        placeholder: '---',
        questionSkipped: this.props.questionSkipped,
        questionDisabled: this.props.disabled,
      }

      columns.push(
        <MatrixCell
          key={`item_${i}`}
          jsonref={cellJsonRef}
          questionName={this.props.questionName}
          currentPageName={this.props.currentPageName}
          component={RankingSelect}
          childProps={cbProps}
        />
      )
    })
    return <TableRow style={withToggleStyle}>{columns}</TableRow>
  }
}
_MatrixRow.displayName = '_MatrixRow'

export const MatrixRowWithComment = withToggle(connect(mstp)(_MatrixRow))
export const MatrixRow = withToggle(connect(mstp)(_MatrixRow))
