import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    error_recoverable: 'Unexpected error',
    error_unrecoverable: 'Unexpected error',
    error_generic: 'Unexpected error',
    error_not_found: '404 - Not found',
    error_survey_completed: 'Interview completed',
    error_expired_session: 'Session expired',
    error_invalid_session: 'Invalid session',
    error_invalid_uid: 'Invalid interview link',
    error_missing_url_params: 'Missing url parameters',

    test_mode: 'TEST MODE: interview data will not be saved',
  },
  it: {
    error_recoverable: 'Si è verificato un errore inaspettato',
    error_unrecoverable: 'Si è verificato un errore inaspettato',
    error_generic: 'Si è verificato un errore inaspettato',
    error_not_found: '404 - Pagina non trovata',
    error_survey_completed: 'Intervista conclusa',
    error_expired_session: 'Sessione scaduta',
    error_invalid_session: 'Sessione non valida',
    error_invalid_uid: 'Link intervista non valido',
    error_missing_url_params: 'Parametri url mancanti',

    test_mode: 'TEST MODE: i dati del questionario non verranno salvati',
  },
})

export default strings
