import * as React from 'react'

import withToggle from '../../../enhancers/toggleable'

class NullElement extends React.PureComponent {
  render() {
    return null
  }
}

const MSelectRow = withToggle(NullElement, 'MatrixRow')
const MSelectColumn = withToggle(NullElement, 'MatrixColumn')
const MSelectReplistItem = withToggle(NullElement, 'MSelectReplistItem')

class MetaMSelect extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  getMetaElements = () => {
    let { disabled: parentDisabled, currentPageName, options } = this.props
    let childQuestions = this.props.childQuestions || []
    let { use_replist } = options
    let rows = childQuestions.map(q => (
      <MSelectRow
        key={q.name}
        group={q.group}
        parentDisabled={parentDisabled}
        questionName={q.name}
        currentPageName={currentPageName}
        jsonref={q.jsonref}
        values={q.items}
      />
    ))
    let q = childQuestions[0]
    let columns = q.items.map(v => (
      <MSelectColumn
        key={`${currentPageName}.${q.group}_col_${v.key}`}
        group={q.group}
        parentDisabled={parentDisabled}
        questionName={q.name}
        currentPageName={currentPageName}
        jsonref={`${currentPageName}.${q.group}_col_${v.key}`}
      />
    ))
    let replistItems = use_replist.map(item => (
      <MSelectReplistItem
        key={`${currentPageName}.${q.group}_rlitem_${item.key}`}
        group={q.group}
        parentDisabled={parentDisabled}
        questionName={q.group}
        currentPageName={currentPageName}
        jsonref={`${currentPageName}.${q.group}_rlitem.${item.key}`}
      />
    ))

    return [...rows, ...columns, ...replistItems]
  }

  render() {
    return <React.Fragment>{this.getMetaElements()}</React.Fragment>
  }
}

export default MetaMSelect
