// Action types
export const SET_SURVEY_DEFINITION = 'survey/SET_SURVEY_DEFINITION'

// Initial state
export const initialState = {
  survey: '',
  survey_title: '',
  version: '',
  content: [],
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SURVEY_DEFINITION:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

// Action creators
export const setSurveyDefinition = json => ({
  type: SET_SURVEY_DEFINITION,
  payload: json,
})

export default reducer
