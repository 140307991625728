import axios from 'axios'
import pako from 'pako'
import get from 'lodash.get'
import set from 'lodash.set'

export const modes = {
  TEST: 'test',
  PRODUCTION: 'production',
}

export const statuses = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

// Action types
const RETRIEVE_PENDING = 'survey/RETRIEVE_PENDING'
const RETRIEVE_SUCCESS = 'survey/RETRIEVE_SUCCESS'
const RETRIEVE_ERROR = 'survey/RETRIEVE_ERROR'
const SEND_PENDING = 'survey/SEND_PENDING'
const SEND_SUCCESS = 'survey/SEND_SUCCESS'
const SEND_ERROR = 'survey/SEND_ERROR'

// Initial state
const initialState = {
  loading: false,
  status: statuses.PENDING,
  errorMsg: '',
  definition: {},
  outofquota: false,
  data: {},
  snapshot: {},
  mode: modes.PRODUCTION,
  uid: '',
  survey: '',
  sessionToken: '',
  isTestLink: false,
  isFixLink: false,
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_PENDING:
      return { ...state, loading: true, status: statuses.PENDING }
    case RETRIEVE_SUCCESS:
      return { ...state, loading: false, status: statuses.SUCCESS, ...action.payload }
    case RETRIEVE_ERROR:
      return {
        ...state,
        loading: false,
        status: statuses.ERROR,
        errorMsg: action.payload,
      }
    case SEND_PENDING:
      return { ...state, sendingStatus: statuses.PENDING }
    case SEND_SUCCESS:
      return { ...state, sendingStatus: statuses.SUCCESS }
    case SEND_ERROR:
      return { ...state, sendingStatus: statuses.ERROR }
    default:
      return state
  }
}
export default reducer

// Action creators
const setPending = () => ({
  type: RETRIEVE_PENDING,
})

const setSuccess = (
  uid,
  survey,
  sessionToken,
  definition,
  outofquota,
  data,
  snapshot,
  preseed,
  mode,
  isTestLink,
  isFixLink
) => ({
  type: RETRIEVE_SUCCESS,
  payload: { uid, survey, sessionToken, definition, outofquota, data, snapshot, preseed, mode, isTestLink, isFixLink },
})

const setError = errorMsg => ({
  type: RETRIEVE_ERROR,
  payload: errorMsg,
})
const setSendPending = () => ({
  type: SEND_PENDING,
})

const setSendSuccess = () => ({
  type: SEND_SUCCESS,
})

const setSendError = () => ({
  type: SEND_ERROR,
})

// Thunks
export const retrieveSurvey = isSubSurvey => async dispatch => {
  dispatch(setPending())
  try {
    let isTestLink = false
    let isFixLink = false
    let navUid = window.location.pathname
    if (navUid === '') throw new Error()
    let slug = navUid.split('/').pop()
    let path = navUid.replace(slug, '')
    if (path === '/test/') {
      isTestLink = true
    }
    if (path === '/fix/') {
      isFixLink = true
    }

    let urlParams = isFixLink ? [{ key: '__fix_interview', value: true }] : []
    for (let p of new URLSearchParams(window.location.search)) {
      let value = /(.+)http[s]?:\/\//.test(p[1]) ? p[1].replace(/http[s]?:\/\/.*$/, '') : p[1]
      urlParams.push({ key: p[0], value })
    }

    let searchString = ''
    if (urlParams.length > 0) {
      searchString = '?' + urlParams.reduce((acc, el) => [...acc, `${el['key']}=${el['value']}`], []).join('&')
    }

    //restore session on page reloads for anonymous surveys...
    let id_interview = sessionStorage.getItem(`id_interview`)
    let saved_query_params = sessionStorage.getItem(`query_params`)
    if (saved_query_params !== searchString || isSubSurvey) {
      id_interview = ''
      clearSessionStorage(isSubSurvey)
    }

    if (slug.split('-').length < 2 && id_interview) {
      slug = `${slug}-${id_interview}`
    }

    let response = await axios.get(`/api/state/${isTestLink ? 'test/' : ''}${slug}${searchString}`)
    let { data: serverRes } = response
    let { surveyUid, interviewUid, survey, sessionToken, definition, outofquota } = serverRes.data

    //survive to page relaod on anonymous surveys..
    if (!isSubSurvey) {
      sessionStorage.setItem(`id_interview`, interviewUid)
      sessionStorage.setItem(`query_params`, searchString)
    }

    let preseed = { __uid: interviewUid }
    if (typeof serverRes.data.cati_id !== 'undefined') preseed.__cati_id = serverRes.data.cati_id

    let mode = modes.PRODUCTION
    for (let p of urlParams) {
      if (!['__testmode', '__fix_interview'].includes(p['key'])) preseed[p['key']] = p['value']
      if (p['key'] === '__testmode') mode = modes.TEST
    }

    let initialData = get(serverRes.data, ['data'], false)
    let snapshot = get(serverRes.data, ['snapshot'], false)

    // restore previously setted PRESEEDS if available
    if (initialData) {
      let origPreseed = get(initialData, ['PRESEED'], {})
      preseed = { ...origPreseed, ...preseed }
      set(initialData, ['PRESEED'], preseed)
    }
    if (snapshot) {
      set(snapshot, ['data', 'PRESEED'], preseed)
    }

    let uid = `${surveyUid}-${interviewUid}`
    dispatch(
      setSuccess(
        uid,
        survey,
        sessionToken,
        definition,
        outofquota,
        initialData,
        snapshot,
        preseed,
        mode,
        isTestLink,
        isFixLink
      )
    )
  } catch (err) {
    let errorMsg = 'error_generic'
    if (err.response) {
      errorMsg = err.response.data.status
    }
    dispatch(setError(errorMsg))
  }
}

export const sendData = snapshot => async (dispatch, getState) => {
  dispatch(setSendPending())
  try {
    let { exitType } = snapshot.internalState
    let { uid, survey, sessionToken, mode } = getState().backend
    if (mode === modes.TEST) {
      dispatch(setSendSuccess())
      return true
    }

    let res = await axios({
      url: `/api/update/${getState().backend.isTestLink ? 'test/' : ''}${uid}`,
      method: 'POST',

      transformRequest: axios.defaults.transformRequest.concat(function (data, headers) {
        if (data.length > 1024) {
          headers['Content-Encoding'] = 'gzip'
          return pako.gzip(data)
        } else {
          return data
        }
      }),

      data: {
        survey,
        sessionToken,
        snapshot,
        closed: exitType !== '',
      },
    })

    dispatch(setSendSuccess())
    return { status: true, outofquota: res.data.data.outofquota }
  } catch (err) {
    dispatch(setSendError())
    let errorMsg = 'error_generic'
    if (err.response) {
      errorMsg = err.response.data.status
      dispatch(setError(errorMsg))
    }
    return { status: false, outofquota: false }
  }
}

export const clearSessionStorage = isSubSurvey => {
  if (!isSubSurvey) {
    sessionStorage.removeItem(`id_interview`)
    sessionStorage.removeItem(`query_params`)
  }
}
