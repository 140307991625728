import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    unexpected_error: 'Oops! Something went wrong.',
    next_button: 'Next',
    back_button: 'Back',
    retry_button: 'Retry',
    network_error_msg: 'Network Error',
    network_error_on_initialize_msg: 'Network Error during initialization',
    survey_end_msg: 'Interview completed. <br/>Thanks for your time',
    survey_stop_msg: 'Interview stopped.<br/>Thanks for your time',
    survey_outofquota_msg:
      'Dear user, thank you for your time.<br/> <br/>Due to the lack of requirements, the interview cannot be completed.',
    survey_required_msg: 'Missing answer',
    survey_required_comment_msg: 'Missing answer: specify other',
    survey_number_min_msg: 'Enter a number grater than or equal to %(MIN)%',
    survey_number_max_msg: 'Enter a number lesser than or equal to %(MAX)%',
    warning: 'Warning',
    stop_confirm: 'Are you sure you want to stop interview?',
    yes: 'Yes',
    no: 'No',

    // SEARCHTABLE related
    search_input_placeholder: 'Search...',
    error_invalid_src: 'Invalid url /SRC',
    error_empty_datafile: 'Empty datafile',
    error_download_resource: 'Error downloading datafile',
    error_missing_header_field: 'Missing header definition in /VALUES section',
    error_unsupported_filetype: 'Unsupported filetype',

    // SEARH related
    search_no_results_msg: 'No results found',

    // TIME related
    time_picker: 'Time',

    // VIDEO RELATED
    video_min_watch_msg: 'You must watch minimum %(MIN_WATCH)% seconds of video',

    // DRAGNDROP related
    dragndrop_msg: 'Drag the answers into this box in the order you want',
  },
  it: {
    unexpected_error: 'Oops! Qualcosa è andato storto.',
    next_button: 'Avanti',
    back_button: 'Indietro',
    retry_button: 'Riprova',
    network_error_msg: 'Errore di rete',
    network_error_on_initialize_msg: 'Errore di rete in fase di inizializzazione',
    survey_end_msg: "L'intervista è conclusa. <br/>Grazie per la collaborazione",
    survey_stop_msg:
      "Ha scelto di interrompere l'intervista.<br/><br/>Potrà riprendere la compilazione utilizzando il link che ha già ricevuto.<br/>Grazie per il tempo che ci ha dedicato",
    survey_outofquota_msg:
      "Gentile utente, grazie per la sua collaborazione.<br/><br/>A causa della mancanza dei requisiti, l'intervista non può essere conclusa.",
    survey_required_msg: 'Risposta mancante',
    survey_required_comment_msg: 'Risposta mancante: specificare altro',
    survey_number_min_msg: 'Inserire un numero maggiore o uguale a %(MIN)%',
    survey_number_max_msg: 'Inserire un numero minore o uguale a %(MAX)%',
    warning: 'Attenzione',
    stop_confirm: "Conferma di voler interrompere l'intervista?",
    yes: 'Si',
    no: 'No',

    // SEARCHTABLE related
    search_input_placeholder: 'Cerca...',
    error_invalid_src: 'Url /SRC non valido',
    error_empty_datafile: 'Datafile vuoto',
    error_download_resource: 'Errore nel recupero del datafile',
    error_missing_header_field: 'Errata definizione delle colonne nella sezione /VALUES',
    error_unsupported_filetype: 'Tipo di file non supportato',

    // SEARH related
    search_no_results_msg: 'Nessun risultato trovato',

    // TIME related
    time_picker: 'Ore',

    // VIDEO related
    video_min_watch_msg: 'Errore: devi aver visualizzato almeno i primi %(MIN_WATCH)% secondi del video',

    // DRAGNDROP related
    dragndrop_msg: "Trascinare in questo riquadro le risposte nell'ordine desiderato",
  },
})

export default strings
