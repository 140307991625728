import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import get from 'lodash.get'

import { NumberField as _NumberField } from '../../formfields/number-field'
import { CommentItemField } from '../../formfields/comment-field'
import Qtext from '../../qtext.js'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'
import Constants from '../../../utils/constants'
import HTMLText from '../../system-wide/html-text'

const NumberField = withToggle(connectField(_NumberField))

class MobileSummary extends React.PureComponent {
  render() {
    let dec = ((this.props.format || '00000').split('.')[1] || '').length
    let cond = '(' + this.props.tokens.map(t => `${t} === null`).join(' && ') + ')'
    let expr = ''
    let tks = ''
    let value = ''
    let active_values = ''
    switch (this.props.type) {
      case 'sum':
        tks = this.props.tokens.map(t => `(${t} !== null ? ${t} : 0)`)
        value = tks.join(' + ')
        let sum = `(Number(Math.round(${value} + 'e' + ${dec}) + 'e-' + ${dec})).toFixed(${dec})`
        expr = `%(${cond} ? '-' : ${sum})%`
        break
      case 'prod':
        tks = this.props.tokens.map(t => `(${t} !== null ? ${t} : 1)`)
        value = tks.join(' * ')
        let prod = `(Number(Math.round(${value} + 'e' + ${dec}) + 'e-' + ${dec})).toFixed(${dec})`
        expr = `%(${cond} ? '-' : ${prod})%`
        break
      case 'avg':
        tks = this.props.tokens.map(t => `(${t} !== null ? ${t} : 0)`)
        active_values = this.props.tokens.map(t => `(${t} !== null ? 1 : 0)`)
        value = `((${tks.join(' + ')})/(${active_values.join(' + ')}))`
        let avg = `(Number(Math.round(${value} + 'e' + ${dec}) + 'e-' + ${dec})).toFixed(${dec})`
        expr = `%(${cond} ? '-' : ${avg})%`
        break
      default:
        expr = ''
    }
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    return (
      <React.Fragment>
        <Grid.Row style={withToggleStyle}>
          <Grid.Column>
            <HTMLText value={this.props.label} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={withToggleStyle}>
          <Grid.Column>
            <HTMLText value={expr} />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    )
  }
}

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.name]['_rowcomment'] }
}
class _List extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    let questionWidth = this.props.options.width || Constants.FULLWIDTH
    let { labelWidth, inputWidth } = Constants.getDefaultFieldWidths(questionWidth)

    let summaryTokens = this.props.items.reduce((acc, item) => {
      return [...acc, `${this.props.currentPageName}.${this.props.name}.${item.key}`]
    }, [])

    let summaries = []
    if (typeof this.props.options.sum !== 'undefined') summaries.push('sum')
    if (typeof this.props.options.prod !== 'undefined') summaries.push('prod')
    if (typeof this.props.options.avg !== 'undefined') summaries.push('avg')

    const rowKey = this.props.name.split('_row_')[1]

    return (
      <React.Fragment>
        <Grid.Row style={withToggleStyle}>
          {this.props.items.map(item => (
            <NumberField
              key={`item-${item.jsonref}`}
              label={item.value}
              name={item.key}
              parentDisabled={this.props.parentDisabled || this.props.disabled || this.props.rowComment === ''}
              jsonref={`${this.props.currentPageName}.${this.props.group}_col_${item.key}`}
              questionName={this.props.name}
              format={this.props.format}
              prefix={this.props.prefix}
              suffix={this.props.suffix}
              currentPageName={this.props.currentPageName}
              questionSkipped={this.props.questionSkipped || false}
              className={`matrix-cell cell-${rowKey}-${item.key}`}
            />
          ))}
        </Grid.Row>

        {summaries.map(type => (
          <MobileSummary
            key={type}
            disabled={this.props.disabled || this.props.questionSkipped || false}
            visible={this.props.visible}
            label={this.props.options[type] || ''}
            format={this.props.options.format || '00000'}
            tokens={summaryTokens}
            labelWidth={labelWidth}
            inputWidth={inputWidth}
            type={type}
          />
        ))}
      </React.Fragment>
    )
  }
}
_List.displayName = '_List'

const NumberListWithComment = withToggle(connect(mstp)(_List))
const NumberList = withToggle(_List)

const MobileBody = props => {
  let childQuestions = props.childQuestions || []
  const format = get(props, ['options', 'format'], Constants.DEFAULTS.NUMBER_FORMAT)
  const prefix = get(props, ['options', 'prefix'], '')
  const suffix = get(props, ['options', 'suffix'], '')

  return childQuestions.map(q => {
    const childProps = {
      key: `key-${q.name}`,
      name: q.name,
      parentDisabled: props.questionSkipped || props.disabled,
      currentPageName: props.currentPageName,
      group: props.name,
      jsonref: q.jsonref,
      items: q.items,
      format,
      prefix,
      suffix,
      options: props.options,
      qtext: q.qtext[0],
      hasComment: typeof q.has_comment !== 'undefined',
      withToggleStyle: props.visible ? {} : { display: 'none' },
    }

    return <MobileMNumberItem {...childProps} />
  })
}

const MobileMNumberItem = withToggle(props => {
  let { qtext, hasComment, withToggleStyle, ...listProps } = props
  let List = hasComment ? NumberListWithComment : NumberList
  return (
    <React.Fragment key={props.name}>
      <Grid.Row style={withToggleStyle}>
        <Grid.Column>
          <Qtext {...qtext} {...props} />
          {hasComment === true && (
            <CommentItemField
              label=""
              disabled={props.questionSkipped || props.disabled}
              visible={props.visible}
              name={`_rowcomment`}
              questionName={props.name}
              currentPageName={props.currentPageName}
              jsonref={`${props.currentPageName}.${props.name}.comment_matrix`}
            />
          )}
        </Grid.Column>
      </Grid.Row>

      <List {...listProps} />
    </React.Fragment>
  )
})

export default MobileBody
