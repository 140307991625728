import * as React from 'react'
import { Grid } from 'semantic-ui-react'

import Constants from '../../utils/constants'
import { CheckboxField } from '../formfields'
import withSkippable from '../../enhancers/skippable'
import Divider from '../system-wide/divider'

class CheckboxlistQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  renderColumns = () => {
    let cols = this.props.options.cols || Constants.DEFAULTS.COLS
    let colSize = Math.min(Math.floor(Constants.FULLWIDTH / cols), 16)
    let checkboxes = []

    this.props.items.forEach((item, i) => {
      let questionSkipped = this.props.questionSkipped || false
      if (item.type === 'separator') {
        checkboxes.push(
          <Grid.Column key={i} mobile={Constants.FULLWIDTH}>
            <Divider key={`divider-${i}`} disabled={this.props.disabled || questionSkipped} value={item.value} />
          </Grid.Column>
        )
      } else {
        let checkboxProps = {
          colSize,
          label: item.value,
          name: item.key,
          parentDisabled: this.props.disabled,
          questionActive: !this.props.disabled && this.props.visible,
          jsonref: item.jsonref,
          questionName: this.props.name,
          currentPageName: this.props.currentPageName,
          hasComment: item.type === 'comment' || item.type === 'optionalcomment',
          questionSkipped,
        }

        checkboxes.push(<CheckboxField key={`item-${item.jsonref}`} {...checkboxProps} />)
      }
    })

    if (this.props.reverseItems) checkboxes.reverse()
    return checkboxes
  }

  render() {
    return <Grid.Row className="resurvey question body">{this.renderColumns()}</Grid.Row>
  }
}

export default withSkippable(CheckboxlistQuestionBody)
