import MarkdownIt from 'markdown-it'
import mila from 'markdown-it-link-attributes'

const md = MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
})
  .use(function (md) {
    md.renderer.rules.table_open = () => '<table class="ui fixed compact table">'
  })
  .use(mila, { attrs: { target: '_blank', rel: 'noopener' } })

const leftTrimRegexp = /^\s+/

const mdRender = str => {
  const regexp = /%\((?:(?!\)%).)+\)%/gm
  let staticTokens = str.split(regexp)
  let dynamicTokens = []

  str.replace(regexp, m => {
    dynamicTokens.push(m)
    return ''
  })

  str = staticTokens
    .reduce(function (acc, v, i) {
      return dynamicTokens[i] ? [...acc, v, `$$$EXPRESSION_${i}$$$`] : [...acc, v]
    }, [])
    .join('')

  str = md
    .render(
      str
        .trim()
        .split('\n')
        .map(l => l.replace(leftTrimRegexp, ''))
        .join('\n') + '\n'
    )
    .replace(/(<p>)(.*)(<\/p>)/, '$2')
    .trim()

  dynamicTokens.forEach((dt, i) => (str = str.replace(`$$$EXPRESSION_${i}$$$`, dynamicTokens[i])))

  return str
}

export default mdRender
