import * as React from 'react'
import { Grid, Rating, Button } from 'semantic-ui-react'
import range from 'lodash.range'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import { CommentItemField } from './comment-field'

const textFieldStyle = { marginTop: '0.3rem' }
export class RatingField extends React.Component {
  static defaultProps = {
    hasComment: false,
  }

  handleChange = (e, { rating }) => {
    try {
      let value = rating === 0 ? null : rating
      this.props.dispatch(handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, value))
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }
  handleScoreChange = (e, { score }) => {
    try {
      let value = score === this.props.replyValue ? null : score
      this.props.dispatch(handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, value))
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }
  handleSelecteChange = e => {
    try {
      let value = e.target.value === '---' ? null : parseInt(e.target.value)
      this.props.dispatch(handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, value))
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    let { labelWidth, inputWidth } = Constants.getDefaultFieldWidths(this.props.questionWidth)
    if (!this.props.label) inputWidth = 16
    const textStyle = {
      opacity: this.props.disabled ? '0.45' : '1',
      width: '100%',
      display: this.props.labelClassName ? '' : 'table',
      height: '100%',
    }
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const scoreMaxWidth = this.props.maxRating * 2.25 + (this.props.prefix && this.props.suffix ? 2 : 0)
    const start_range = this.props.includeZero ? 0 : 1

    return (
      <React.Fragment>
        {this.props.label && (
          <Grid.Column
            {...Constants.getWidths(labelWidth)}
            style={{ ...textFieldStyle, ...withToggleStyle }}
            className={`multiple ${this.props.labelClassName} ${this.props.className}`}>
            <span style={textStyle} className={this.props.labelClassName}>
              <div
                style={{
                  display: 'table-cell',
                  verticalAlign: ['score', 'nps'].includes(this.props.renderAs) ? 'bottom' : 'middle',
                  width: '100%',
                }}>
                <HTMLText
                  value={this.props.label}
                  style={this.props.hasComment ? {} : { minHeight: '1.75rem', display: 'block' }}
                />
                {this.props.hasComment === true && (
                  <CommentItemField
                    style={{ marginBottom: '0' }}
                    disabled={this.props.disabled}
                    visible={this.props.visible}
                    currentPageName={this.props.currentPageName}
                    questionName={this.props.questionName}
                    name={this.props.name + '_comment'}
                    jsonref={this.props.jsonref + '_comment'}
                  />
                )}
              </div>
            </span>
          </Grid.Column>
        )}
        <Grid.Column
          className={`multiple ${this.props.className}`}
          {...Constants.getWidths(inputWidth)}
          style={{ display: 'inline-block', ...withToggleStyle }}>
          {this.props.renderAs === 'select' && (
            <div className="select-wrapper container input-width-4 input-centered">
              <select
                className={`ui form ${this.props.disabled ? 'disabled' : ''} field ${
                  this.props.hasError ? 'error' : ''
                }`}
                name={this.props.name}
                disabled={this.props.disabled || this.props.emptyComment === true}
                onChange={this.handleSelecteChange}
                placeholder="---"
                value={this.props.replyValue !== null ? this.props.replyValue : ''}>
                <option key={`option-null`} value={'---'}>
                  ---
                </option>
                {range(start_range, this.props.maxRating + 1).map(i => {
                  return (
                    <option key={`option-${i}`} value={i}>
                      {i}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          <div style={{ marginTop: '.5rem', maxWidth: `${scoreMaxWidth}rem` }}>
            {this.props.prefix && this.props.suffix && (
              <React.Fragment>
                <HTMLText
                  style={{
                    fontSize: 'small',
                    display: 'block',
                    maxWidth: '8rem',
                    float: 'left',
                    textAlign: 'left',
                    lineHeight: 1,
                    opacity: this.props.disabled || this.props.emptyComment === true ? '0.45' : '1',
                  }}
                  value={this.props.prefix}
                />
                <div style={{ float: 'left', width: '2rem' }} />
                <HTMLText
                  style={{
                    fontSize: 'small',
                    display: 'block',
                    maxWidth: '8rem',
                    float: 'right',
                    textAlign: 'right',
                    lineHeight: 1,
                    opacity: this.props.disabled || this.props.emptyComment === true ? '0.45' : '1',
                  }}
                  value={this.props.suffix}
                />
              </React.Fragment>
            )}
            {this.props.renderAs === 'rating' && (
              <div style={{ clear: 'both', textAlign: 'center' }}>
                <Rating
                  className={`field ${this.props.hasError ? 'error' : ''}`}
                  icon="star"
                  clearable
                  maxRating={this.props.maxRating}
                  name={this.props.name}
                  rating={this.props.replyValue}
                  disabled={this.props.disabled || this.props.emptyComment === true}
                  onRate={this.handleChange}
                  style={this.props.hasComment ? { marginTop: '1.35rem' } : {}}
                />
              </div>
            )}
            {['score', 'nps'].includes(this.props.renderAs) && (
              <div style={{ clear: 'both', textAlign: 'center' }}>
                {range(start_range, this.props.maxRating + 1).map(i => (
                  <Button
                    className={`form field ${this.props.hasError ? 'error' : ''} score rating`}
                    size="mini"
                    primary={this.props.replyValue === i}
                    icon
                    key={`item${this.props.name}-${i}`}
                    score={i}
                    disabled={this.props.disabled || this.props.emptyComment === true}
                    onClick={this.handleScoreChange}>
                    {i}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </Grid.Column>
      </React.Fragment>
    )
  }
}
RatingField.displayName = 'RatingField'

export default RatingField
