import * as React from 'react'
import { Table } from 'semantic-ui-react'

import MatrixVerticalRow from './vrow'
import { MatrixRow, MatrixRowWithComment } from './row'
import ColSummary from './colsummary'

const TableBody = Table.Body

const MatrixBody = props => {
  let tableRows = []
  if (props.isVertical) {
    props.childQuestions[0].items.forEach((item, i) => {
      let cells = []
      props.childQuestions.forEach(q => {
        cells.push({ cell: q.items[i], hasComment: q.has_comment })
      })
      tableRows.push(
        <MatrixVerticalRow
          key={i}
          qtext={item.value}
          cells={cells}
          parentDisabled={props.parentDisabled}
          questionName={props.questionName}
          group={props.questionName}
          currentPageName={props.currentPageName}
          isVertical={props.isVertical}
          jsonref={`${props.currentPageName}.${props.questionName}_col_${item.key}`}
          format={props.format}
          prefix={props.prefix}
          suffix={props.suffix}
          options={props.options}
        />
      )
    })
  } else {
    props.childQuestions.forEach(q => {
      let Row = q.has_comment ? MatrixRowWithComment : MatrixRow
      tableRows.push(
        <Row
          key={q.name}
          qtext={q.qtext[0].value}
          items={q.items}
          parentDisabled={props.parentDisabled}
          questionName={q.name}
          currentPageName={props.currentPageName}
          group={props.questionName}
          isVertical={props.isVertical}
          jsonref={q.jsonref}
          format={props.format}
          prefix={props.prefix}
          suffix={props.suffix}
          options={props.options}
          hasComment={q.has_comment}
        />
      )
    })
  }
  let summaries = []
  if (typeof props.options.colsum !== 'undefined' && !props.isVertical) summaries.push('colsum')
  if (typeof props.options.colprod !== 'undefined' && !props.isVertical) summaries.push('colprod')
  if (typeof props.options.colavg !== 'undefined' && !props.isVertical) summaries.push('colavg')
  if (typeof props.options.sum !== 'undefined' && props.isVertical) summaries.push('sum')
  if (typeof props.options.prod !== 'undefined' && props.isVertical) summaries.push('prod')
  if (typeof props.options.avg !== 'undefined' && props.isVertical) summaries.push('avg')

  summaries.forEach(type => {
    tableRows.push(
      <ColSummary
        key={props.currentPageName + '.' + props.questionName + '.' + type}
        type={type}
        questionName={props.questionName}
        currentPageName={props.currentPageName}
        isVertical={props.isVertical}
        childQuestions={props.childQuestions}
        format={props.format}
        options={props.options}
      />
    )
  })
  return <TableBody>{tableRows}</TableBody>
}

export default MatrixBody
