import * as React from 'react'
import { connect } from 'react-redux'
import DOMPurify from 'dompurify'
import striptags from 'striptags'

import Interpolator from '../../utils/interpolator'

class StaticHTMLText extends React.PureComponent {
  render() {
    let DOMElement = this.props.as ? this.props.as : 'span'
    let title = this.props.addTitle ? striptags(this.props.value, [], '') : undefined
    return (
      <DOMElement
        lang={navigator.language}
        className={this.props.className}
        style={this.props.style}
        title={title}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(this.props.value, {
            USE_PROFILES: { html: true },
            ADD_ATTR: ['target'],
            FORCE_BODY: true,
          }),
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    data: state.data,
    toggleables: state.toggleables,
  }
}

class _DynamicHTMLText extends React.PureComponent {
  interpolator

  constructor(props) {
    super(props)
    this.interpolator = new Interpolator()
  }

  render() {
    return (
      <StaticHTMLText
        as={this.props.as}
        addTitle={this.props.addTitle}
        className={this.props.className}
        style={this.props.style}
        value={this.interpolator.render(this.props.value)}
      />
    )
  }
}

const DynamicHTMLText = connect(mapStateToProps)(_DynamicHTMLText)

const HTMLText = props => {
  if (Interpolator.needInterpolation(props.value)) {
    return <DynamicHTMLText {...props} />
  } else {
    return <StaticHTMLText {...props} />
  }
}
export default HTMLText
