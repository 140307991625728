// Action types
export const SET_PAGE_VALIDATION_STATUS = 'survey/SET_PAGE_VALIDATION_STATUS'
export const INIT_VALIDATORS = 'survey/INIT_VALIDATORS'
export const SET_QUESTION_VALIDATION_STATUS = 'survey/SET_QUESTION_VALIDATION_STATUS'
export const SET_QUESTION_ERROR_LIST = 'survey/SET_QUESTION_ERROR_LIST'
export const RESET_ERROR_LIST = 'survey/RESET_ERROR_LIST'

// Initial state
export const initialState = {
  configs: {},
  pageStatuses: {},
  questionStatuses: {},
  errors: {},
  errorList: {},
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_VALIDATORS:
      return { ...state, ...action.payload.validators }

    case SET_PAGE_VALIDATION_STATUS:
      return {
        ...state,
        pageStatuses: {
          ...state.pageStatuses,
          [action.payload.page]: action.payload.pageStatus,
        },
      }

    case SET_QUESTION_VALIDATION_STATUS:
      return {
        ...state,
        questionStatuses: {
          ...state.questionStatuses,
          [action.payload.page]: {
            ...state.questionStatuses[action.payload.page],
            [action.payload.question]: action.payload.questionStatus,
          },
        },
        errors: {
          ...state.errors,
          [action.payload.page]: {
            ...state.errors[action.payload.page],
            [action.payload.question]: action.payload.errors,
          },
        },
      }

    case SET_QUESTION_ERROR_LIST: //DON'T REWRITE ENTIRE state TO AVOID DidUpdate LOOPS
      return {
        ...state,
        errorList: {
          ...state.errorList,
          [action.payload.page]: {
            ...state.errorList[action.payload.page],
            [action.payload.question]: action.payload.errorList,
          },
        },
      }

    case RESET_ERROR_LIST:
      let emptyErrorList = Object.keys(state.errorList[action.payload]).reduce(
        (acc, qName) => ({ ...acc, [qName]: [] }),
        {}
      )
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload]: emptyErrorList,
        },
        errorList: {
          ...state.errorList,
          [action.payload]: emptyErrorList,
        },
      }

    default:
      return state
  }
}

// Action creators
export const initValidators = validators => ({
  type: INIT_VALIDATORS,
  payload: { validators },
})

export const setPageValidationStatus = (page, pageStatus) => ({
  type: SET_PAGE_VALIDATION_STATUS,
  payload: {
    page,
    pageStatus,
  },
})

export const setQuestionValidationStatus = (page, question, questionStatus, errors) => ({
  type: SET_QUESTION_VALIDATION_STATUS,
  payload: {
    page,
    question,
    questionStatus,
    errors,
  },
})

export const setQuestionErrorList = (page, question, errorList) => ({
  type: SET_QUESTION_ERROR_LIST,
  payload: {
    page,
    question,
    errorList,
  },
})

export const resetErrorList = page => ({
  type: RESET_ERROR_LIST,
  payload: page,
})

export default reducer
