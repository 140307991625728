import * as React from 'react'
import { connect } from 'react-redux'
import set from 'lodash.set'

import { evaluateExpression, initExpressionValues } from '../store/survey-internal-state'
import expressionBuilder from '../utils/exprbuilder'
import * as rsch from '../utils/rscode-helpers'
import { store } from '../store'

const generateMapStateToProps = (deps, tDeps, page, expr, initialValue) => {
  let pageDeps = []
  let minDep = deps.reduce((acc, d) => {
    let cur = d.split('.').length
    return acc < cur ? acc : cur
  }, 3)
  let minTDep = tDeps.reduce((acc, d) => {
    let cur = d.split('.').length
    return acc < cur ? acc : cur
  }, 3)

  if (minDep !== 1 && minTDep !== 1 && deps.length + tDeps.length > 0) {
    // AT LEAST A DEP BUT NOT ON ANTIRE PAGE
    for (let i = 0; i < deps.length; i++) {
      pageDeps.push(deps[i].split('.')[0])
    }
    for (let i = 0; i < tDeps.length; i++) {
      pageDeps.push(tDeps[i].split('.')[0])
    }
    pageDeps = [...new Set(pageDeps)]

    return state => {
      let { internalState, data, toggleables } = state
      // bail out after resetState
      if (internalState.pages.length === 0) return {}

      let ret = {
        currentPageName: internalState.currentPageName,
        initialValue: initialValue,
        pageDeps: pageDeps,
      }
      for (let i = 0; i < deps.length; i++) {
        let tks = deps[i].split('.')
        if (tks.length > 2) {
          ret[`__${tks[0]}$${tks[1]}$${tks[2]}`] = data[tks[0]][tks[1]][tks[2]]
        } else {
          //flat all replies
          for (let k in data[tks[0]][tks[1]]) {
            ret[`__${tks[0]}$${tks[1]}$${k}`] = data[tks[0]][tks[1]][k]
          }
        }
      }
      for (let i = 0; i < tDeps.length; i++) {
        let tks = tDeps[i].split('.')
        if (tks.length > 2) {
          ret[`__toggleables__${tks[0]}$${tks[1]}$${tks[2]}`] =
            toggleables[tks[0]][tks[1]]['questionStatus'] && toggleables[tks[0]][tks[1]]['repliesStatus'][tks[2]]
        } else {
          //flat all toggleables
          ret[`__toggleables__${tks[0]}$${tks[1]}$questionStatus`] = toggleables[tks[0]][tks[1]]['questionStatus']
          for (let k in toggleables[tks[0]][tks[1]]['repliesStatus']) {
            ret[`__toggleables__${tks[0]}$${tks[1]}$${k}`] = toggleables[tks[0]][tks[1]]['repliesStatus'][k]
          }
        }
      }
      return ret
    }
  } else {
    // NO DEPS OR AT LEAST A DEP ON ENTIRE PAGE (EG. isActive(P1))
    return (state, ownProps) => {
      // bail out after resetState
      if (state.internalState.pages.length === 0) return {}
      return { pageDeps, forceEval: Math.random(), initialValue }
    }
  }
}

class Expression extends React.Component {
  constructor(props) {
    super(props)
    let { data, toggleables, internalState } = store.getState()
    let { page, exprkey, exprFunction, initialValue } = props
    let exprText = internalState['expressions'][page][exprkey]

    let exprValue = undefined
    if (exprText === 'false') {
      exprValue = false
    } else if (exprText === 'true') {
      exprValue = true
    } else {
      exprValue = exprFunction(data, toggleables, ...rsch.helpers)
    }

    if (initialValue !== exprValue) {
      this.props.dispatch(evaluateExpression(page, exprkey, exprValue))
    }
  }

  // evaluate this expression
  eval = async () => {
    try {
      let { page, exprkey, exprFunction } = this.props
      let { data, toggleables, internalState } = store.getState()
      let exprText = internalState['expressions'][page][exprkey]
      let oldExprValue = internalState['expressionValues'][page][exprkey]

      let exprValue = undefined
      if (exprText === 'false') {
        exprValue = false
      } else if (exprText === 'true') {
        exprValue = true
      } else {
        exprValue = exprFunction(data, toggleables, ...rsch.helpers)
      }

      if (exprValue !== oldExprValue) {
        await this.props.dispatch(evaluateExpression(page, exprkey, exprValue))
      }
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  async shouldComponentUpdate(nextProps) {
    let { currentPageName, page, exprkey, exprFunction } = nextProps
    if (page !== currentPageName) return false

    let shouldUpdate = false
    for (let k in this.props) {
      //SHALLOW EQUALITY CHECK
      if (this.props[k] !== nextProps[k]) {
        //some prop was changed!
        shouldUpdate = true
        break
      }
    }
    if (!shouldUpdate) return false

    let { data, toggleables, internalState } = store.getState()
    let oldExprValue = internalState['expressionValues'][page][exprkey]

    let exprText = internalState['expressions'][page][exprkey]
    if ((exprText === 'false' && oldExprValue === false) || (exprText === 'true' && oldExprValue === true)) {
      return false
    }

    let exprValue = undefined
    try {
      if (exprText === 'false') {
        exprValue = false
      } else if (exprText === 'true') {
        exprValue = true
      } else {
        exprValue = exprFunction(data, toggleables, ...rsch.helpers)
      }
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }

    if (exprValue !== oldExprValue) {
      await this.props.dispatch(evaluateExpression(page, exprkey, exprValue))
    }
    return exprValue !== oldExprValue
  }

  render() {
    return null
  }
}

const generateExpression = (deps, tDeps, page, expr, initialValue) => {
  let mstp = generateMapStateToProps(deps, tDeps, page, expr, initialValue)
  return connect(mstp, null, null, { forwardRef: true })(Expression)
}

const generateExpressions = () => {
  let exprState = {}
  let expressions = []
  let expressionRefs = []
  let errors = []
  let initialState = {}
  let expressionsSlice = store.getState().internalState.expressions

  for (let page in expressionsSlice) {
    for (let expr in expressionsSlice[page]) {
      let exprCode = expressionsSlice[page][expr]
      let { exprObject, errors: err } = expressionBuilder(exprCode, expr)
      if (err.length === 0) {
        exprState[expr] = { ...exprObject, page, expr }
      }
      errors = [...errors, ...err]
    }
  }

  for (let k in exprState) {
    let page = exprState[k].page
    let expr = exprState[k].expr
    let initialValue = exprState[k].initialValue
    set(initialState, [page, expr], initialValue)

    let props = {
      key: exprState[k].expr,
      page: exprState[k].page || '',
      exprkey: exprState[k].expr,
      exprFunction: exprState[k].exprFunction,
    }
    let Expr = generateExpression(
      exprState[k].deps,
      exprState[k].tDeps,
      exprState[k].page,
      exprState[k].expr,
      initialValue
    )
    let ref = React.createRef()
    expressionRefs.push(ref)
    expressions.push(<Expr {...props} ref={ref} />)
  }

  if (Object.keys(store.getState().internalState.expressionValues).length === 0) {
    console.log('INITIALIZE EXPR VALUES')
    store.dispatch(initExpressionValues(initialState))
  }

  return { expressions, expressionRefs, errors }
}

export default generateExpressions
