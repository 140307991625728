import withToggle from '../../enhancers/toggleable'
import connectField from '../../enhancers/connect-field'

import { TextField as _TextField } from './text-field'
import { SelectField as _SelectField } from './select-field'
import { RadioField as _RadioField } from './radio-field'
import { CheckboxField as _CheckboxField } from './checkbox-field'
import { TextareaField as _TextareaField } from './textarea-field'
import { NumberField as _NumberField } from './number-field'
import { RatingField as _RatingField } from './rating-field'
import { DateField as _DateField } from './date-field'
import { TimeField as _TimeField } from './time-field'
import { DragndropField as _DragndropField } from './dragndrop-field'
import { RankingField as _RankingField } from './ranking-field'
import { SearchField as _SearchField } from './search-field'
import { VideoField as _VideoField } from './video-field'
import { GeolocationField as _GeolocationField } from './geolocation-field'

import './field-styles.css'

export const TextField = withToggle(connectField(_TextField), 'ReplyItem')
export const SelectField = withToggle(connectField(_SelectField))
export const RadioField = withToggle(connectField(_RadioField), 'ReplyItem')
export const CheckboxField = withToggle(connectField(_CheckboxField), 'ReplyItem')
export const TextareaField = withToggle(connectField(_TextareaField), 'ReplyItem')
export const NumberField = withToggle(connectField(_NumberField), 'ReplyItem')
export const RatingField = withToggle(connectField(_RatingField), 'ReplyItem')
export const DateField = withToggle(connectField(_DateField), 'ReplyItem')
export const TimeField = withToggle(connectField(_TimeField), 'ReplyItem')
export const DragndropField = withToggle(connectField(_DragndropField))
export const RankingField = withToggle(connectField(_RankingField))
export const SearchField = withToggle(connectField(_SearchField), 'ReplyItem')
export const VideoField = withToggle(connectField(_VideoField), 'ReplyItem')
export const GeolocationField = withToggle(connectField(_GeolocationField), 'ReplyItem')
