import * as React from 'react'
import { Table } from 'semantic-ui-react'
import get from 'lodash.get'

import MatrixHeader from './header'
import MatrixBody from './body'
import Constants from '../../../utils/constants'

const Matrix = props => {
  const skipped = props.questionSkipped || false
  const tableStyle = props.disabled || skipped ? { opacity: '.55' } : {}
  const format = get(props, ['options', 'format'], Constants.DEFAULTS.NUMBER_FORMAT)
  const prefix = get(props, ['options', 'prefix'], '')
  const suffix = get(props, ['options', 'suffix'], '')
  return (
    <Table
      id={`table-${props.currentPageName}-${props.name}`}
      className="mnumber-table"
      definition
      celled
      unstackable
      size="small"
      style={tableStyle}
      textAlign="center">
      <MatrixHeader
        elements={props.items}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        isVertical={true}
        options={props.options}
      />
      <MatrixBody
        childQuestions={props.childQuestions || []}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        isVertical={true}
        format={format}
        prefix={prefix}
        suffix={suffix}
        options={props.options}
      />
    </Table>
  )
}

export default Matrix
