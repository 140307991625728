import * as React from 'react'
import { Table } from 'semantic-ui-react'

import withToggle from '../../../enhancers/toggleable'

const TableCell = Table.Cell

class _MatrixCell extends React.PureComponent {
  render() {
    const { width } = this.props
    const Component = this.props.component
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const className =
      this.props.childProps && typeof this.props.childProps.className !== 'undefined'
        ? this.props.childProps.className
        : ''

    return (
      <TableCell width={width} style={{ ...withToggleStyle, ...this.props.style }} className={className}>
        <Component
          {...this.props.childProps}
          visible={this.props.visible}
          disabled={this.props.disabled || this.props.rowComment === ''}
          parentDisabled={this.props.childProps.parentDisabled}
          active={this.props.active}
        />
      </TableCell>
    )
  }
}
_MatrixCell.displayName = '_MatrixCell'

export default withToggle(_MatrixCell)
