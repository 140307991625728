// Action types
export const SET_TOGGLEABLE_STATE = 'survey/SET_TOGGLEABLE_STATE'
export const SET_BULK_ITEMS_TOGGLEABLE_STATE = 'survey/SET_BULK_ITEMS_TOGGLEABLE_STATE'
export const SET_MATRIX_TOGGLEABLE_STATE = 'survey/SET_MATRIX_TOGGLEABLE_STATE'
export const SET_MSELECT_RLITEMS_TOGGLEABLE_STATE = 'survey/SET_MSELECT_RLITEMS_TOGGLEABLE_STATE'
export const INIT_TOGGLEABLE = 'survey/INIT_TOGGLEABLE'

// Initial state
export const initialState = {}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_TOGGLEABLE:
      return { ...state, ...action.payload }

    case SET_BULK_ITEMS_TOGGLEABLE_STATE:
      return {
        ...state,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          [action.payload.qName]: {
            ...state[action.payload.pageName][action.payload.qName],
            repliesStatus: action.payload.repliesStatus,
          },
        },
      }

    case SET_MATRIX_TOGGLEABLE_STATE:
      return {
        ...state,
        [action.payload.pageName]: action.payload.status,
      }

    case SET_MSELECT_RLITEMS_TOGGLEABLE_STATE:
      return {
        ...state,
        // [action.payload.pageName]: action.payload.status,
        [action.payload.pageName]: {
          ...state[action.payload.pageName],
          [action.payload.qName]: {
            ...state[action.payload.pageName][action.payload.qName],
            repliesStatus: {
              ...state[action.payload.pageName][action.payload.qName]['repliesStatus'],
              ...action.payload.statuses,
            },
          },
        },
      }

    case SET_TOGGLEABLE_STATE:
      //TODO: se replyName è undefined il reducer si comporta implicitamente
      // (ed ERRONEAMENTE) come Question
      if (typeof action.payload.replyName !== 'undefined' && action.payload.replyName !== '') {
        return {
          ...state,
          [action.payload.pageName]: {
            ...state[action.payload.pageName],
            [action.payload.qName]: {
              ...state[action.payload.pageName][action.payload.qName],
              repliesStatus: {
                ...state[action.payload.pageName][action.payload.qName]['repliesStatus'],
                [action.payload.replyName]: action.payload.active,
              },
            },
          },
        }
      } else {
        let repliesStatus = { ...state[action.payload.pageName][action.payload.qName].repliesStatus }
        if (!action.payload.active) {
          //IF question is not active reset replies' toggleables
          for (let k in repliesStatus) {
            repliesStatus[k] = /_comment|_rowcomment/.test(k) ? false : true
          }
        }
        return {
          ...state,
          [action.payload.pageName]: {
            ...state[action.payload.pageName],
            [action.payload.qName]: {
              ...state[action.payload.pageName][action.payload.qName],
              questionStatus: action.payload.active,
              repliesStatus,
            },
          },
        }
      }

    default:
      return state
  }
}

// Action creators
export const initToggleable = init => ({
  type: INIT_TOGGLEABLE,
  payload: init,
})

export const setToggleableState = (pageName, qName, replyName, active) => ({
  type: SET_TOGGLEABLE_STATE,
  payload: { pageName, qName, replyName, active },
})

export const setBulkItemsToggleableState = (pageName, qName, repliesStatus) => ({
  type: SET_BULK_ITEMS_TOGGLEABLE_STATE,
  payload: { pageName, qName, repliesStatus },
})

export const setMatrixToggleableState = (pageName, status) => ({
  type: SET_MATRIX_TOGGLEABLE_STATE,
  payload: { pageName, status },
})

export const setMselectRlitemsToggleableState = (pageName, qName, statuses) => ({
  type: SET_MSELECT_RLITEMS_TOGGLEABLE_STATE,
  payload: { pageName, qName, statuses },
})

export default reducer
