import * as React from 'react'
import { Grid } from 'semantic-ui-react'

import Constants from '../../utils/constants'
import withSkippable from '../../enhancers/skippable'
import { TimeField } from '../formfields'

class TimeQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  render() {
    let times = this.props.items.map(r => {
      let { mintime, maxtime, placeholder, width } = this.props.options
      return (
        <Grid.Row key={r.key} className="resurvey question body">
          <TimeField
            name={r.key}
            key={`item-${r.jsonref}`}
            questionName={this.props.name}
            questionWidth={width || Constants.FULLWIDTH}
            label={r.value}
            parentDisabled={this.props.disabled}
            hasComment={r.has_comment}
            jsonref={r.jsonref}
            placeholder={placeholder}
            minTime={mintime}
            maxTime={maxtime}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped}
          />
        </Grid.Row>
      )
    })

    if (this.props.reverseItems) times.reverse()
    return times
  }
}

export default withSkippable(TimeQuestionBody)
