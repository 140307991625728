import * as React from 'react'

import HTMLText from './system-wide/html-text'
import withToggle from '../enhancers/toggleable'

export class _Qtext extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1', ...withToggleStyle }
    return (
      <div className="resurvey qtext" style={textStyle}>
        <HTMLText value={this.props.value} />
      </div>
    )
  }
}
const Qtext = withToggle(_Qtext)

export const QtextList = props => {
  return (
    <div>
      {props.items.map((el, i) => {
        return <Qtext key={i} currentPageName={props.currentPageName} parentDisabled={props.parentDisabled} {...el} />
      })}
    </div>
  )
}

export default Qtext
