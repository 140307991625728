import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import arrayMove from 'array-move'

import { handleDragndropDataChange } from '../../store/survey-data'
import withToggle from '../../enhancers/toggleable'
import HTMLText from '../system-wide/html-text'
import Interpolator from '../../utils/interpolator'
import strings from '../../locales'

const onlyItems = k => !/(__qtype|_comment|_label|skipped)$/.test(k)

const mapStateToProps = (state, ownProps) => {
  let data = state.data[ownProps.currentPageName][ownProps.questionName]
  let toggleables = state.toggleables[ownProps.currentPageName][ownProps.questionName]['repliesStatus']

  let _reps = []
  Object.keys(data).forEach(key => {
    if (!isNaN(key) && data[key] !== null) {
      _reps.push(data[key])
    }
  })
  let enabledItems = Object.keys(toggleables).filter(key => toggleables[key] === true).length
  let items = ownProps.items.filter(item => _reps.indexOf(item.key) === -1)
  let replies = _reps.map(key => ownProps.items.find(item => item.key === key))

  let interpolator = new Interpolator()
  let max_reps = typeof ownProps.max_reps !== 'undefined' ? interpolator.render(ownProps.max_reps.value) : false
  let max = max_reps && max_reps <= enabledItems ? max_reps : enabledItems

  let parentDisabled = ownProps.parentDisabled
  let disabled = ownProps.disabled || enabledItems === 0
  let questionStatus = state['validators']['questionStatuses'][ownProps.currentPageName][ownProps.questionName]
  let errors = state['validators']['errorList'][ownProps.currentPageName][ownProps.questionName]
  let hasError = errors.indexOf(ownProps.jsonref) !== -1 && questionStatus === 'INVALID'

  return {
    data,
    origItems: ownProps.items,
    items,
    replies,
    max,
    disabled,
    parentDisabled,
    hasError,
    toggleables,
  }
}

const _mapItemProps = (state, ownProps) => {
  return {
    isActive: state.toggleables[ownProps.currentPageName][ownProps.questionName]['repliesStatus'][ownProps.itemKey],
    isQuestionDisabled: !state.toggleables[ownProps.currentPageName][ownProps.questionName]['questionStatus'],
    itemExpressions: state.internalState.expressionValues[ownProps.currentPageName] || {},
  }
}

class _DragndropItem extends React.Component {
  static defaultProps = {
    onDeactivateItem: null,
  }

  render() {
    return (
      <Draggable
        key={this.props.itemKey}
        draggableId={this.props.itemKey}
        index={this.props.index}
        isDragDisabled={this.props.isDragDisabled}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <DragndropItemContent {...this.props} isDragging={snapshot.isDragging} isActive={this.props.isActive} />
          </div>
        )}
      </Draggable>
    )
  }
}

const DragndropItem = connect(_mapItemProps)(_DragndropItem)

class _DragndropItemContent extends React.Component {
  render() {
    let active = this.props.isActive
    if (this.props.isQuestionDisabled || this.props.questionSkipped) {
      active =
        this.props.itemExpressions[`${this.props.jsonref}.disableif`] !== true &&
        this.props.itemExpressions[`${this.props.jsonref}.enableif`] !== false
    }

    if (active === false) return null
    return (
      <HTMLText
        as="div"
        className={`draggable-item ${this.props.isDragging ? 'on-drag' : ''}`}
        value={this.props.value}
      />
    )
  }
}

const DragndropItemContent = withToggle(_DragndropItemContent, 'ReplyItem')

export class _DragndropField extends React.Component {
  state = {
    isDragging: false,
  }

  prepareData = () => {
    let newData = {}
    Object.keys(this.props.data).forEach(k => {
      switch (true) {
        case k === '__qtype':
        case k === 'skipped':
        case k === 'skipped_label':
          newData[k] = this.props.data[k]
          break

        case /_label/.test(k):
          newData[k] = ''
          break

        default:
          newData[k] = null
      }
    })
    return newData
  }

  onDeactivateItem = itemKey => {
    let index = this.props.replies.findIndex(item => item.key === itemKey)
    let args = {
      source: { droppableId: 'droppable-right', index },
      destination: { droppableId: 'droppable-left', index: -1 },
      reason: 'DROP',
    }
    this.onDropRight(args)
  }

  onDragStart = () => {
    this.setState({ isDragging: true })
  }

  onDropRight = event => {
    this.setState({ isDragging: false })
    if (event.reason !== 'DROP' || event.destination === null) return

    let removedIndex = event.source.droppableId === 'droppable-right' ? event.source.index : null
    let addedIndex = event.destination.droppableId === 'droppable-right' ? event.destination.index : null
    let payload = removedIndex !== null ? this.props.replies[removedIndex] : this.props.items[event.source.index]

    if (removedIndex === null && addedIndex === null) {
      return
    }

    let newData = this.prepareData()

    // REORDER DROPZONE
    if (removedIndex !== null && addedIndex !== null) {
      arrayMove(this.props.replies, removedIndex, addedIndex).forEach((item, index) => {
        newData[index + 1] = item.key
        newData[`${index + 1}_label`] = item.value
      })
    }

    // RECEIVE ITEM FROM LEFT
    if (removedIndex === null && addedIndex !== null) {
      this.props.replies.splice(addedIndex, 0, payload)
      this.props.replies.forEach((item, index) => {
        newData[index + 1] = item.key
        newData[`${index + 1}_label`] = item.value
      })
    }

    // DONATE ITEM TO LEFT
    if (removedIndex !== null && addedIndex === null) {
      this.props.replies
        .filter(item => item.key !== payload.key)
        .forEach((item, index) => {
          newData[index + 1] = item.key
          newData[`${index + 1}_label`] = item.value
        })
    }
    this.props.dispatch(handleDragndropDataChange(this.props.currentPageName, this.props.questionName, newData))
  }

  getChildPayload = side => index => {
    if (side === 'left') {
      return this.props.items[index]
    } else {
      return this.props.replies[index]
    }
  }

  getChildPayloadLeft = this.getChildPayload('left')
  getChildPayloadRight = this.getChildPayload('right')

  onToggleablesChange = oldProps => {
    let ks = Object.keys(this.props.data)
      .filter(onlyItems)
      .reduce((acc, k) => ({ ...acc, [k]: this.props.data[k] }), {})
    let changed = false
    Object.keys(this.props.toggleables).forEach(k => {
      // se una risposta passa da visibile -> nascosta oppure
      // è nascosta ma è presente nelle replies
      if (
        (oldProps.toggleables[k] && !this.props.toggleables[k]) ||
        (!this.props.toggleables[k] && Object.values(ks).includes(k))
      ) {
        let pos = Object.values(ks).indexOf(k.toString())
        if (pos !== -1) {
          changed = true
          let key = Object.keys(ks)[pos]
          ks[key] = null
        }
      }
    })
    if (changed) {
      let values = Object.values(ks).filter(v => v !== null)
      let reps = Object.keys(this.props.data)
        .filter(onlyItems)
        .reduce((acc, k, i) => {
          return {
            ...acc,
            [k]: i < values.length ? values[i] : null,
            [`${k}_label`]: i < values.length ? this.props.origItems.find(i => i.key === k).value : '',
          }
        }, {})
      reps = { ...this.props.data, ...reps }
      this.props.dispatch(handleDragndropDataChange(this.props.currentPageName, this.props.questionName, reps))
    }
  }

  onMaxRepsChanged = oldProps => {
    let newData = { ...this.props.data }
    Object.keys(newData)
      .filter(onlyItems)
      .forEach(k => {
        if (parseInt(k) > parseInt(this.props.max)) {
          newData[k] = null
          newData[`${k}_label`] = ''
        }
      })
    this.props.dispatch(handleDragndropDataChange(this.props.currentPageName, this.props.questionName, newData))
  }

  componentDidUpdate(oldProps) {
    if (this.props.toggleables !== oldProps.toggleables) {
      this.onToggleablesChange(oldProps)
    } else if (
      this.props.toggleables === oldProps.toggleables &&
      parseInt(oldProps.max) > parseInt(this.props.max) &&
      this.props.replies.length > parseInt(this.props.max)
    ) {
      // remove last replies if max_reps is changed due to interpolation
      this.onMaxRepsChanged(oldProps)
    }
  }

  render() {
    let isDragDisabled =
      this.props.questionSkipped || this.props.disabled || this.props.replies.length >= parseInt(this.props.max)

    let areaDisabled =
      this.props.disabled || (this.props.replies.length >= parseInt(this.props.max) && !this.state.isDragging)
        ? 'disabled'
        : ''

    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    return (
      <Grid.Column style={withToggleStyle}>
        <DragDropContext onDragEnd={this.onDropRight} onDragStart={this.onDragStart}>
          <div>
            <Droppable droppableId="droppable-left" type="div" ignoreContainerClipping={false}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`left-column dragndrop ${areaDisabled} ${snapshot.isDraggingOver ? 'on-drag' : ''}`}>
                  {this.props.items.map((item, index) => (
                    <DragndropItem
                      {...item}
                      itemKey={item.key}
                      index={index}
                      isDragDisabled={isDragDisabled}
                      parentDisabled={this.props.parentDisabled}
                      currentPageName={this.props.currentPageName}
                      questionName={this.props.questionName}
                      questionSkipped={this.props.questionSkipped}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="droppable-right" type="div">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`right-column dragndrop ${this.props.hasError ? 'error' : ''} ${
                    snapshot.isDraggingOver ? 'on-drag' : ''
                  } ${this.props.disabled || this.props.questionSkipped ? 'disabled' : ''}`}>
                  {this.props.replies.map((item, index) => (
                    <DragndropItem
                      {...item}
                      itemKey={item.key}
                      index={index}
                      onDeactivateItem={this.onDeactivateItem}
                      currentPageName={this.props.currentPageName}
                      questionName={this.props.questionName}
                    />
                  ))}
                  {this.props.replies.length === 0 && <div className="right-column-tip">{strings.dragndrop_msg}</div>}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Grid.Column>
    )
  }
}

export const DragndropField = connect(mapStateToProps)(_DragndropField)
