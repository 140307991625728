import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import surveyLoaderReducer from './backend-integration'

export const REHYDRATE_STORE = 'survey/REHYDRATE_STORE'

const reducers = combineReducers({
  backend: surveyLoaderReducer,
})

const rootReducer = (state, action) => {
  if (action.type === REHYDRATE_STORE) {
    return reducers(action.payload, action)
  }
  return reducers(state, action)
}

const composeEnhancers =
  typeof window === 'object' && process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

const middlewares = applyMiddleware(thunk)
const enhancers = composeEnhancers(middlewares)

export const rehydrateStore = preloadedState => ({
  type: REHYDRATE_STORE,
  payload: preloadedState,
})

export const store = createStore(rootReducer, enhancers)
export default store
