import { Parser } from 'acorn'
import * as astring from 'astring'
import get from 'lodash.get'

import { store } from '../store'
import { helperNames, getChildQuestions } from './rscode-helpers'

const tokenizableHelpers = [...helperNames.filter(h => h !== 'now'), 'goBack']
const nativeProperties = new Set([
  ...Object.getOwnPropertyNames(String.prototype),
  ...Object.getOwnPropertyNames(Number.prototype),
])

function tolerantParser(Parser) {
  return class extends Parser {
    readWord1() {
      let w = super.readWord1()
      if (/^\.[0-9]/.test(this.input.substring(this.pos))) {
        this.input = this.input.substring(0, this.pos + 1) + '_$$$_' + this.input.substring(this.pos + 1)
      }
      return w
    }
  }
}
const TolerantParser = Parser.extend(tolerantParser)

//RECHECK THESE VALUES ON ASTRING UPGRADE
const EXPRESSIONS_PRECEDENCE = {
  ArrayExpression: 20,
  TaggedTemplateExpression: 20,
  ThisExpression: 20,
  Identifier: 20,
  Literal: 18,
  TemplateLiteral: 20,
  Super: 20,
  SequenceExpression: 20,
  MemberExpression: 19,
  CallExpression: 19,
  NewExpression: 19,
  ArrowFunctionExpression: 17,
  ClassExpression: 17,
  FunctionExpression: 17,
  ObjectExpression: 17,
  UpdateExpression: 16,
  UnaryExpression: 15,
  BinaryExpression: 14,
  LogicalExpression: 13,
  ConditionalExpression: 4,
  AssignmentExpression: 3,
  AwaitExpression: 2,
  YieldExpression: 2,
  RestElement: 1,
}

export const rewriteIdentifier = identifier => {
  if (identifier === 'PRESEED.__uid') return 'getUID()'
  let { data } = store.getState()
  let _tks = identifier.split('.')

  let [t1, t2, t3, ...others] = _tks
  let tks = [t1, t2]
  if (_tks.length > 2) {
    if (nativeProperties.has(t3)) {
      others = [t3, ...others]
    } else {
      tks = [...tks, t3]
    }
  }

  switch (typeof get(data, tks)) {
    case 'undefined':
      throw new Error('Invalid identifier: ' + identifier)
    case 'object':
      if (typeof get(data, [...tks, '_rep']) !== 'undefined') {
        tks.push('_rep')
      } else {
        if (tks.length === 2) throw new Error('Invalid identifier: ' + identifier)
      }
      break
    default:
      break
  }

  let othersStr = others.length ? `.${others.join('.')}` : ''
  if (data[tks[0]][tks[1]]['__qtype'] === 'date') {
    return `__normalizeDate(data["${tks.join('"]["')}"], undefined)${othersStr}`
  } else {
    return `data["${tks.join('"]["')}"]${othersStr}`
  }
}

let customGenerator = {
  ...astring.baseGenerator,

  BinaryExpression: function (node, state) {
    let left = astring.generate(node.left, { generator: customGenerator })
    let right = astring.generate(node.right, { generator: customGenerator })

    if (
      (/^__normalizeDate.*\], undefined\)$/.test(left) && /^now\(.*\)$/.test(right)) ||
      (/^now\(.*\)$/.test(left) && /^__normalizeDate.*\], undefined\)$/.test(right))
    ) {
      // DATE TO NOW COMPARISONS - ACCHROCCHIH!
      let nowOperand = /^now\(.*\)$/.test(left) ? left : right
      let dateOperand = /^now\(.*\)$/.test(left) ? right : left
      let isOrdered = nowOperand === left
      let tks1 = dateOperand.replace('__normalizeDate(data["', '').replace('"], undefined)', '').split('"]["')
      let { data } = store.getState()
      let format = data[tks1[0]][tks1[1]]['__dateFormat']
      dateOperand = dateOperand.replace('undefined', format)
      nowOperand = nowOperand.replace(', "")', `, ${format})`)
      state.write(`${isOrdered ? nowOperand : dateOperand} ${node.operator} ${isOrdered ? dateOperand : nowOperand}`)
    } else if (/^__normalizeDate.*\], undefined\)$/.test(left) && /^__normalizeDate.*\], undefined\)$/.test(right)) {
      // DATE TO DATE COMPARISONS
      let tks1 = left.replace('__normalizeDate(data["', '').replace('"], undefined)', '').split('"]["')
      let tks2 = right.replace('__normalizeDate(data["', '').replace('"], undefined)', '').split('"]["')
      let { data } = store.getState()
      let commonFormat = Math.min(data[tks1[0]][tks1[1]]['__dateFormat'], data[tks2[0]][tks2[1]]['__dateFormat'])
      left = left.replace('undefined', commonFormat)
      right = right.replace('undefined', commonFormat)
      state.write(`${left} ${node.operator} ${right}`)
    } else {
      // NO CUSTOMIZATIONS
      astring.baseGenerator.BinaryExpression(node, state)
    }
  },

  UnaryExpression: function (node, state) {
    if (node.operator === 'delete') {
      let arg = astring.generate(node.argument).replace('_$$$_', '')
      if (arg.match(customGenerator.idRegExp) !== null) {
        throw new Error(`Operation not allowed: delete ${arg}`)
      }
    }

    let argument = astring.generate(node.argument, { generator: customGenerator })
    state.write(`${node.operator} ${argument}`)
  },

  CallExpression: function (node, state) {
    let { data } = store.getState()
    let fname = astring.generate(node.callee, { generator: customGenerator })
    let args = ''
    if (tokenizableHelpers.indexOf(fname) !== -1) {
      args = node.arguments.map(arg => astring.generate(arg).replace('_$$$_', ''))
      if (args.length) {
        customGenerator.deps.push(args[0])
        customGenerator.tDeps.push(args[0])

        let tks = args[0].split('.')
        if (tks.length === 2) {
          //check for matrix parent question dep..
          let qtype = data[tks[0]][tks[1]].__qtype
          if (['matrix', 'mmatrix', 'mrating', 'mtext', 'mnumber', 'mranking', 'mselect'].indexOf(qtype) !== -1) {
            getChildQuestions(tks).forEach(qname => {
              customGenerator.deps.push(`${tks[0]}.${qname}`)
              customGenerator.tDeps.push(`${tks[0]}.${qname}`)
            })
          }
        }
      }

      args = args.map(arg => `["${arg.split('.').join('", "')}"]`)
      if (fname === 'goBack' && node.arguments[0].type === 'Literal') {
        args = [`["${node.arguments[0].value}"]`]
      }
    } else if (fname === 'now') {
      args = node.arguments.map(arg => astring.generate(arg, { generator: customGenerator }))
      let missingArgs = Array(3 - args.length).fill('""')
      args = [...args, ...missingArgs]
    } else if (fname === 'getData' && node.arguments[2].type === 'ArrayExpression') {
      let arr_expr = node.arguments.splice(node.arguments.length - 1, 1)
      args = node.arguments.map(arg => astring.generate(arg, { generator: customGenerator }))

      let arr_str = arr_expr[0].elements.map(e => {
        let str = []
        if (e.type === 'MemberExpression') {
          if ('name' in e.object) {
            str.push(e.object.name)
            str.push(e.property.name.replace('_$$$_', ''))
          } else if ('name' in e.object.object) {
            str.push(e.object.object.name)
            str.push(e.object.property.name)
            str.push(e.property.name.replace('_$$$_', ''))
          }
        } else if (e.type === 'Identifier') {
          str = e.name.split('.')
        } else {
          str = e.value.split('.')
        }
        return str.join('.')
      })

      args.push('["' + arr_str.join('","') + '"]')
    } else {
      args = node.arguments.map(arg => astring.generate(arg, { generator: customGenerator }))
    }

    if (fname === 'checkQuota') {
      state.write(`await ${fname}(${args.join(', ')}, __checkQuotaHandler)`)
    } else {
      state.write(`${fname}(${args.join(', ')})`)
    }
  },

  MemberExpression: function (node, state) {
    if (node.object.type === 'MemberExpression') {
      node.object.noIdRewrite = true
    }

    let chunks = []
    if (EXPRESSIONS_PRECEDENCE[node.object.type] < EXPRESSIONS_PRECEDENCE.MemberExpression) {
      chunks.push('(')
      chunks.push(astring.generate(node.object, { generator: customGenerator }))
      chunks.push(')')
    } else {
      chunks.push(astring.generate(node.object, { generator: customGenerator }))
    }

    if (node.computed) {
      chunks.push('[')
      chunks.push(astring.generate(node.property, { generator: customGenerator }))
      chunks.push(']')
    } else {
      chunks.push('.')
      chunks.push(astring.generate(node.property, { generator: customGenerator }))

      if (node.noIdRewrite !== true) {
        let s = chunks.join('')
        if (s.match(customGenerator.idRegExp) !== null) {
          s = s.replace('_$$$_', '')
          if (!/^PRESEED/.test(s)) customGenerator.deps.push(s)
          s = rewriteIdentifier(s)
        }
        chunks = [s]
      }
    }

    state.write(chunks.join(''))
  },
}

const parse = (program, allowAssignment = true) => {
  const pages = Object.keys(store.getState().data).join('|')
  const idRegExp = new RegExp(`^(${pages})\\.[a-zA-Z0-9_.$]+`, 'g')

  customGenerator.deps = []
  customGenerator.tDeps = []
  customGenerator.idRegExp = idRegExp

  if (!allowAssignment) {
    customGenerator.AssignmentExpression = function (node, state) {
      throw new Error("Error: operator '=' not allowed, did you mean '=='?")
    }
  } else {
    customGenerator.AssignmentExpression = astring.baseGenerator.AssignmentExpression
  }

  return {
    code: astring.generate(TolerantParser.parse(program, { ecmaVersion: 2020 }), { generator: customGenerator }),
    deps: [...new Set(customGenerator.deps)],
    tDeps: [...new Set(customGenerator.tDeps)],
  }
}

export default parse
