import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import { NumberField as _NumberField } from '../../formfields/number-field'
import { CommentItemField } from '../../formfields/comment-field'
import HTMLText from '../../system-wide/html-text'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'

import MatrixCell from '../matrix/cell'
import Summary from './summary'

const NumberField = connectField(_NumberField)

const TableRow = Table.Row
const TableCell = Table.Cell

const cla = { textAlign: 'left' }

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.questionName]['_rowcomment'] }
}
class _MatrixRow extends React.PureComponent {
  static defaultProps = {
    hasComment: false,
  }

  render() {
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    let columns = []
    columns.push(
      <TableCell key="mnumber-row-0" style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.qtext} />
        </span>
        {this.props.hasComment === true && (
          <CommentItemField
            label={this.props.qtext}
            name={`_rowcomment`}
            visible={this.props.visible}
            disabled={this.props.disabled}
            questionName={this.props.questionName}
            currentPageName={this.props.currentPageName || ''}
            jsonref={`${this.props.currentPageName}.${this.props.questionName}.comment_matrix`}
          />
        )}
      </TableCell>
    )

    this.props.items.forEach((elem, i) => {
      const jsonref = elem.jsonref
      const cellJsonRef = `${this.props.currentPageName}.${this.props.group}_col_${elem.key}`

      const itemKey = this.props.questionName.split('_row_')[1]
      const valueKey = elem.key
      const cellKey = `cell-${itemKey}-${valueKey}`

      const cbProps = {
        itemKey: elem.key,
        name: elem.key,
        jsonref,
        disabled: this.props.disabled,
        questionName: this.props.questionName,
        currentPageName: this.props.currentPageName,
        className: `matrix-cell ${cellKey}`,
        labelClassName: 'hidden-label',
        format: this.props.format,
        prefix: this.props.prefix,
        suffix: this.props.suffix,
      }

      columns.push(
        <MatrixCell
          key={`item_${i}`}
          jsonref={cellJsonRef}
          parentDisabled={this.props.disabled || this.props.rowComment === ''}
          questionName={this.props.questionName}
          currentPageName={this.props.currentPageName}
          component={NumberField}
          childProps={cbProps}
        />
      )
    })
    let summaryTokens = this.props.items.reduce((acc, item) => {
      return [...acc, `${this.props.currentPageName}.${this.props.questionName}.${item.key}`]
    }, [])

    let summaries = []
    if (typeof this.props.options.sum !== 'undefined') summaries.push('sum')
    if (typeof this.props.options.prod !== 'undefined') summaries.push('prod')
    if (typeof this.props.options.avg !== 'undefined') summaries.push('avg')

    summaries.forEach((type, index) => {
      columns.push(
        <Summary
          className={index === 0 ? 'first-table-summary' : ''}
          key={this.props.currentPageName + '.' + this.props.questionName + '.' + type}
          id={this.props.currentPageName + '.' + this.props.questionName + '.' + type}
          disabled={this.props.disabled || this.props.rowComment === ''}
          questionName={this.props.questionName}
          currentPageName={this.props.currentPageName}
          label={this.props.options[type] || ''}
          format={this.props.options.format || '00000'}
          tokens={summaryTokens || []}
          labelWidth={10}
          inputWidth={10}
          type={type}
        />
      )
    })
    return <TableRow style={withToggleStyle}>{columns}</TableRow>
  }
}
_MatrixRow.displayName = '_MatrixRow'

export const MatrixRowWithComment = withToggle(connect(mstp)(_MatrixRow))
export const MatrixRow = withToggle(_MatrixRow)
