import * as React from 'react'
import { connect } from 'react-redux'
import get from 'lodash.get'

import { executeNonRenderable } from '../store/survey-data'

import type { ReduxState, Dispatch } from '../store'
import type { PageObject } from '../types'

type OwnProps = {|
  page: PageObject,
  deps: Array<string>,
  scripts: Object,
|}

type MappedProps = {|
  watch: string,
|}

type Props = { dispatch: Dispatch } & OwnProps & MappedProps

const mstp = (state: ReduxState, ownProps: OwnProps): MappedProps => {
  let watch = ownProps.deps.reduce((acc, identifier) => {
    let depValue = get(state.data, identifier.split('.'))
    if (typeof depValue === 'undefined') {
      throw new Error(`Invalid identifier: ${identifier}`)
    }
    return `${acc} ${identifier} ===> ${JSON.stringify(depValue)} `
  }, '')
  return { watch }
}

class ScriptWatch extends React.PureComponent<Props> {
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.watch !== this.props.watch) {
      let { page, scripts, interpolator, evalAllExpressions } = this.props
      await this.props.dispatch(executeNonRenderable(page, scripts, interpolator, true, evalAllExpressions))
    }
  }

  render() {
    return null
  }
}

export default connect(mstp)(ScriptWatch)
