import * as React from 'react'
import { Table } from 'semantic-ui-react'

import HTMLText from '../../system-wide/html-text'
import { CommentItemField } from '../../formfields/comment-field.js'
import withToggle from '../../../enhancers/toggleable'

const TableHeaderCell = Table.HeaderCell
const TableHeader = Table.Header
const TableRow = Table.Row

class _MatrixHeaderCell extends React.Component {
  static defaultProps = {
    hasComment: false,
  }

  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    const qName = `${this.props.questionName}_row_${this.props.itemKey}`
    return (
      <TableHeaderCell width={this.props.width} className={this.props.className} style={withToggleStyle}>
        <div style={textStyle}>
          <HTMLText style={{ textAlign: 'center', width: '100%', display: 'inline-block' }} value={this.props.value} />
        </div>
        {this.props.hasComment === true && (
          <CommentItemField
            label={this.props.value}
            name={`_rowcomment`}
            visible={this.props.visible}
            disabled={this.props.disabled}
            questionName={qName}
            currentPageName={this.props.currentPageName || ''}
            jsonref={`${this.props.currentPageName}.${qName}.comment_matrix`}
          />
        )}
      </TableHeaderCell>
    )
  }
}
_MatrixHeaderCell.displayName = '_MatrixHeaderCell'
const MatrixHeaderCell = withToggle(_MatrixHeaderCell)

const MatrixHeader = props => {
  const { currentPageName, questionName } = props
  const mod = props.isVertical ? 'row' : 'col'

  let columns = []
  columns.push(<TableHeaderCell className="matrix left cell" key="0-0" />)

  props.elements.forEach(elem => {
    let jsonref = `${currentPageName}.${questionName}_${mod}_${elem.key}`
    columns.push(
      <MatrixHeaderCell
        className="matrix cell"
        key={elem.key}
        itemKey={elem.key}
        value={elem.value}
        jsonref={jsonref}
        parentDisabled={props.parentDisabled}
        currentPageName={currentPageName}
        questionName={questionName}
        hasComment={elem.has_comment ? elem.has_comment : false}
      />
    )
  })
  return (
    <TableHeader className="matrix">
      <TableRow>{columns}</TableRow>
    </TableHeader>
  )
}

export default MatrixHeader
