import * as React from 'react'
import { Grid, Form } from 'semantic-ui-react'
import striptags from 'striptags'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import { CommentField } from './comment-field'

export class RadioField extends React.Component {
  handleChange = (e, { value = '' }) => {
    let label = striptags(this.props.label, ['img'], '').trim()
    try {
      this.props.dispatch(
        handleDataChange(
          this.props.currentPageName,
          this.props.questionName,
          this.props['data-name'] || '',
          value,
          label ? label : value
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.disabled !== nextProps.disabled ||
      this.props.visible !== nextProps.visible ||
      this.props.active !== nextProps.active ||
      this.props.hasError !== nextProps.hasError ||
      this.props.parentDisabled !== nextProps.parentDisabled ||
      this.props.questionSkipped !== nextProps.questionSkipped ||
      this.props.itemKey === this.props.replyValue ||
      this.props.itemKey === nextProps.replyValue
    )
  }

  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    let radioValue = this.props.itemKey || ''
    let checked = radioValue === (this.props.replyValue || '')
    return (
      <Grid.Column
        {...Constants.getWidths(this.props.colSize || Constants.FULLWIDTH)}
        className={this.props.className || ''}
        style={withToggleStyle}>
        <Form.Radio
          id={`${this.props.currentPageName}-${this.props.questionName}-${radioValue}`}
          name={this.props.name}
          value={radioValue}
          checked={checked}
          {...(this.props.hasError ? { error: true } : {})}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          label={
            <label
              className={this.props.labelClassName}
              htmlFor={`${this.props.currentPageName}-${this.props.questionName}-${radioValue}`}>
              <HTMLText value={this.props.label || ''} className={this.props.labelClassName} />
            </label>
          }
        />
        {this.props.hasComment && (
          <CommentField
            forceVisible={checked}
            name={`${radioValue}_comment`}
            currentPageName={this.props.currentPageName || ''}
            questionName={this.props.questionName}
            jsonref={`${this.props.currentPageName}.${this.props.questionName}.${radioValue}_comment`}
          />
        )}
      </Grid.Column>
    )
  }
}
RadioField.displayName = 'RadioField'
