import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import DatePicker from '@alcacoop/react-datepicker'
import { CustomView, isMobile } from 'react-device-detect'
import '@alcacoop/react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import 'moment/locale/it'
import it from 'date-fns/locale/it'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import { CommentItemField } from './comment-field'
import strings from '../../locales'

moment.locale('it')
const dateFieldStyle = { marginTop: '0.3rem' }

export class TimeField extends React.Component {
  static defaultProps = {
    hasComment: false,
    format: 'HH:mm',
    placeholder: 'hh:mm',
    minTime: '00:00',
    maxTime: '24:00',
  }

  minTime
  maxTime

  constructor(props) {
    super(props)
    let minTime = props.minTime === 'NOW' ? moment().format('HH:mm') : props.minTime
    let maxTime = props.maxTime === 'NOW' ? moment().format('HH:mm') : props.maxTime

    this.minTime = moment(minTime, 'HH:mm').toDate()
    this.maxTime = moment(maxTime, 'HH:mm')
      .subtract(maxTime === '24:00' ? 1 : 0, 'seconds')
      .toDate()
  }

  handleChange = e => {
    let date = isMobile === true ? moment(e.target.value, 'HH:mm').format('HH:mm') : moment(e).format('HH:mm')

    if (
      !moment(date, 'HH:mm').isValid() ||
      date > moment(this.maxTime).format('HH:mm') ||
      date < moment(this.minTime).format('HH:mm')
    )
      date = ''
    try {
      this.props.dispatch(handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, date))
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    let { labelWidth, inputWidth } = Constants.getDefaultFieldWidths(this.props.questionWidth)
    let inputValue = moment(this.props.replyValue, 'HH:mm').format('HH:mm')

    let dateStyle = { opacity: this.props.disabled ? '0.45' : '1' }

    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    let disabled = this.props.disabled || this.props.emptyComment === true

    return (
      <React.Fragment>
        {this.props.label && (
          <Grid.Column
            className="multiple"
            {...Constants.getWidths(labelWidth)}
            style={{ ...dateFieldStyle, ...withToggleStyle }}>
            <span style={dateStyle}>
              <HTMLText value={this.props.label} />
            </span>
            {this.props.hasComment === true && (
              <CommentItemField
                disabled={this.props.disabled}
                visible={this.props.visible}
                currentPageName={this.props.currentPageName}
                questionName={this.props.questionName}
                name={this.props.name + '_comment'}
                jsonref={this.props.jsonref + '_comment'}
              />
            )}
          </Grid.Column>
        )}

        <Grid.Column className="multiple" {...Constants.getWidths(inputWidth)} style={withToggleStyle}>
          <React.Fragment>
            <CustomView condition={isMobile === true} renderWithFragment>
              <input
                type="time"
                name={this.props.name}
                className={`ui form field ${disabled ? 'disabled' : ''} ${this.props.hasError ? 'error' : ''}`}
                style={this.props.hasComment === true ? { marginTop: '1.4rem' } : {}}
                value={inputValue}
                disabled={disabled}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                min={moment(this.minTime).format('HH:mm')}
                max={moment(this.maxTime).format('HH:mm')}
              />
            </CustomView>
            <CustomView condition={isMobile === false} renderWithFragment>
              <div style={this.props.hasComment === true ? { marginTop: '1.4rem', width: '9rem' } : { width: '9rem' }}>
                <DatePicker
                  name={this.props.name}
                  className={`ui form datepicker field ${disabled ? 'disabled' : ''} ${
                    this.props.hasError ? 'error' : ''
                  }`}
                  disabled={disabled}
                  selected={this.props.replyValue ? moment(this.props.replyValue, 'HH:mm').toDate() : null}
                  onChange={this.handleChange}
                  placeholderText={this.props.placeholder}
                  locale={it}
                  minTime={this.minTime}
                  maxTime={this.maxTime}
                  strictParsing
                  isClearable={true}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption={`${strings.time_picker}`}
                  dateFormat="HH:mm"
                />
              </div>
            </CustomView>
          </React.Fragment>
        </Grid.Column>
      </React.Fragment>
    )
  }
}

export default TimeField
