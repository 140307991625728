export const getSummaryExpression = (type, format, tokens) => {
  let dec = ((format || '00000').split('.')[1] || '').length
  let expr = ''
  let value = ''

  switch (type) {
    case 'sum':
    case 'colsum':
      value = `[].filter.call([${tokens.join(
        ', '
      )}], function(p){return p!==null}).reduce(function(acc, p){return acc+p}, 0)`
      expr = `%((Number(Math.round(${value} + 'e' + ${dec}) + 'e-' + ${dec})).toFixed(${dec}))%`
      break
    case 'prod':
    case 'colprod':
      value = `[].filter.call([${tokens.join(
        ', '
      )}], function(p){return p!==null}).reduce(function(acc, p, i){return i===0?p:acc*p}, 0)`
      expr = `%((Number(Math.round(${value} + 'e' + ${dec}) + 'e-' + ${dec})).toFixed(${dec}))%`
      break
    case 'avg':
    case 'colavg':
      value = `(function(){
        var a = [].filter.call([${tokens.join(', ')}], function(p){return p!==null});
        if (a.length===0) { 
          return (0).toFixed(${dec});
        } else {
          return Number(Math.round((a.reduce(function(acc, p){return acc+p}, 0) / a.length) + 'e' + ${dec}) + 'e-' + ${dec}).toFixed(${dec})
        }
      })()`
      expr = `%(${value.replace(/\n/g, ' ')})%`
      break
    default:
      expr = ''
  }

  return expr
}
