import * as React from 'react'
import './loader.css'

const Loader = props => {
  let style = props.overlay ? { backgroundColor: 'rgba(255,255,255,.75)' } : {}
  let fixed = props.fixed ? { position: 'fixed', top: '48%' } : {}
  if (!props.loaded) {
    return (
      <div className="full-page-loader-container" style={style}>
        <div className="full-page-loader" style={fixed}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  } else {
    return props.children ? props.children : null
  }
}

export default Loader
