import * as React from 'react'
import { connect } from 'react-redux'
import striptags from 'striptags'
import { Grid, Form } from 'semantic-ui-react'
import { CustomView, isMobile } from 'react-device-detect'
import get from 'lodash.get'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import Divider from '../system-wide/divider'
import Interpolator from '../../utils/interpolator'
import { store } from '../../store'
import { setToggleableState } from '../../store/survey-toggleable'
import { CommentItemField, CommentField } from './comment-field'
import { handleMSelectItemCommmetDataChange } from '../../store/survey-data'

import withToggle from '../../enhancers/toggleable'
import connectField from '../../enhancers/connect-field'

class NativeSelect extends React.PureComponent {
  onChange = e => {
    this.props.onChange(e, { name: this.props.name, value: e.target.value, options: this.props.options })
  }
  render() {
    let options = this.props.options.map(e => {
      return (
        <option value={e.value} key={`key-${e.key}`}>
          {e.text}
        </option>
      )
    })
    return (
      <div className="select-wrapper">
        <select
          className={`ui form ${this.props.disabled ? 'disabled' : ''} field ${this.props.hasError ? 'error' : ''}`}
          name={this.props.name}
          disabled={this.props.disabled}
          onChange={this.onChange}
          placeholder="---"
          value={this.props.value || ''}>
          {options}
        </select>
      </div>
    )
  }
}
NativeSelect.displayName = 'NativeSelect'

class _RadioField extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Form.Radio
          label={this.props.label}
          value={this.props.value}
          disabled={this.props.disabled}
          {...(this.props.hasError ? { error: true } : {})}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
        {this.props.hasradiocomment === '1' && (
          <CommentField
            isMSelect={true}
            visible={this.props.checked}
            disabled={this.props.disabled}
            name={`${this.props.name}_${this.props.value}_comment`}
            currentPageName={this.props.currentPageName || ''}
            questionName={this.props.questionName}
            jsonref={`${this.props.currentPageName}.${this.props.questionName}.${this.props.name}_${this.props.value}_comment`}
            handleDataChangeCallback={handleMSelectItemCommmetDataChange}
          />
        )}
      </React.Fragment>
    )
  }
}
const RadioField = withToggle(connectField(_RadioField), 'ReplyItem')

const sepRegExp = new RegExp('^separator')

class RadioGroup extends React.PureComponent {
  onChange = (e, { name, value }) => {
    this.props.onChange(e, { name: this.props.name, value, options: this.props.options })
  }
  render() {
    let options = this.props.options
      .filter(o => o.value !== '')
      .map(e => {
        return sepRegExp.test(e.key) ? (
          <Divider
            className="mselect-radiogroup-divider"
            key={e.key}
            disabled={this.props.disabled || e.disabled}
            value={e.text}
          />
        ) : (
          <RadioField
            key={e.key}
            name={this.props.name}
            label={e.text}
            value={e.value}
            parentDisabled={this.props.disabled || e.disabled}
            hasError={this.props.hasError}
            checked={this.props.value === e.value}
            onChange={this.onChange}
            currentPageName={this.props.currentPageName}
            questionName={this.props.questionName}
            hasradiocomment={e.hasradiocomment}
            jsonref={`${this.props.currentPageName}.${this.props.questionName}.${this.props.name}.:${e.value}`}
          />
        )
      })
    return <div className="mselect-radiogroup">{options}</div>
  }
}

class Select extends React.PureComponent {
  static defaultProps = {
    useRadio: false,
  }

  handleSelect = (e, { name, value, options }) => {
    try {
      let label = options.filter(el => el.value === value)[0].origtext
      label = striptags(label, [], '').trim()
      value = value === '' ? null : value
      store.dispatch(
        handleDataChange(
          this.props.currentPageName,
          this.props.questionName,
          this.props.name,
          value,
          label ? label : value || ''
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    let disabled = this.props.parentDisabled || this.props.data.length === 1
    let useNativeSelect = this.props.useRadio === false && (isMobile === true || this.props.forceNativeSelect === true)
    let useDropdown = this.props.useRadio === false && isMobile === false && this.props.forceNativeSelect === false

    return (
      <React.Fragment>
        <CustomView condition={useNativeSelect === true} renderWithFragment>
          <NativeSelect
            fluid
            hasError={this.props.hasError}
            options={this.props.data}
            name={this.props.name}
            value={this.props.replyValue}
            disabled={disabled}
            onChange={this.handleSelect}
            placeholder="---"
            style={this.props.hasComment ? { marginTop: '1.1rem' } : {}}
          />
        </CustomView>
        <CustomView condition={useDropdown === true} renderWithFragment>
          <Form.Dropdown
            fluid
            search={!disabled}
            selection
            {...(this.props.hasError ? { error: true } : {})}
            options={this.props.data}
            name={this.props.name}
            value={this.props.replyValue}
            disabled={disabled}
            onChange={this.handleSelect}
            noResultsMessage="Nessun risultato"
            selectOnBlur={false}
            placeholder="---"
            style={this.props.hasComment ? { marginTop: '1.1rem' } : {}}
          />
        </CustomView>
        <CustomView condition={this.props.useRadio === true} renderWithFragment>
          <RadioGroup
            fluid
            hasError={this.props.hasError}
            options={this.props.data}
            name={this.props.name}
            currentPageName={this.props.currentPageName}
            questionName={this.props.questionName}
            value={this.props.replyValue}
            disabled={disabled}
            onChange={this.handleSelect}
            style={this.props.hasComment ? { marginTop: '1.1rem' } : {}}
          />
        </CustomView>
      </React.Fragment>
    )
  }
}
Select.displayName = 'Select'

const mapStateToProps = interpolator => (state, ownProps) => {
  let { currentPageName, questionName, renderAs, jsonref } = ownProps
  let page = state.internalState.expressionValues[`${currentPageName}`] || {}
  let data = state.data[currentPageName][questionName]
  let name = typeof ownProps['data-name'] !== 'undefined' ? ownProps['data-name'] : ownProps.name

  let questionSkipped = ownProps.questionSkipped || false
  let pickerData = [
    {
      key: 'key-none',
      value: '',
      text: '---',
      origtext: '---',
      ...(renderAs === 'radiolist' && { hasradiocomment: '0' }),
    },
  ]

  let isMSelect = false
  let mselectQName = ''
  if (/_row_/.test(questionName)) {
    mselectQName = questionName.split('_row_')[0]
    isMSelect = state.data[currentPageName][mselectQName]['__qtype'] === 'mselect'
  }

  let selectOptions = ownProps.selectOptions || []
  selectOptions.forEach((option, i) => {
    if (option.type === 'separator') {
      if (renderAs === 'radiolist') {
        pickerData.push({
          key: `separator-${i}`,
          disabled: questionSkipped || ownProps.disabled,
          text: Interpolator.needInterpolation(option.value)
            ? interpolator.render(striptags(option.value, [], ' ').trim())
            : striptags(option.value, [], ' ').trim(),
        })
      }
      return
    }

    let disabled = false
    let expressionKey = isMSelect ? `${currentPageName}.${mselectQName}_rlitem.${option.key}` : option.jsonref

    if (ownProps.disabled) {
      disabled = true
    } else {
      let disableif =
        typeof page[`${expressionKey}.disableif`] !== 'undefined' ? page[`${expressionKey}.disableif`] : false
      let enableif = typeof page[`${expressionKey}.enableif`] !== 'undefined' ? page[`${expressionKey}.enableif`] : true
      disabled = questionSkipped || disableif || !enableif
    }

    let displayif =
      typeof page[`${expressionKey}.displayif`] !== 'undefined' ? page[`${expressionKey}.displayif`] : true
    let hideif = typeof page[`${expressionKey}.hideif`] !== 'undefined' ? page[`${expressionKey}.hideif`] : false
    let visible = displayif && !hideif

    if (renderAs !== 'radiolist') {
      visible = disabled ? false : visible
    }

    if (visible) {
      pickerData.push({
        key: `key-${option.key}`,
        value: option.key,
        ...(renderAs === 'radiolist' && { disabled }),
        origtext: striptags(option.value, [], ' ').trim(),
        text: Interpolator.needInterpolation(option.value)
          ? interpolator.render(striptags(option.value, [], ' ').trim())
          : striptags(option.value, [], ' ').trim(),
        ...(renderAs === 'radiolist'
          ? { hasradiocomment: typeof data[`${name}_${option.key}_comment`] !== 'undefined' ? '1' : '0' }
          : {}),
      })
    }
  })

  let questionDisabled = !state.toggleables[currentPageName][questionName]['questionStatus']
  let parentDisabled = ownProps.parentDisabled || data[name + '_comment'] === '' || data['_rowcomment'] === ''

  let questionStatus = state['validators']['questionStatuses'][currentPageName][questionName]
  let errors = state['validators']['errorList'][currentPageName][questionName]
  let hasError = errors.indexOf(jsonref) !== -1 && questionStatus === 'INVALID'

  return {
    data: pickerData,
    comment: data[`${name}_comment`],
    rowComment: data[`_rowcomment`],
    replyValue: data[name],
    disableComment: questionDisabled || questionSkipped,
    parentDisabled,
    hasError,
  }
}

export class _SelectField extends React.PureComponent {
  static defaultProps = {
    hasComment: false,
    forceNativeSelect: false,
  }

  componentDidMount() {
    let prevToggleableState = get(store.getState(), [
      'toggleables',
      this.props.currentPageName,
      this.props.questionName,
      'repliesStatus',
      this.props.name,
    ])
    let state = prevToggleableState && !this.props.disabled && this.props.visible && this.props.data.length > 1
    this.props.dispatch(setToggleableState(this.props.currentPageName, this.props.questionName, this.props.name, state))
  }

  componentDidUpdate(prevProps) {
    let state = store.getState()
    let prevToggleableState = get(state, [
      'toggleables',
      this.props.currentPageName,
      this.props.questionName,
      'repliesStatus',
      this.props.name,
    ])
    let questionToggleableState = get(state, [
      'toggleables',
      this.props.currentPageName,
      this.props.questionName,
      'questionStatus',
    ])
    let commentState = typeof this.props.comment === 'undefined' ? true : this.props.comment !== ''
    let currentToggleableState =
      commentState && !this.props.disabled && this.props.data.length > 1 && this.props.visible

    if (questionToggleableState === true && prevToggleableState !== currentToggleableState) {
      if (typeof this.props.comment !== 'undefined' && this.props.comment === prevProps.comment) return //FIX UPDATE LOOP
      this.props.dispatch(
        setToggleableState(this.props.currentPageName, this.props.questionName, this.props.name, currentToggleableState)
      )
    }
  }

  render() {
    const { labelWidth, inputWidth } = Constants.getDefaultFieldWidths(this.props.questionWidth)
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = {
      opacity: this.props.disabled || this.props.questionSkipped || this.props.data.length === 1 ? '0.45' : '1',
    }

    return (
      <React.Fragment>
        {this.props.label && (
          <Grid.Column
            className={`multiple ${this.props.className}`}
            {...Constants.getWidths(labelWidth)}
            style={withToggleStyle}>
            <span style={textStyle}>
              <HTMLText value={this.props.label || ''} className={this.props.labelClassName} />
            </span>
            {this.props.hasComment === true && (
              <CommentItemField
                disabled={this.props.disabled || this.props.data.length === 1}
                visible={this.props.visible}
                currentPageName={this.props.currentPageName}
                questionName={this.props.questionName}
                name={this.props.name + '_comment'}
                jsonref={this.props.jsonref + '_comment'}
              />
            )}
          </Grid.Column>
        )}
        <Grid.Column
          className={`multiple ${this.props.className}`}
          {...Constants.getWidths(inputWidth)}
          style={withToggleStyle}>
          <Select
            name={this.props.name}
            hasError={this.props.hasError}
            parentDisabled={this.props.disabled || this.props.comment === '' || this.props.rowComment === ''}
            currentPageName={this.props.currentPageName || ''}
            questionName={this.props.questionName}
            jsonref={this.props.jsonref}
            questionSkipped={this.props.questionSkipped}
            data={this.props.data}
            replyValue={this.props.replyValue}
            hasComment={this.props.hasComment}
            forceNativeSelect={this.props.forceNativeSelect}
            useRadio={this.props.renderAs === 'radiolist'}
          />
        </Grid.Column>
      </React.Fragment>
    )
  }
}

export const SelectField = connect(mapStateToProps(new Interpolator()))(_SelectField)
