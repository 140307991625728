import * as React from 'react'
import { Grid, Form } from 'semantic-ui-react'
import DatePicker from '@alcacoop/react-datepicker'
import { CustomView, isMobile } from 'react-device-detect'
import '@alcacoop/react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import 'moment/locale/it'
import it from 'date-fns/locale/it'
import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import { CommentItemField } from './comment-field'

moment.locale('it')
const dateFieldStyle = { marginTop: '0.3rem' }
const range = (s, e) => Array.from('x'.repeat(e - s + 1), (_, i) => s + i).reverse()

class YearSelect extends React.Component {
  static defaultProps = {
    handleChange: () => {},
  }

  render() {
    let { inputWidth } = Constants.getDefaultFieldWidths()
    let minYear = parseInt(moment(this.props.minDate).format('YYYY'))
    let maxYear = parseInt(moment(this.props.maxDate).format('YYYY'))
    let years = ['---']

    if (maxYear >= minYear) {
      years = range(minYear, maxYear)
      let currentYear = parseInt(moment().format('YYYY'))
      if (maxYear < currentYear) {
        // add to tail
        years.push('---')
      } else if (minYear > currentYear) {
        // add to head
        years.splice(0, 0, '---')
      } else {
        // add before current year
        years.splice(years.indexOf(currentYear), 0, '---')
      }
    }

    let options = years.map(year => {
      return (
        <option value={year !== '---' ? year : ''} key={`key-${year}`}>
          {year}
        </option>
      )
    })

    let dropDownYears = years.map(year => {
      let value = year === '---' ? null : year
      return {
        key: 'key-' + year,
        origtext: year,
        text: year,
        value,
      }
    })

    return (
      <Grid.Column className="multiple" {...Constants.getWidths(inputWidth)}>
        <CustomView condition={isMobile === true} renderWithFragment>
          <div className="select-wrapper container input-width-5">
            <select
              className={`ui form field ${this.props.disabled ? 'disabled' : ''} ${this.props.hasError ? 'error' : ''}`}
              style={this.props.hasComment === true ? { marginTop: '1.4rem' } : {}}
              name={this.props.name}
              disabled={this.props.disabled}
              onChange={this.props.handleChange}
              placeholder="---"
              value={this.props.value || ''}>
              {options}
            </select>
          </div>
        </CustomView>
        <CustomView condition={isMobile === false} renderWithFragment>
          <Form.Dropdown
            fluid
            search
            selection
            {...(this.props.hasError ? { error: true } : {})}
            options={dropDownYears}
            name={this.props.name}
            style={this.props.hasComment === true ? { marginTop: '1.4rem', width: '9rem' } : { width: '9rem' }}
            value={this.props.value}
            disabled={this.props.disabled}
            onChange={this.props.handleChange}
            selectOnBlur={false}
            placeholder="---"
            noResultsMessage="No results"
            clearable
          />
        </CustomView>
      </Grid.Column>
    )
  }
}

export class DateField extends React.Component {
  static defaultProps = {
    hasComment: false,
    format: '',
    placeholder: '',
    minDate: '',
    maxDate: '',
  }

  constructor(props) {
    super(props)
    this.minDate = moment(props.minDate || '', props.format || '').toDate()
    this.maxDate = moment(props.maxDate || '', props.format || '').toDate()
  }

  minDate
  maxDate

  handleChangeYear = (e, obj) => {
    let dateStr = isMobile === true ? parseInt(e.target.value) : parseInt(obj.value)
    let maxDate = parseInt(moment(this.maxDate).format('YYYY'))
    let minDate = parseInt(moment(this.minDate).format('YYYY'))
    if (dateStr > maxDate || dateStr < minDate || isNaN(dateStr)) dateStr = null

    try {
      this.props.dispatch(
        handleDataChange(
          this.props.currentPageName,
          this.props.questionName,
          this.props.name,
          dateStr !== null ? dateStr : null
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  handleChange = e => {
    let input = isMobile === true ? e.target.value : e
    let format =
      /^MM[-./]?(YYYY|YY)$/i.test(this.props.format || '') || /^(YYYY|YY)[-./]?MM$/i.test(this.props.format || '')
        ? 'YYYYMM'
        : 'YYYYMMDD'
    let dateStr = moment(input || '', format).format(format)
    let maxDate = moment(this.maxDate).format(format)
    let minDate = moment(this.minDate).format(format)

    if (!moment(dateStr, format).isValid() || dateStr > maxDate || dateStr < minDate) dateStr = null
    try {
      this.props.dispatch(
        handleDataChange(
          this.props.currentPageName,
          this.props.questionName,
          this.props.name,
          dateStr !== null ? parseInt(dateStr) : null
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    let { labelWidth, inputWidth } = Constants.getDefaultFieldWidths(this.props.questionWidth)
    let mobile_maxDate, mobile_minDate
    let inputValue = ''

    let dateStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    let inputType =
      /^MM[-./]?(YYYY|YY)$/i.test(this.props.format || '') || /^(YYYY|YY)[-./]?MM$/i.test(this.props.format || '')
        ? 'month'
        : 'date'
    if (this.props.replyValue) {
      if (inputType === 'month') inputValue = moment(this.props.replyValue, 'YYYYMM').format('YYYY-MM')
      else inputValue = moment(this.props.replyValue, 'YYYYMMDD').format('YYYY-MM-DD')
    }

    if (inputType === 'month') {
      mobile_minDate = moment(this.minDate).format('YYYY-MM')
      mobile_maxDate = moment(this.maxDate).format('YYYY-MM')
    } else {
      mobile_minDate = moment(this.minDate).format('YYYY-MM-DD')
      mobile_maxDate = moment(this.maxDate).format('YYYY-MM-DD')
    }

    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    let disabled = this.props.disabled || this.props.emptyComment === true

    return (
      <React.Fragment>
        {this.props.label && (
          <Grid.Column
            className="multiple"
            {...Constants.getWidths(labelWidth)}
            style={{ ...dateFieldStyle, ...withToggleStyle }}>
            <span style={dateStyle}>
              <HTMLText value={this.props.label} />
            </span>
            {this.props.hasComment === true && (
              <CommentItemField
                disabled={this.props.disabled}
                visible={this.props.visible}
                currentPageName={this.props.currentPageName}
                questionName={this.props.questionName}
                name={this.props.name + '_comment'}
                jsonref={this.props.jsonref + '_comment'}
              />
            )}
          </Grid.Column>
        )}

        <Grid.Column className="multiple" {...Constants.getWidths(inputWidth)} style={withToggleStyle}>
          {/(^yyyy$)|(^yy$)/i.test(this.props.format || '') ? (
            <YearSelect
              minDate={this.minDate}
              maxDate={this.maxDate}
              name={this.props.name}
              value={this.props.replyValue}
              handleChange={this.handleChangeYear}
              disabled={disabled}
              hasError={this.props.hasError}
              hasComment={this.props.hasComment || false}
            />
          ) : (
            <React.Fragment>
              <CustomView condition={isMobile === true} renderWithFragment>
                <input
                  type={inputType}
                  name={this.props.name}
                  className={`ui form field ${disabled ? 'disabled' : ''} ${this.props.hasError ? 'error' : ''}  ${
                    inputType === 'month' ? 'month-picker' : 'datepicker'
                  }`}
                  style={this.props.hasComment === true ? { marginTop: '1.4rem' } : {}}
                  value={inputValue}
                  disabled={disabled}
                  onChange={this.handleChange}
                  placeholder={this.props.placeholder}
                  min={mobile_minDate}
                  max={mobile_maxDate}
                />
              </CustomView>
              <CustomView condition={isMobile === false} renderWithFragment>
                <div
                  style={this.props.hasComment === true ? { marginTop: '1.4rem', width: '9rem' } : { width: '9rem' }}>
                  <DatePicker
                    name={this.props.name}
                    className={`ui form datepicker field ${disabled ? 'disabled' : ''} ${
                      this.props.hasError ? 'error' : ''
                    }`}
                    disabled={disabled}
                    selected={this.props.replyValue ? moment(this.props.replyValue, 'YYYYMMDD').toDate() : null}
                    onChange={this.handleChange}
                    dateFormat={(this.props.format || '').replace(/D/g, 'd').replace(/Y/g, 'y')}
                    showMonthYearPicker={inputType === 'month' ? true : false}
                    placeholderText={this.props.placeholder}
                    locale={it}
                    minDate={this.minDate}
                    maxDate={this.maxDate}
                    strictParsing
                    isClearable={true}
                    showYearDropdown
                    yearDropdownItemNumber={7}
                    scrollableYearDropdown
                    dateFormatCalendar="MMMM"
                  />
                </div>
              </CustomView>
            </React.Fragment>
          )}
        </Grid.Column>
      </React.Fragment>
    )
  }
}
DateField.displayName = 'DateField'

export default DateField
