import * as React from 'react'
import { connect } from 'react-redux'
import { Segment, Icon, Button, Progress } from 'semantic-ui-react'
import get from 'lodash.get'
import { isIOS } from 'react-device-detect'
import { scriptBuilder } from '../utils/scriptbuilder'

import Page from './page'
import {
  setCurrentPage,
  setPageRequestIdx,
  setDirection,
  setPageSubmitStatus,
  setExitStatus,
  setStopStatus,
  isPageVisible,
  isPageExecutable,
  getNextPageIdx,
  prepareSnapshot,
  getPageIndexByName,
  setOutOfQuota,
  emptyGotoPage,
  resetDataForNextPages,
} from '../store/survey-internal-state'
import { resetErrorList, setPageValidationStatus } from '../store/survey-validation'
import { isPageValid } from '../utils/validation-helpers'
import { executeNonRenderable, updateTimer } from '../store/survey-data'
import ErrorMessage from './system-wide/error-message'
import generateExpressions from './expressions'
import Loader from './system-wide/loader'
import Dimmer from './system-wide/dimmer'
import HTMLText from './system-wide/html-text'
import Interpolator from '../utils/interpolator'
import QuitConfirmModal from './quit-confirm-modal'
import generateRespondentTracker from './respondent-tracker'
import ScriptWatch from './script-watch'
import strings from '../locales'

import { store } from '../store'

const mapStateToProps = state => {
  let pages = state.internalState.pages
  let currentPageIdx = state.internalState.currentPageIdx
  let requestedPageIdx = state.internalState.requestedPageIdx
  let currentPage = pages[currentPageIdx]
  let showStopConfirm =
    typeof state.definition.show_stop_confirm !== 'undefined' ? state.definition.show_stop_confirm : true
  return {
    currentPageIdx,
    requestedPageIdx,
    currentPage,
    pageSubmitStatus: state.internalState.pageSubmitStatus,
    stopStatus: state.internalState.stopStatus,
    name: state.definition.survey,
    survey_title: state.definition.survey_title || '',
    showStopConfirm,
    pages,
    outofquota: state.internalState.outofquota,
  }
}
const SegmentStyle = { paddingLeft: '0', paddingRight: '0' }

export class Survey extends React.Component {
  static defaultProps = {
    hideStopButton: false,
  }

  modalMountnodeRef = { current: null }
  interpolator = null
  scripts = {}
  scriptWatchComponents = []
  startTime = 0

  constructor(props) {
    super(props)
    this.modalMountnodeRef = React.createRef()
    this.interpolator = new Interpolator()
    let scripts = this.props.pages.filter(p => get(p, ['page_options', 'execute'], '') === 'script')
    if (scripts.length > 0) {
      for (let i = 0; i < scripts.length; i++) {
        let { name, page_options } = scripts[i]
        this.scripts[name] = scriptBuilder(page_options.code || '', name, this.props.transpile, this.props.onCheckQuota)
      }

      this.scriptWatchComponents = scripts.reduce((acc, s) => {
        let watchingDeps = s.page_options.watch
        if (watchingDeps) {
          acc.push(
            <ScriptWatch
              key={s.name}
              page={s}
              deps={watchingDeps}
              scripts={this.scripts}
              interpolator={this.interpolator}
              evalAllExpressions={this.evalAllExpressions}
            />
          )
        }
        return acc
      }, [])
    }
  }

  async componentDidMount() {
    if (this.props.outofquota) {
      let index = getPageIndexByName(store, '__outofquota_survey')
      this.props.dispatch(setCurrentPage(index))
      let exitWithRedirect = get(this.props.pages[index].content[0], ['options', 'redirect'], '')
      let redirectUrl = this.interpolator.renderUrl(exitWithRedirect)
      if (redirectUrl !== '' && store.getState().internalState.mode === 'production') {
        window.location.replace(redirectUrl)
      }
      return
    }

    let { pages, currentPageIdx: idx } = this.props
    let { scripts, interpolator, evalAllExpressions } = this
    while (!isPageVisible(store, idx) || isPageExecutable(store, idx)) {
      // Execute non-renderable pages
      if (isPageVisible(store, idx) && isPageExecutable(store, idx)) {
        let response = await this.props.dispatch(
          executeNonRenderable(pages[idx], scripts, interpolator, false, evalAllExpressions)
        )
        if (response.status !== 'success') {
          if (response.status === 'error_script') {
            this.throwError(response.msg)
          } else {
            this.throwError(strings.network_error_on_initialize_msg)
          }
        }
      }
      idx++
    }
    if (idx !== this.props.currentPageIdx) {
      this.props.dispatch(setCurrentPage(idx))
      this.props.dispatch(setPageSubmitStatus('COMPLETED'))
    }

    let currentPage = this.props.pages[idx]
    // Execute pre-exit actions if typeof first visible page is end_page
    if (currentPage.page_options.is_end_page && store.getState().internalState.exitType !== '') {
      let exitType = get(currentPage.content[0], ['options', 'exit_type'], 'COMPLETED')
      let exitCode = get(currentPage.content[0], ['options', 'exit_code'], 'COMPLETED')
      this.props.dispatch(setExitStatus(exitType, exitCode, currentPage.name))

      let exitWithRedirect = get(currentPage.content[0], ['options', 'redirect'], '')
      let snapshot = prepareSnapshot(store, idx)

      await this.props.onPageSubmit(snapshot.data, snapshot)
      await this.props.onSurveyCompleted(snapshot.data, snapshot)

      let redirectUrl = this.interpolator.renderUrl(exitWithRedirect)
      if (redirectUrl !== '' && store.getState().internalState.mode === 'production') {
        window.location.replace(redirectUrl)
      }
    }

    if (['__end_survey', '__stop_survey', '__outofquota_survey'].indexOf(this.props.name) === -1) {
      this.startTime = Date.now()
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.stopStatus !== prevProps.stopStatus && this.props.stopStatus === 'CONFIRMED') {
      await this.goToPage(getPageIndexByName(store, '__stop_survey'))
      return
    }
    if (this.props.outofquota !== prevProps.outofquota && this.props.outofquota) {
      this.props.dispatch(setCurrentPage(getPageIndexByName(store, '__outofquota_survey')))
      return
    }

    if (
      this.props.requestedPageIdx !== -1 &&
      this.props.pageSubmitStatus !== 'ERROR' &&
      prevProps.requestedPageIdx !== this.props.requestedPageIdx
    ) {
      let direction = this.props.requestedPageIdx - this.props.currentPageIdx
      this.props.dispatch(setDirection(direction < 0 ? 'back' : 'next'))
      this.props.dispatch(setPageRequestIdx(-1))
      if (direction < 0) {
        //BACK
        let oldPageName = this.props.currentPage.name
        await this.goToPage(this.props.requestedPageIdx)
        this.props.dispatch(resetErrorList(oldPageName))
      } else {
        //FORWARD
        if (isPageValid(this.props.currentPage.name)) {
          this.props.dispatch(setPageValidationStatus(this.props.currentPage.name, 'FRESH'))
          await this.goToPage(this.props.requestedPageIdx)
        } else {
          this.props.dispatch(setPageSubmitStatus('COMPLETED'))
        }
      }
    }
  }

  evalAllExpressions = async () => {
    for (let expr of this.props.expressionRefs) {
      await expr.current.eval()
    }
  }

  throwError = msg => {
    this.setState(() => {
      throw new Error(msg)
    })
  }

  retryOnError = index => {
    this.props.dispatch(setPageSubmitStatus('ERROR'))
    this.props.dispatch(setPageRequestIdx(index))
  }

  forceReflow = () => {
    window.requestAnimationFrame(() => {
      window.document.body.style.display = 'inline'
      window.requestAnimationFrame(() => {
        window.document.body.style.display = 'block'
      })
    })
  }

  goToPage = async index => {
    let isStopped = this.props.pages[index].name === '__stop_survey'
    let { currentPageName, direction, stepList } = store.getState().internalState
    let previousPageIdx = getPageIndexByName(store, stepList[stepList.length - 1])

    if (['__end_survey', '__stop_survey', '__outofquota_survey'].indexOf(currentPageName) === -1) {
      let duration = Date.now() - this.startTime
      this.props.dispatch(updateTimer(currentPageName, duration))
    }
    this.startTime = Date.now()

    if (!isPageExecutable(store, previousPageIdx)) {
      //EXECUTE DEPENDENT EXPRESSIONS AND RE-EVALUATE NEXT PAGE IDX
      await this.evalAllExpressions()
      if (!isStopped) index = getNextPageIdx(store, direction)
    }

    // Execute non-renderable pages
    if (isPageExecutable(store, index)) {
      let { scripts, interpolator, evalAllExpressions } = this
      let result = await this.props.dispatch(
        executeNonRenderable(this.props.pages[index], scripts, interpolator, false, evalAllExpressions)
      )
      if (result.status === 'success') {
        let nextIndex = getNextPageIdx(store, 'next', index)
        this.props.dispatch(emptyGotoPage())
        this.props.dispatch(setPageRequestIdx(nextIndex))
      } else if (result.status === 'error_recoverable') {
        this.retryOnError(index)
      } else if (result.status === 'error_script') {
        this.throwError(result.msg)
      } else {
        this.throwError(strings.network_error_msg)
      }
      return
    }

    this.props.dispatch(setPageValidationStatus(this.props.pages[index].name, 'FRESH'))

    let exitType = ''
    let exitCode = ''
    let exitWithRedirect = ''
    if (this.props.pages[index].page_options.is_end_page) {
      exitType = get(this.props.pages[index].content[0], ['options', 'exit_type'], 'COMPLETED')
      exitCode = get(this.props.pages[index].content[0], ['options', 'exit_code'], 'COMPLETED')
      exitWithRedirect = get(this.props.pages[index].content[0], ['options', 'redirect'], '')
      this.props.dispatch(setExitStatus(exitType, exitCode, this.props.pages[index].name))
    }

    if (isStopped) {
      exitWithRedirect = get(this.props.pages[index].content[0], ['options', 'redirect'], '')
    }

    try {
      // survey handlers call
      let onSurveyCompletedResult = true
      let onPageSubmittedResult = true
      let outOfQuotaResult = false
      this.props.dispatch(resetDataForNextPages(index))
      let snapshot = prepareSnapshot(store, index)

      if (isStopped) {
        await this.props.onSurveyStopped(snapshot.data, snapshot)
      } else {
        let { status, outofquota } = await this.props.onPageSubmit(snapshot.data, snapshot)
        onPageSubmittedResult = status
        outOfQuotaResult = outofquota
        if (this.props.pages[index].page_options.is_end_page) {
          onSurveyCompletedResult = await this.props.onSurveyCompleted(snapshot.data, snapshot)
        }
      }

      if (onPageSubmittedResult === false || onSurveyCompletedResult === false) {
        this.retryOnError(index)
      } else {
        if (outOfQuotaResult) {
          index = getPageIndexByName(store, '__outofquota_survey')
          this.props.dispatch(setOutOfQuota(outOfQuotaResult, this.props.onSurveyCompleted))
          exitWithRedirect = get(this.props.pages[index].content[0], ['options', 'redirect'], '')
        }

        let redirectUrl = this.interpolator.renderUrl(exitWithRedirect)
        if (redirectUrl !== '' && store.getState().internalState.mode === 'production') {
          window.location.replace(redirectUrl)
        } else {
          this.props.scrollToTop()
          this.props.dispatch(setCurrentPage(index))
          this.props.dispatch(setPageSubmitStatus('COMPLETED'))
          if (isIOS) this.forceReflow() // WORKAROUND: resolve blank page on iOS
        }
      }
    } catch (error) {
      if (error.name === 'InterpolatorError') {
        this.props.onError(error)
      } else {
        this.retryOnError(index)
      }
    }
  }

  handleNextPage = () => {
    window.requestAnimationFrame(() => {
      this.props.dispatch(setPageValidationStatus(this.props.currentPage.name, 'SUBMITTED'))
      this.props.dispatch(setPageSubmitStatus('PENDING'))
      window.requestAnimationFrame(() => {
        let idx = getNextPageIdx(store, 'next')
        this.props.dispatch(setPageRequestIdx(idx))
      })
    })
  }

  handlePrevPage = () => {
    this.props.dispatch(setPageSubmitStatus('PENDING'))
    let idx = getNextPageIdx(store, 'back')
    this.props.dispatch(setPageRequestIdx(idx))
  }

  handleRetryButton = async () => {
    await this.goToPage(this.props.requestedPageIdx)
  }

  handleStopButton = async () => {
    let status = this.props.showStopConfirm ? 'SHOW' : 'CONFIRMED'
    await this.props.dispatch(setStopStatus(status))
  }

  render() {
    let isFirst = getNextPageIdx(store, 'back') === -1
    let isLast = getNextPageIdx(store, 'next') === -2
    let progress = this.props.currentPage.page_options.is_end_page
      ? this.props.pages.length
      : this.props.currentPageIdx + 1
    let showStopButton =
      this.props.hideStopButton === false &&
      this.props.currentPage.page_options.show_stop_button &&
      !this.props.readOnly

    if (isPageExecutable(store, this.props.currentPageIdx)) {
      return <Loader fixed loaded={false} />
    }
    return (
      <React.Fragment>
        {this.scriptWatchComponents}
        <QuitConfirmModal mountNode={this.modalMountnodeRef.current} />
        <div className="resurvey header" ref={this.modalMountnodeRef}>
          <HTMLText value={this.props.survey_title} />
        </div>
        <Segment className="resurvey page" style={SegmentStyle}>
          <Dimmer active={this.props.pageSubmitStatus === 'ERROR'}>
            <p style={{ fontWeight: 'bold' }}>{strings.network_error_msg}</p>
            <Button icon labelPosition="right" color="red" onClick={this.handleRetryButton}>
              {strings.retry_button}
              <Icon name="redo" />
            </Button>
          </Dimmer>
          <Loader overlay fixed loaded={this.props.pageSubmitStatus !== 'PENDING'} />
          <Progress value={progress} total={this.props.pages.length - 1} attached="top" />
          {showStopButton === true && (
            <Button
              icon
              size="tiny"
              primary
              onClick={this.handleStopButton}
              style={{
                margin: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                zIndex: 2,
                position: 'absolute',
                right: 0,
              }}>
              <Icon name="close" onClick={this.handleStopButton} />
            </Button>
          )}
          <Page
            {...this.props.currentPage}
            readOnly={this.props.readOnly}
            isFirst={isFirst}
            isLast={isLast}
            handlePrevPage={this.handlePrevPage}
            handleNextPage={this.handleNextPage}
            key={this.props.currentPage.name}
            forceTitles={this.props.forceTitles}
          />
        </Segment>
      </React.Fragment>
    )
  }
}

const ConnectedSurvey = connect(mapStateToProps)(Survey)

class SurveyRenderer extends React.PureComponent {
  constructor(props) {
    super(props)
    let { expressions, expressionRefs, errors } = generateExpressions()
    this.RespondentTracker = generateRespondentTracker()
    this.expressions = expressions
    this.errors = errors
    this.expressionRefs = expressionRefs
  }

  render() {
    let { RespondentTracker } = this
    return (
      <React.Fragment>
        <RespondentTracker />
        {this.errors.length > 0 && <ErrorMessage title="Error" errors={this.errors} />}
        {this.expressions}
        <ConnectedSurvey {...this.props} expressionRefs={this.expressionRefs} />
      </React.Fragment>
    )
  }
}

export default SurveyRenderer
