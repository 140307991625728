import { store } from '../store'
import { helpers, helperNames } from './rscode-helpers'
import parse from './jsparser'

const generateExprFn = expr => {
  let program = `
    var ret = ${expr};
    return !!ret;
  `
  return new Function('data', 'toggleables', ...helperNames, program) //eslint-disable-line
}

export const expressionBuilder = (exprCode, exprKey = '') => {
  let { data, toggleables } = store.getState()
  let errors = []
  let exprObject = {}
  let exprFunction = null

  try {
    let { code, deps, tDeps } = parse(exprCode, false)
    exprFunction = generateExprFn(code)

    exprObject = {
      deps: [...deps],
      tDeps: [...tDeps],
      exprFunction,
      initialValue: exprFunction(data, toggleables, ...helpers),
    }
  } catch (e) {
    let msg = `Expression ${exprKey} - Runtime error: ${e.message}`
    if (e instanceof SyntaxError) msg = `Expression ${exprKey} - Syntax error: ${e.message}`
    errors.push(msg)
  }

  return { exprObject, errors }
}

export default expressionBuilder
