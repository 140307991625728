import get from 'lodash.get'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/it'

import getProxy from '../utils/proxy'
import Constants from '../utils/constants'
import { setBulkItemsToggleableState } from './survey-toggleable'
import { updateSteplist } from './update-steplist'
import strings from '../locales'

moment.locale('it')

// Action types
export const SET_SURVEY_DATA = 'survey/SET_SURVEY_DATA'
export const UPDATE_REPLY = 'survey/UPDATE_REPLY'
export const RESET_DATA = 'survey/RESET_DATA'
export const RESET_PAGE_DATA = 'survey/RESET_PAGE_DATA'
export const UPDATE_TIMER = 'survey/UPDATE_TIMER'
export const FORCE_UPDATE = 'survey/FORCE_UPDATE'

// Initial state
export const initialState = {
  TIMERS: {},
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SURVEY_DATA:
      return action.payload
    case UPDATE_REPLY:
    case RESET_DATA: {
      let otherQuestions = state[action.payload.pageName]
      return {
        ...state,
        [action.payload.pageName]: {
          ...otherQuestions,
          [action.payload.questionName]: action.payload.replies,
        },
      }
    }
    case RESET_PAGE_DATA: {
      return {
        ...state,
        [action.payload.pageName]: action.payload.pageData,
      }
    }
    case UPDATE_TIMER: {
      let newTimers = {
        ...state.TIMERS,
        [action.payload.pageName]: state.TIMERS[action.payload.pageName] + action.payload.increment,
      }
      let __total = Object.keys(newTimers).reduce((acc, k) => {
        return /^__/.test(k) ? acc : acc + newTimers[k]
      }, 0)
      return {
        ...state,
        TIMERS: { ...newTimers, __total },
      }
    }
    case FORCE_UPDATE: {
      return { ...state }
    }
    default:
      return state
  }
}

// Action creators
export const setSurveyData = data => ({
  type: SET_SURVEY_DATA,
  payload: data,
})

export const updateReply = (pageName, questionName, replies) => ({
  type: UPDATE_REPLY,
  payload: { pageName, questionName, replies },
})

const _resetData = (pageName, questionName, replies) => ({
  type: RESET_DATA,
  payload: { pageName, questionName, replies },
})

const _resetPageData = (pageName, pageData) => ({
  type: RESET_PAGE_DATA,
  payload: { pageName, pageData },
})

export const updateTimer = (pageName, increment) => ({
  type: UPDATE_TIMER,
  payload: { pageName, increment },
})

export const forceUpdate = () => ({
  type: FORCE_UPDATE,
})

const _getEmptyValue = (replies, key) => {
  let qtype = replies['__qtype']
  if (key === 'skipped') return null
  if (/_label|_comment|_optionalcomment|_rowcomment/.test(key)) return ''

  let emptyValue = ''
  if (
    [
      'radiolist',
      'select',
      'checkboxlist',
      'script',
      'date',
      'dragndrop',
      'ranking',
      'searchtable',
      'search',
      'video',
    ].includes(qtype)
  ) {
    emptyValue = null
  } else if (qtype === 'geolocation') {
    emptyValue = {
      latitude: null,
      longitude: null,
      accuracy: null,
      isGeolocationAvailable: true,
      isGeolocationEnabled: false,
    }
  } else if (replies[key] === null || ['number', 'rating'].includes(qtype)) {
    emptyValue = null
  }
  return emptyValue
}

export const resetMatrixColumnData = jsonref => (dispatch, getState) => {
  let [pageName, colName] = jsonref.split('.')
  let [qName, colKey] = colName.split('_col_')
  let pageData = getState().data[pageName]
  let qRows = Object.keys(pageData).filter(e => e.indexOf(qName + '_row_') !== -1)
  qRows.forEach(q => {
    let resetJsonref = '_rep' in pageData[q] ? `${pageName}.${q}._rep.:${colKey}` : `${pageName}.${q}.${colKey}`
    dispatch(resetData(resetJsonref))
  })
}

export const resetMSelectReplistItemData = jsonref => (dispatch, getState) => {
  let [pageName, qName, replistItemKey] = jsonref.split('.')
  qName = qName.replace('_rlitem', '')
  let pageData = getState().data[pageName]
  let qRows = Object.keys(pageData).filter(e => e.indexOf(qName + '_row_') !== -1)
  let qCols = Object.keys(pageData[qRows[0]]).filter(k => {
    return !/_qtype$/.test(k) && !/_label/.test(k) && !/_rowcomment/.test(k)
  })

  for (let row of qRows) {
    for (let col of qCols) {
      dispatch(resetData(`${pageName}.${row}.${col}.:${replistItemKey}`))
    }
  }
}

export const resetData = _jsonref => (dispatch, getState) => {
  let jsonref = _jsonref.replace('_comment', '') //empty reply if related comment is "" (ITEM_COMMENTS)
  jsonref = jsonref.replace('.comment_matrix', '') //empty all matrix row if related comment is "" (MATRIX ITEM_COMMENTS)

  let dataKeyArr = jsonref.split('.')
  let pageData = getState().data[dataKeyArr[0]]

  if (dataKeyArr.length === 1) {
    // reset entire page
    let newPageData = {}
    for (let q in pageData) {
      let replies = { ...pageData[q] }
      for (let k in replies) {
        if (k !== '__qtype' && k !== '__dateFormat' && replies['__qtype'] !== 'video') {
          replies[k] = _getEmptyValue(replies, k)
        }
      }
      newPageData[q] = replies
    }
    dispatch(_resetPageData(dataKeyArr[0], newPageData))
  } else if (dataKeyArr.length === 2) {
    // reset entire question
    let replies = { ...pageData[dataKeyArr[1]] }
    for (let k in replies) {
      if (k !== '__qtype' && k !== '__dateFormat' && replies['__qtype'] !== 'video') {
        replies[k] = _getEmptyValue(replies, k)
      }
    }
    dispatch(_resetData(dataKeyArr[0], dataKeyArr[1], replies))
  } else if (dataKeyArr.length === 3) {
    let replyKey = dataKeyArr[2]
    // reset single item in question
    let replies = { ...pageData[dataKeyArr[1]] }

    // RESET REPLY VALUE ON ALL QUESTIONS EXCEPT RADIOLIST
    if (
      replies.__qtype !== 'radiolist' ||
      (replies.__qtype === 'radiolist' && !('_rep' in replies) && _jsonref.indexOf('_comment') === -1) // MSELECT AND DON'T WANT TO RESET COMMENT
    ) {
      replies[replyKey] = _getEmptyValue(replies, replyKey)
    }

    // EMPTY ASSOCIATED LABEL
    if (typeof replies[`${replyKey}_label`] !== 'undefined') {
      replies[`${replyKey}_label`] = ''
    }
    // EMPTY ASSOCIATED COMMENT
    if (typeof replies[`${replyKey}_comment`] !== 'undefined') {
      replies[`${replyKey}_comment`] = ''
    }
    dispatch(_resetData(dataKeyArr[0], dataKeyArr[1], replies))
  } else if (dataKeyArr.length === 4) {
    if (dataKeyArr[3].startsWith(':')) {
      //RADIO AND SELECT ITEMS!
      let itemKey = dataKeyArr[3].replace(':', '')
      let itemValue = pageData[dataKeyArr[1]][dataKeyArr[2]]
      if (itemKey === itemValue || pageData[dataKeyArr[1]][`${dataKeyArr[2]}_comment`]) {
        let replies = { ...pageData[dataKeyArr[1]] }
        for (let k in replies) {
          if (
            k !== '__qtype' &&
            k !== '__dateFormat' &&
            (k === dataKeyArr[2] || k === `${dataKeyArr[2]}_label` || k === `${dataKeyArr[2]}_comment`)
          ) {
            replies[k] = _getEmptyValue(replies, k)
          }
        }
        dispatch(_resetData(dataKeyArr[0], dataKeyArr[1], replies))
      }
    }
  }
}

export const handleDataChange =
  (pageName, questionName, replyName, value, optionalLabel = '') =>
  (dispatch, getState) => {
    const replies = getState().data[pageName][questionName]
    const items = getState().internalState.items[pageName][questionName]
    let qtype = replies['__qtype']
    let newReps = { ...replies }

    if (Object.keys(items).length !== 0 && qtype !== 'radiolist' && qtype !== 'video') {
      // handle questions configured with unique...
      let isNotEmpty = qtype === 'checkboxlist' ? value === 1 : value !== _getEmptyValue(newReps, replyName)

      for (let k in items) {
        if (k !== replyName) {
          //caller or i-th items are unique
          if (typeof items[k]['unique'] !== 'undefined' || typeof items[replyName]['unique'] !== 'undefined') {
            //do not empty checkbox if caller is a comment field!
            if (isNotEmpty && k !== replyName.replace('_comment', '')) {
              newReps[k] = _getEmptyValue(newReps, k)
              if (typeof replies[`${k}_label`] !== 'undefined') newReps[`${k}_label`] = ''
              if (typeof replies[`${k}_comment`] !== 'undefined') newReps[`${k}_comment`] = ''
            }
          }
        } else {
          newReps[k] = value
          if (typeof replies[`${k}_label`] !== 'undefined') newReps[`${k}_label`] = value ? optionalLabel : ''
        }
      }
    } else {
      // handle questions configured without unique...
      // Reset other's _comments
      newReps[replyName] = value
      if (qtype === 'radiolist' && typeof replies['_rep'] !== 'undefined') {
        for (let k in replies) {
          if (/_comment/.test(k)) {
            let item = '_rep'
            let val = k.split('_')[0]
            if (newReps[item] !== val) {
              newReps[k] = ''
            }
          }
        }
      }
      if (optionalLabel !== '') {
        newReps[`${replyName}_label`] = value ? optionalLabel : ''
      }
    }

    // Update data question replies
    dispatch(updateReply(pageName, questionName, newReps))
  }

export const handleItemCommentDataChange = (pageName, questionName, replyName, value) => (dispatch, getState) => {
  const replies = getState().data[pageName][questionName]
  const items = getState().internalState.items[pageName][questionName]
  let newReps = { ...replies }
  let itemKey = replyName.replace('_comment', '')

  if (replyName !== '_rowcomment') {
    for (let k in items) {
      if (
        k !== replyName &&
        (typeof items[k]['unique'] !== 'undefined' || typeof items[itemKey]['unique'] !== 'undefined')
      ) {
        newReps[k] = _getEmptyValue(newReps, k)
        if (typeof replies[`${k}_comment`] !== 'undefined') newReps[`${k}_comment`] = ''
      }
    }
  }
  newReps[replyName] = value
  dispatch(updateReply(pageName, questionName, newReps))
}

export const handleMSelectItemCommmetDataChange =
  (pageName, questionName, replyName, value) => (dispatch, getState) => {
    const replies = getState().data[pageName][questionName]
    let newReps = { ...replies }
    newReps[replyName] = value
    dispatch(updateReply(pageName, questionName, newReps))
  }

export const handleDragndropDataChange = (pageName, questionName, value) => (dispatch, getState) => {
  const replies = getState().data[pageName][questionName]
  let newReps = { ...replies, ...value }
  dispatch(updateReply(pageName, questionName, newReps))
}

export const handleSearchtableDataChange = (pageName, questionName, value) => (dispatch, getState) => {
  const replies = getState().data[pageName][questionName]
  let newReps = { ...replies, ...value }
  dispatch(updateReply(pageName, questionName, newReps))
}

export const handleRankingDataChange = (pageName, questionName, value) => (dispatch, getState) => {
  const replies = getState().data[pageName][questionName]
  let newReps = { ...replies, ...value }
  dispatch(updateReply(pageName, questionName, newReps))
}

export const handleSkippedDataChange =
  (pageName, questionName, value, optionalLabel = '') =>
  (dispatch, getState) => {
    const replies = getState().data[pageName][questionName]
    let qtype = replies['__qtype']
    let newReps = {}

    // handle skipped questions
    for (let k in replies) {
      let v = replies[k]
      switch (k) {
        case '__qtype':
        case '__dateFormat':
          newReps[k] = v
          break
        case 'skipped':
          newReps[k] = value
          break
        case 'skipped_label':
          newReps[k] = value ? optionalLabel : ''
          break
        default:
          if (qtype === 'video') {
            newReps[k] = v
          } else {
            newReps[k] = value ? _getEmptyValue(replies, k) : v
          }
      }
    }

    let toggleablesStatus = { ...getState().toggleables[pageName][questionName]['repliesStatus'] }
    if (value === 1) {
      for (let s in toggleablesStatus) {
        toggleablesStatus[s] = false
      }
    } else {
      let expressionValues = getState().internalState.expressionValues[pageName]
      for (let s in toggleablesStatus) {
        let keyPref = `${pageName}.${questionName}.${s}`
        let disableif = get(expressionValues, `${keyPref}.disableif`, false)
        let enableif = get(expressionValues, `${keyPref}.enableif`, true)
        let displayif = get(expressionValues, `${keyPref}.displayif`, true)
        let hideif = get(expressionValues, `${keyPref}.hideif`, false)

        let status = enableif && !disableif && displayif && !hideif
        toggleablesStatus[s] = s.indexOf('_comment') !== -1 ? false : status
      }
    }

    // reset all toggleables
    dispatch(setBulkItemsToggleableState(pageName, questionName, toggleablesStatus))
    // Update data question replies
    dispatch(updateReply(pageName, questionName, newReps))
  }

export const executeNonRenderable =
  (page, scripts, interpolator, watchCall, evalAllExpressions) => async (dispatch, getState) => {
    // return: {status: success | recoverable_error | unrecoverable_error | error_script}

    if (watchCall === false) dispatch(updateSteplist([page.name]))
    let isWatchingScript = typeof get(page, ['page_options', 'watch']) !== 'undefined'
    if (isWatchingScript && !watchCall) return { status: 'success' } //do not execute watching script on standard traversing

    let state = getState()

    switch (page.page_options.execute) {
      case 'getdata':
        try {
          let url = get(page.content[0], ['options', 'url'])
          url = typeof url === 'undefined' ? url : interpolator.renderUrl(url)
          let pathTks = window.location.pathname.split('/')
          let uids = pathTks[pathTks.length - 1]
          let method = get(page.content[0], ['options', 'method'])
          let params = page.content.reduce((acc, q) => [...acc, q.qtext[0].value], [])
          let reqOptions = {
            url: '/api/getdata',
            method: 'POST',
            validateStatus: null,
            data: { url, uids, method, params },
          }

          let response = {}
          if (state.internalState.mode === 'production') {
            response = await axios(reqOptions)
            if (response.status !== 200) {
              throw new Error(`GETDATA: ${strings.network_error_msg}`)
            }
          } else {
            console.log(reqOptions)
          }
          if (Object.keys(response).length !== 0) {
            page.content.forEach(q => {
              let replies = get(state, ['data', page.name, q.name], {})
              let _rep = get(response, ['data', 'data', q.qtext[0].value], '')
              dispatch(updateReply(page.name, q.name, { ...replies, _rep }))
            })
            await evalAllExpressions()
          }
        } catch (err) {
          return { status: 'error_script', msg: err.message }
        }

        return { status: 'success' }

      case 'script':
        try {
          let { fn, scriptHelpers } = scripts[page.name]

          if (!watchCall) {
            dispatch({ type: 'survey/SET_PAGE_SUBMIT_STATUS', payload: 'PENDING' })
          }

          let proxiedData = getProxy(state.data, getCheckValueFn(state))
          let resetOnEnter = get(page.content[0], ['options', 'reset_on_enter'], false)
          if (resetOnEnter) {
            for (let k in state.data[page.name]) proxiedData[page.name][k]['_rep'] = null
          }
          await fn(proxiedData, state.toggleables, ...scriptHelpers)
          // FORCE data SLICE PROPAGATION
          dispatch(forceUpdate())
          await evalAllExpressions()
          return { status: 'success' }
        } catch (e) {
          return { status: 'error_script', msg: `Errore script ${page.name}: ${e.message ? e.message : e}` }
        }

      default:
        return { status: 'success' }
    }
  }

const getCheckValueFn = state => (pName, qName, prop, value) => {
  const { pages } = state.internalState
  let result = {
    valid: true,
    label: '',
    _value: value,
    labelKey: '',
  }
  let question = pages.find(p => p.name === pName).content.find(q => q.name === qName)

  if (/_comment$/.test(prop)) {
    if (typeof value === 'undefined') {
      result._value = ''
      return result
    }
    let maxlength = question.type === 'memo' ? Constants.DEFAULTS.MEMO_MAX_LENGTH : Constants.DEFAULTS.TEXT_MAX_LENGTH
    result.valid = typeof value === 'string' && value.length <= maxlength
    if (result.valid) {
      result._value = value
    }
    return result
  }

  if (prop === 'skipped') {
    if (typeof value === 'undefined') value = null
    result.valid = parseInt(value) === 0 || parseInt(value) === 1 || value === null
    if (result.valid) {
      result.label = question.options.allowskip || ''
      result.labelKey = `skipped_label`
      result._value = value === null ? value : parseInt(value)
    }
    return result
  }

  switch (question.type) {
    case 'script':
      if (typeof value === 'undefined') result._value = null
      return result

    case 'text':
    case 'memo':
      if (typeof value === 'undefined') {
        result._value = ''
        return result
      }
      result.valid = typeof value === 'string' && value.length <= parseInt(question.options.maxlength)
      return result

    case 'number':
      if (value === null || value === '' || typeof value === 'undefined') {
        result._value = null
        result.valid = true
        return result
      }
      if (typeof value !== 'number') {
        result.valid = false
        return result
      }
      let format = question.options.format
      let units = format.split('.')[0]
      let decimals = format.split('.')[1] || ''
      let valueUnits = value.toString().split('.')[0]
      let valueDecimals = value.toString().split('.')[1] || ''
      result.valid = valueUnits.length <= units.length && valueDecimals.length <= decimals.length
      return result

    case 'rating':
      if (typeof value === 'undefined') {
        result._value = null
        result.valid = true
        return result
      }
      result.valid = value === null || (typeof value === 'number' && value > 0 && value <= question.options.maxrating)
      return result

    case 'checkboxlist':
      if (typeof value === 'undefined') value = null
      result.valid = parseInt(value) === 0 || parseInt(value) === 1 || value === null
      if (result.valid) {
        result.label = parseInt(value) === 1 ? question.items.filter(i => i.key === prop)[0].value : ''
        result.labelKey = `${prop}_label`
        result._value = value === null ? value : parseInt(value)
      }
      console.log(result)
      return result

    case 'ranking':
    case 'dragndrop': {
      if (value === null || value === '' || typeof value === 'undefined') {
        result.valid = true
        result.label = ''
        result._value = null
        result.labelKey = `${prop}_label`
        return result
      }
      let allowedValues = question.items
        .filter(v => typeof v.key !== 'undefined')
        .reduce((acc, el) => [...acc, el.key], [])
      result.valid = allowedValues.indexOf(value.toString()) !== -1
      if (result.valid) {
        result.label = question.items.filter(v => v.key === value.toString())[0].value
        result.labelKey = `${prop}_label`
        result._value = value.toString()
      }
      return result
    }

    case 'select':
    case 'radiolist':
      if (value === null || value === '' || typeof value === 'undefined') {
        result.valid = true
        result.label = ''
        result._value = null
        result.labelKey = `${prop}_label`
        return result
      }
      let allowedValues = question.values
        .filter(v => typeof v.key !== 'undefined')
        .reduce((acc, el) => [...acc, el.key], [])
      result.valid = allowedValues.indexOf(value.toString()) !== -1
      if (result.valid) {
        result.label = question.values.filter(v => v.key === value.toString())[0].value
        result.labelKey = `${prop}_label`
        result._value = value.toString()
      }
      return result

    case 'date':
    case 'time':
      if (value === null || value === '' || typeof value === 'undefined') {
        result._value = null
        result.valid = true
        return result
      }

      if (typeof value !== 'string') {
        result.valid = false
        return result
      }

      let dateFormat =
        /^MM[-./]?(YYYY|YY)$/i.test(question.options.format) || /^(YYYY|YY)[-./]?MM$/i.test(question.options.format)
          ? 'YYYYMM'
          : question.options.format === 'YYYY'
            ? 'YYYY'
            : 'YYYYMMDD'

      let dateValue = moment(value, question.options.format, true)
      if (!dateValue.isValid()) {
        result.valid = false
        return result
      }
      result._value = parseInt(dateValue.format(dateFormat))
      return result

    case 'search':
    case 'searchtable':
    default:
      return result
  }
}

export const loadUrl = async url => {
  try {
    await axios.post(`/api/loadurl`, { url })
  } catch (err) {
    let status = get(err, ['response', 'data', 'status'], '')
    if (status === 'error_unrecoverable') {
      return { status }
    } else {
      return { status: 'error_recoverable' }
    }
  }

  return { status: 'success' }
}

export default reducer
