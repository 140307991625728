import get from 'lodash.get'
import moment from 'moment'

import { store } from '../store'
import { isAnswered as vhIsAnswered, isSkipped as vhIsSkipped } from './validation-helpers'
import { getPageIndexByName, isPageVisible } from '../store/survey-internal-state'
import { forceUpdate } from '../store/survey-data'

export const getChildQuestions = tks => {
  let regexp = new RegExp(`${tks[1]}_row_`)
  let s = store.getState()
  return Object.keys(get(s.internalState.items, [tks[0]], {})).filter(k => regexp.test(k))
}

const cite = (...questions) => {
  let cited = []
  let { data } = store.getState()
  questions.forEach(tks => {
    let reply = tks.join('.')
    let qtype = get(data, [tks[0], tks[1], '__qtype'])
    if (['checkboxlist', 'select'].indexOf(qtype) === -1) {
      throw new Error(`cite()/citeList() not supported on qtype ${qtype.toUpperCase()}`)
    }
    if (tks.length !== 2) {
      throw new Error(`cite(${reply}): wrong argument`)
    }
    let slice = get(data, tks, {})
    Object.keys(slice).forEach(key => {
      if (/_label$/.test(key)) cited.push(slice[key])
    })
  })
  return cited.filter(c => !!c)
}

export const helperNames = [
  'isAnswered',
  'isActive',
  'isSkipped',
  'isValueActive',
  'countChecked',
  'isValueIn',
  'label',
  'comment',
  'cite',
  'citeList',
  'getUID',
  'formatDate',
  'now',
  '__normalizeDate',
  '__getCurrentValue',
  '__onChange',
]

const _isActive = tks => {
  if (tks.length === 1) {
    //IsActive called on entire page
    let pageIdx = getPageIndexByName(store, tks[0])
    if (pageIdx === -1) throw new Error(`Invalid identifier: ${tks[0]}`)
    return isPageVisible(store, pageIdx)
  }

  let { data, toggleables } = store.getState()
  let questionStatus = toggleables[tks[0]][tks[1]]['questionStatus']
  let replies = data[tks[0]][tks[1]]
  if (tks.length === 2) {
    if ('_rowcomment' in replies) {
      return questionStatus && replies['_rowcomment'] !== ''
    } else {
      return questionStatus
    }
  } else if (tks.length === 3) {
    return questionStatus && toggleables[tks[0]][tks[1]]['repliesStatus'][tks[2]]
  }
}

const _isAnswered = tks => {
  if (tks.length === 1) {
    //IsAnwered called on entire page
    let pageIdx = getPageIndexByName(store, tks[0])
    if (pageIdx === -1) throw new Error(`Invalid identifier: ${tks[0]}`)
    let { internalState } = store.getState()
    let page = internalState.pages[pageIdx]
    return page.content.reduce((acc, question) => {
      return acc && _isAnswered([tks[0], question.name])
    }, true)
  }

  let { data } = store.getState()
  let qtype = get(data, [tks[0], tks[1], '__qtype'])

  if (['matrix', 'mmatrix', 'mrating', 'mtext', 'mnumber', 'mranking', 'mselect'].indexOf(qtype) !== -1) {
    // For matrix questions at least one row must be active and all active childQuestions must be answered
    let activeSubQuestions = getChildQuestions(tks).filter(qname => _isActive([tks[0], qname]))
    return (
      activeSubQuestions.length > 0 &&
      activeSubQuestions.reduce((acc, qname) => {
        return acc && vhIsAnswered([tks[0], qname]).status
      }, true)
    )
  }

  if (qtype === 'geolocation') {
    let { isGeolocationAvailable = false, latitude = null } = get(data, [tks[0], tks[1], '_rep'])
    return isGeolocationAvailable === false || latitude !== null
  }

  return vhIsAnswered([tks[0], tks[1]], tks.length > 2 ? tks[2] : '').status
}

//USED ONLY IN VALID_IF/INVALID_IF CONTEXT TO VALIDATE MATRIX SUBQUESTIONS
export const isAnsweredWithMatrixRowsWorkaround = tks => {
  if (/_row_[\d]+/.test(tks[1])) {
    return !_isActive(tks) || _isAnswered(tks)
  } else {
    return _isAnswered(tks)
  }
}

//NOTE: position in this array MUST match helpeNames order
export const helpers = [
  function isAnswered(tks) {
    return _isAnswered(tks)
  },

  function isActive(tks) {
    return _isActive(tks)
  },

  function isSkipped(tks) {
    return vhIsSkipped(tks)
  },

  function isValueActive(tks, value) {
    let { data } = store.getState()
    let qtype = get(data, [tks[0], tks[1], '__qtype'])
    if (['radiolist', 'select'].indexOf(qtype) === -1) {
      throw new Error(`isValueActive() not supported on qtype ${qtype.toUpperCase()}`)
    }
    let { toggleables } = store.getState()
    let questionStatus = toggleables[tks[0]][tks[1]]['questionStatus']
    return questionStatus && toggleables[tks[0]][tks[1]]['repliesStatus'][`_rep.:${value[0]}`]
  },

  function countChecked(tks) {
    let { data } = store.getState()
    let qtype = get(data, [tks[0], tks[1], '__qtype'])
    if (['checkboxlist'].indexOf(qtype) === -1) {
      throw new Error(`countChecked() not supported on qtype ${qtype.toUpperCase()}`)
    }
    if (tks.length !== 2) {
      throw new Error(`countChecked(${tks.join('.')}): wrong argument`)
    }
    let slice = get(data, [tks[0], tks[1]])
    return Object.keys(slice).reduce((acc, k) => {
      if (!/(qtype|skipped|_label|comment)/.test(k)) {
        return (acc += slice[k])
      } else {
        return acc
      }
    }, 0)
  },

  function isValueIn(tks, ...args) {
    let { data } = store.getState()
    let qtype = get(data, [tks[0], tks[1], '__qtype'])
    if (['radiolist', 'select'].indexOf(qtype) === -1) {
      throw new Error(`isValueIn() not supported on qtype ${qtype.toUpperCase()}`)
    }
    let values = []
    args.forEach(vtks => values.push(vtks[0]))
    let dataValue = ''
    switch (qtype) {
      case 'radiolist':
        dataValue = get(data, [tks[0], tks[1], '_rep'])
        break
      case 'select':
        if (tks.length === 2) {
          dataValue = get(data, [tks[0], tks[1], '_rep'])
        }
        if (tks.length === 3) {
          dataValue = get(data, tks)
        }
        if (typeof dataValue === 'undefined') {
          throw new Error(`isValueIn(${tks.join('.')}, ${values.join(', ')}): wrong argument`)
        }
        break
      default:
        break
    }
    return values.indexOf(dataValue) !== -1
  },

  function label(tks, column = '') {
    let { data } = store.getState()
    let qtype = get(data, [tks[0], tks[1], '__qtype'])
    if (
      ['radiolist', 'checkboxlist', 'select', 'dragndrop', 'ranking', 'searchtable', 'search'].indexOf(qtype) === -1
    ) {
      throw new Error(`label() not supported on qtype ${qtype.toUpperCase()}`)
    }
    let _label = ''
    switch (qtype) {
      case 'radiolist':
        _label = get(data, [tks[0], tks[1], '_rep_label'])
        break
      case 'checkboxlist':
      case 'select':
      case 'dragndrop':
      case 'ranking':
      case 'search':
        if (tks.length === 2) {
          _label = get(data, [tks[0], tks[1], '_rep_label'])
        }
        if (tks.length === 3) {
          _label = get(data, [tks[0], tks[1], `${tks[2]}_label`])
        }
        if (typeof _label === 'undefined') {
          throw new Error(`label(${tks.join('.')}): wrong argument`)
        }
        break
      case 'searchtable':
        if (column === '') {
          throw new Error(`label(${tks.join('.')}, 'columnName'): missing column name argument`)
        }
        if (tks.length === 2) {
          _label = get(data, [tks[0], tks[1], `_rep_label_${column}`])
        }
        if (tks.length === 3) {
          _label = get(data, [tks[0], tks[1], `${tks[2]}_label_${column}`])
        }
        if (typeof _label === 'undefined') {
          throw new Error(`label(${tks.join('.')}, ${column}): wrong arguments`)
        }
        break
      default:
        break
    }
    return _label
  },

  function comment(tks) {
    let { data } = store.getState()
    let _comment = ''
    if (tks.length === 2) {
      _comment = get(data, [tks[0], tks[1], `_rowcomment`], false)
    } else {
      _comment = get(data, [tks[0], tks[1], `${tks[2]}_comment`], false)
    }

    if (_comment === false) {
      throw new Error(`comment(${tks.join('.')}): wrong argument`)
    } else {
      return _comment
    }
  },

  cite,

  function citeList(...questions) {
    let cited = cite(...questions)
    if (cited.length) return '<ul><li>' + cited.join('</li><li>') + '</li></ul>'
    else return ''
  },

  function getUID() {
    let { data } = store.getState()
    return get(data, ['PRESEED', '__uid'], 'DEVMODE')
  },

  function formatDate(tks) {
    let { data } = store.getState()
    let dateValue = get(data, [tks[0], tks[1], tks.length > 2 ? tks[2] : '_rep'])
    let page = store.getState().internalState.pages.filter(k => k.name === tks[0])[0]
    let question = page.content.filter(q => q.name === tks[1])[0]
    let format = get(question, ['options', 'format'], 'DD/MM/YYYY')
    return dateValue !== null ? moment(dateValue, 'YYYYMMDD').format(format) : null
  },

  function now(increment, unit, format) {
    if (format === '' || format === 8) {
      format = 'YYYYMMDD'
    } else if (format === 6) {
      format = 'YYYYMM'
    } else if (format === 4) {
      format = 'YYYY'
    } else {
      format = 'YYYYMMDD'
    }

    return parseInt(moment().add(increment, unit).format(format))
  },

  // PRIVATE METHODS
  function __normalizeDate(date, format) {
    if (date === null || typeof format === 'undefined') return date
    return parseInt(date.toString().substring(0, format))
  },

  function __getCurrentValue(tks) {
    let { data } = store.getState()
    tks.push('_rep')
    let _value = get(data, [tks[0], tks[1], `${tks[2]}`])
    if (typeof _value === 'undefined') {
      tks.pop()
      throw new Error(`__getCurrentValue(${tks.join('.')}): wrong argument`)
    }
    return _value
  },

  function __onChange(tks, _fn) {
    /*
     * BAILOUT ON PAGE TRANSITION
     * This is a workaround to ensure retrocompatibility after refactoring on expression eval logic
     * NOTE: this helper in deprecated in favor of /WATCH scripts
     */
    if (store.getState().internalState.pageSubmitStatus !== 'COMPLETED') return true
    let fn = get(window, _fn, () => true)
    fn.call()
    // force redux update
    store.dispatch(forceUpdate())
    return true
  },
]
