import * as React from 'react'
import HTMLText from '../../system-wide/html-text'
import { Table } from 'semantic-ui-react'

import { getSummaryExpression } from '../../../utils/summary-helpers'

const TableCell = Table.Cell

const SummaryCell = props => {
  const textStyle = { opacity: props.disabled ? '0.45' : '1' }
  let id = props.currentPageName + '.' + props.questionName + '.' + props.type

  return (
    <TableCell className={props.className} key={id}>
      <div style={textStyle}>
        <HTMLText
          id={id}
          style={{ textAlign: 'center', width: '100%', display: 'inline-block' }}
          value={getSummaryExpression(props.type, props.format, props.tokens)}
        />
      </div>
    </TableCell>
  )
}

export default SummaryCell
