function proxyPolyfill() {
  let lastRevokeFn = null
  let ProxyPolyfill

  function isObject(o) {
    return o ? typeof o === 'object' || typeof o === 'function' : false
  }

  ProxyPolyfill = function(target, handler, jsonref) {
    if (!isObject(target) || !isObject(handler)) {
      throw new TypeError('Cannot create proxy with a non-object as target or handler')
    }

    let throwRevoked = function() {}
    lastRevokeFn = function() {
      throwRevoked = function(trap) {
        throw new TypeError('Cannot perform  on a proxy that has been revoked')
      }
    }

    const unsafeHandler = handler
    handler = { get: null, set: null, apply: null, construct: null }
    for (let k in unsafeHandler) {
      if (!(k in handler) && k !== 'jsonref') {
        throw new TypeError('Proxy polyfill does not support trap ')
      }
      handler[k] = unsafeHandler[k]
    }
    if (typeof unsafeHandler === 'function') {
      handler.apply = unsafeHandler.apply.bind(unsafeHandler)
    }

    let _jsonref = jsonref
    let proxy = this
    let isMethod = false
    let isArray = false

    if (typeof target === 'function') {
      proxy = function ProxyPolyfill() {
        const usingNew = this && this.constructor === proxy
        const args = Array.prototype.slice.call(arguments)
        throwRevoked(usingNew ? 'construct' : 'apply')

        if (usingNew && handler['construct']) {
          return handler['construct'].call(this, target, args)
        } else if (!usingNew && handler.apply) {
          return handler.apply(target, this, args)
        }

        if (usingNew) {
          args.unshift(target)
          const f = target.bind.apply(target, args)
          return new f()
        }
        return target.apply(this, args)
      }
      isMethod = true
    } else if (target instanceof Array) {
      proxy = []
      isArray = true
    }

    proxy.getJsonref = function() {
      return _jsonref
    }

    const getter = handler.get
      ? function(prop) {
          throwRevoked('get')
          return handler.get(this, prop, proxy)
        }
      : function(prop) {
          throwRevoked('get')
          return this[prop]
        }
    const setter = handler.set
      ? function(prop, value) {
          throwRevoked('set')
          handler.set(this, prop, value, proxy)
        }
      : function(prop, value) {
          throwRevoked('set')
          this[prop] = value
        }

    const propertyNames = Object.getOwnPropertyNames(target)
    const propertyMap = {}
    propertyNames.forEach(function(prop) {
      if ((isMethod || isArray) && prop in proxy) {
        return // ignore properties already here, e.g. 'bind', 'prototype' etc
      }
      const real = Object.getOwnPropertyDescriptor(target, prop)
      const desc = {
        enumerable: !!real.enumerable,
        get: getter.bind(target, prop),
        set: setter.bind(target, prop),
      }
      Object.defineProperty(proxy, prop, desc)
      propertyMap[prop] = true
    })

    let prototypeOk = true
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(proxy, Object.getPrototypeOf(target))
    } else if (proxy.__proto__) {
      proxy.__proto__ = target.__proto__
    } else {
      prototypeOk = false
    }
    if (handler.get || !prototypeOk) {
      for (let k in target) {
        if (propertyMap[k]) {
          continue
        }
        Object.defineProperty(proxy, k, { get: getter.bind(target, k) })
      }
    }

    Object.seal(target)
    Object.seal(proxy)

    return proxy // nb. if isMethod is true, proxy != this
  }

  ProxyPolyfill.revocable = function(target, handler) {
    const p = new ProxyPolyfill(target, handler)
    return { proxy: p, revoke: lastRevokeFn }
  }

  return ProxyPolyfill
}

const getProxyImpl = (data, checkValueFn) => {
  let proxies = {}
  let handler = {
    get: (obj, prop) => {
      if (typeof obj.target[prop] === 'object' && obj.target[prop] !== null) {
        let jsonref = [...obj.jsonref, prop]
        let jsonrefStr = jsonref.join('.')

        if (jsonrefStr in proxies) {
          return proxies[jsonrefStr]
        } else {
          let p = new Proxy({ jsonref, target: obj.target[prop] }, handler)
          proxies[jsonrefStr] = p
          return p
        }
      } else {
        return obj.target[prop]
      }
    },
    set: (obj, prop, value) => {
      if (obj.jsonref[0] === 'PRESEED') {
        throw new Error(`${obj.jsonref.join('.')}: invalid assignment`)
      }
      let { valid, label, _value, labelKey } = checkValueFn(obj.jsonref[0], obj.jsonref[1], prop, value)
      if (!valid) {
        throw new Error(`${obj.jsonref.join('.')}: invalid assignment`)
      }
      obj.target[prop] = _value
      if (labelKey !== '') {
        obj.target[labelKey] = label
      }

      return true
    },
  }

  return new Proxy({ jsonref: [], target: data }, handler)
}

const getProxyPolyfill = (data, checkValueFn) => {
  let _Proxy = proxyPolyfill()
  let proxies = {}
  let handler = {
    get: (obj, prop, proxy) => {
      if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        let jsonref = [...proxy.getJsonref(), prop]
        let jsonrefStr = jsonref.join('.')

        if (jsonrefStr in proxies) {
          return proxies[jsonrefStr]
        } else {
          let p = new _Proxy(obj[prop], handler, jsonref)
          proxies[jsonrefStr] = p
          return p
        }
      } else {
        return obj[prop]
      }
    },
    set: (obj, prop, value, proxy) => {
      let jsonref = proxy.getJsonref()
      if (jsonref[0] === 'PRESEED') {
        throw new Error(`${jsonref.join('.')}: invalid assignment`)
      }
      let { valid, label, _value, labelKey } = checkValueFn(jsonref[0], jsonref[1], prop, value)
      if (!valid) {
        throw new Error(`${jsonref.join('.')}: invalid assignment`)
      }
      obj[prop] = _value
      if (labelKey !== '') {
        obj[labelKey] = label
      }

      return true
    },
  }

  return new _Proxy(data, handler, [])
}

const getProxy = (data, checkValueFn) => {
  return typeof window.Proxy === 'undefined' ? getProxyPolyfill(data, checkValueFn) : getProxyImpl(data, checkValueFn)
}

export default getProxy
