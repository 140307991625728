import * as React from 'react'
import { Message } from 'semantic-ui-react'

import Interpolator from '../../utils/interpolator'
import HTMLText from '../system-wide/html-text'
import { loadUrl } from '../../store/survey-data'
import { store } from '../../store'

class EmptyQuestionBody extends React.PureComponent {
  static defaultProps = {
    options: {},
  }

  state = {
    loadurl: '',
    redirect: '',
  }

  constructor(props) {
    super(props)
    let interpolator = new Interpolator()
    if (typeof props.options['loadurl'] !== 'undefined') {
      this.state.loadurl = interpolator.renderUrl(props.options.loadurl)
    }
    if (typeof this.props.options['redirect'] !== 'undefined') {
      this.state.redirect = interpolator.renderUrl(props.options.redirect)
    }
  }

  async componentDidMount() {
    if (this.state.loadurl && store.getState().internalState.mode === 'production') {
      await loadUrl(this.state.loadurl)
    }
  }

  render() {
    if (store.getState().internalState.mode !== 'production') {
      return (
        <React.Fragment>
          {this.state.loadurl && (
            <Message
              success
              header="Load remote URL"
              content={<HTMLText value={this.state.loadurl} />}
              style={{ width: '100%' }}
            />
          )}
          {this.state.redirect && (
            <Message
              success
              header="Redirect to remote URL"
              content={<HTMLText value={this.state.redirect} />}
              style={{ width: '100%' }}
            />
          )}
        </React.Fragment>
      )
    } else {
      return null
    }
  }
}

export default EmptyQuestionBody
