import * as React from 'react'
import hash from 'object-hash/index'
import Themes from './components'

import './fonts.css'

const appStyle = {
  opacity: '0',
  overflow: 'hidden',
  height: '100%',
  maxHeight: '0',
  width: '100%',
}

export class ResurveyTheme extends React.Component {
  static defaultProps = {
    staticLoader: 'main-loader',
    cssUrl: '/api/less-css/index.css',
  }

  constructor(props) {
    super(props)
    let activeTheme = props.cssUrl && props.themeConfigs ? 'custom' : props.theme.toLowerCase()
    if (Object.keys(Themes).indexOf(activeTheme) === -1) activeTheme = 'default'
    let appContainerClassName =
      activeTheme !== 'custom' ? activeTheme : 'custom-fallback custom-' + hash(props.themeConfigs)

    this.state = {
      activeTheme,
      appContainerClassName,
    }
  }

  cssLoadedSpy = null
  meta = null
  css = null
  timer = null

  showChidren = (activeTheme, primaryColor, headerColor) => {
    const cssText = `
      #app-container-${activeTheme} {
        max-height: 100%!important;
        opacity: 1!important;
        transition: opacity .35s ease-in-out;
      }
      .${this.props.staticLoader} {
        display: none;
      }
    `
    const docFrag = document.createDocumentFragment()
    let css = document.createElement('style')
    css.type = 'text/css'
    css.appendChild(document.createTextNode(cssText))
    docFrag.appendChild(css)
    let meta = document.createElement('meta')
    meta.setAttribute('name', 'theme-color')
    meta.setAttribute('content', primaryColor)
    docFrag.appendChild(meta)
    if (document.head) document.head.appendChild(docFrag)
    this.meta = meta
    this.css = css
  }

  cssLoadedHandler = () => {
    window.clearTimeout(this.timer)
    'onanimationend animationend webkitAnimationEnd oAnimationEnd msAnimationEnd'.split(' ').forEach(evt => {
      this.cssLoadedSpy.removeEventListener(evt, this.cssLoadedHandler)
    })
    let { color, backgroundColor } = this.cssLoadedSpy
      ? window.getComputedStyle(this.cssLoadedSpy)
      : { color: '', backgroundColor: '' }
    this.showChidren(this.state.activeTheme, color, backgroundColor)
  }

  componentDidMount() {
    if (this.cssLoadedSpy) this.cssLoadedSpy.classList.add('rs-css-loaded')
    'onanimationend animationend webkitAnimationEnd oAnimationEnd msAnimationEnd'.split(' ').forEach(evt => {
      //$FlowFixMe
      this.cssLoadedSpy.addEventListener(evt, this.cssLoadedHandler)
    })
    this.timer = window.setTimeout(this.cssLoadedHandler, 5000) //FALLBACK!
  }

  componentWillUnmount() {
    if (this.cssLoadedSpy) {
      this.cssLoadedSpy.classList.remove('rs-css-loaded')
    }
    if (this.css && document.head) document.head.removeChild(this.css)
    if (this.meta && document.head) document.head.removeChild(this.meta)
    window.clearTimeout(this.timer)
  }

  render() {
    let Theme = Themes[this.state.activeTheme]
    return (
      <React.Fragment>
        <div
          id={`app-container-${this.state.activeTheme}`}
          className={this.state.appContainerClassName}
          style={appStyle}>
          <div ref={e => (this.cssLoadedSpy = e)} />
          {this.props.children}
        </div>
        <Theme themeConfigs={this.props.themeConfigs} cssUrl={this.props.cssUrl} />
      </React.Fragment>
    )
  }
}
