import { SET_TOGGLEABLE_STATE, SET_MATRIX_TOGGLEABLE_STATE } from './survey-toggleable'

// Action types
export const SET_RESPONDENT_TRACKER_STATE = 'survey/SET_RESPONDENT_TRACKER_STATE'

// Initial state
export const initialState = {}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESPONDENT_TRACKER_STATE:
      return { ...state, ...action.payload }

    case SET_TOGGLEABLE_STATE:
      if (action.payload.replyName !== '') {
        return state
      } else {
        return { ...state, [`${action.payload.pageName}$${action.payload.qName}`]: action.payload.active }
      }
    case SET_MATRIX_TOGGLEABLE_STATE:
      let { pageName, status } = action.payload
      let respondentTrackerStatus = Object.keys(status).reduce(
        (acc, q) => ({ ...acc, [`${pageName}$${q}`]: status[q].questionStatus }),
        {}
      )
      return { ...state, ...respondentTrackerStatus }

    default:
      return state
  }
}

// Action creators
export const setRespondentTrackerState = pageStatuses => ({
  type: SET_RESPONDENT_TRACKER_STATE,
  payload: pageStatuses,
})

export default reducer
