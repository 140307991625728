import * as React from 'react'

import withToggle from '../../../enhancers/toggleable'

class NullElement extends React.PureComponent {
  render() {
    return null
  }
}

const MMatrixRow = withToggle(NullElement, 'MatrixRow')
const MMatrixColumn = withToggle(NullElement, 'MatrixColumn')

class MetaMMatrix extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  getMetaElements = () => {
    let { disabled: parentDisabled, currentPageName } = this.props
    let childQuestions = this.props.childQuestions || []
    let rows = childQuestions.map(q => (
      <MMatrixRow
        key={q.name}
        group={q.group}
        parentDisabled={parentDisabled}
        questionName={q.name}
        currentPageName={currentPageName}
        jsonref={q.jsonref}
        values={q.items}
      />
    ))
    let q = childQuestions[0]
    let columns = q.items.map(v => (
      <MMatrixColumn
        key={`${currentPageName}.${q.group}_col_${v.key}`}
        group={q.group}
        parentDisabled={parentDisabled}
        questionName={q.name}
        currentPageName={currentPageName}
        jsonref={`${currentPageName}.${q.group}_col_${v.key}`}
      />
    ))

    return [...rows, ...columns]
  }

  render() {
    return <React.Fragment>{this.getMetaElements()}</React.Fragment>
  }
}

export default MetaMMatrix
