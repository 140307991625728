import * as React from 'react'

import { ToggleableWrapper, ConnectedToggleableWrapper } from './toggleable_wrapper'
import { store } from '../../store'

const hasExpressions = props => {
  let { jsonref, currentPageName } = props
  let page = store.getState()['internalState']['expressionValues'][currentPageName] || {}
  return (
    typeof page[`${jsonref}.disableif`] !== 'undefined' ||
    typeof page[`${jsonref}.enableif`] !== 'undefined' ||
    typeof page[`${jsonref}.displayif`] !== 'undefined' ||
    typeof page[`${jsonref}.hideif`] !== 'undefined'
  )
}

const withToggle = (Component, childType = '') => {
  return class WithToggleWrapper extends React.Component {
    WrappedComponent
    hasExpressions

    constructor(props) {
      super(props)
      this.hasExpressions = hasExpressions(props)
    }

    render() {
      let WithToggleWrapper = this.hasExpressions ? ConnectedToggleableWrapper : ToggleableWrapper
      return (
        <WithToggleWrapper
          childProps={this.props}
          hasExpressions={this.hasExpressions}
          childType={childType}
          childComponent={Component}
        />
      )
    }
  }
}

export const CommentItemToggleableHook = withToggle(props => null, 'CommentItem')
export default withToggle
