import { helpers, helperNames } from './rscode-helpers'
import { loadUrl, request, checkQuota, search, goBack, sendMail, getData, forceUpdate } from './script-helpers'
import parse from './jsparser'

export const scriptBuilder = (program, page, transpileFn, __checkQuotaHandler) => {
  let scriptFn = () => {}

  try {
    let code = `async (data, toggleables, ${helperNames.join(
      ', '
    )}, loadUrl, request, checkQuota, search, goBack, sendMail, getData, forceUpdate, __checkQuotaHandler) => {
      Array.prototype.shuffle = function () {
        let tmp, current, top = this.length
        if (top) {
          while (--top) {
            current = Math.floor(Math.random() * (top + 1))
            tmp = this[current]
            this[current] = this[top]
            this[top] = tmp
          }
        }
        return this
      }

      ${program}
    }`

    let { code: fixedRefsCode } = parse(code)
    scriptFn = eval(transpileFn(fixedRefsCode)) //eslint-disable-line
  } catch (e) {
    throw new Error(`Errore script ${page}: ${typeof e.message !== 'undefined' ? e.message : e}`)
  }

  return {
    fn: scriptFn,
    scriptHelpers: [
      ...helpers,
      loadUrl,
      request,
      checkQuota,
      search,
      goBack,
      sendMail,
      getData,
      forceUpdate,
      __checkQuotaHandler,
    ],
  }
}
