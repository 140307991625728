import * as React from 'react'
import { Grid } from 'semantic-ui-react'
import get from 'lodash.get'

import Constants from '../../utils/constants'
import withSkippable from '../../enhancers/skippable'
import { CommentItemToggleableHook } from '../../enhancers/toggleable'
import { RatingField } from '../formfields'

class RatingQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  render() {
    const renderAs = get(this.props, ['options', 'render_as'], 'rating')
    const prefix = get(this.props, ['options', 'prefix'], '')
    const suffix = get(this.props, ['options', 'suffix'], '')

    let ratings = this.props.items.map(r => {
      return (
        <Grid.Row key={r.key} className="resurvey question body">
          <RatingField
            name={r.key}
            key={`item-${r.jsonref}`}
            questionName={this.props.name}
            questionWidth={this.props.options.width || Constants.FULLWIDTH}
            label={r.value}
            parentDisabled={this.props.disabled}
            hasComment={r.has_comment}
            jsonref={r.jsonref}
            maxRating={this.props.options.maxrating || Constants.DEFAULTS.MAXRATING}
            includeZero={this.props.options.include_zero}
            renderAs={renderAs}
            prefix={prefix}
            suffix={suffix}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped}
          />
          {r.has_comment && (
            <CommentItemToggleableHook
              currentPageName={this.props.currentPageName}
              questionName={this.props.name}
              name={r.key + '_comment'}
              jsonref={r.jsonref + '_comment'}
            />
          )}
        </Grid.Row>
      )
    })

    if (this.props.reverseItems) ratings.reverse()
    return ratings
  }
}

export default withSkippable(RatingQuestionBody)
