import axios from 'axios'
import get from 'lodash.get'
import isEmail from 'validator/lib/isEmail'

import { store } from '../store'
import Interpolator from './interpolator'
import { setGotoPage, getPageIndexByName } from '../store/survey-internal-state'
import { forceUpdate as _forceUpdate } from '../store/survey-data'

/**
 * NOTE: these scripts are supported only in script
 */

export const loadUrl = url => {
  let interpolator = new Interpolator()
  let _url = interpolator.render(url)
  if (store.getState().internalState.mode === 'production') {
    return axios
      .post(`/api/loadurl`, { url: _url })
      .then(response => true)
      .catch(err => {
        console.log(err)
        return false
      })
  } else {
    console.log(`Load remote URL: ${url}`)
    return Promise.resolve(true)
  }
}

//Execute a generic http request proxied by backend
export const request = async options => {
  let interpolator = new Interpolator()
  options.url = interpolator.render(options.url)

  if (typeof options.params !== 'undefined') {
    for (let p in options.params) {
      options.params[p] = interpolator.render(options.params[p])
    }
  }
  if (typeof options.data === 'object') {
    for (let d in options.data) {
      options.data[d] = interpolator.render(options.data[d])
    }
  }
  if (typeof options.data === 'string') {
    options.data = interpolator.render(options.data)
  }

  try {
    return await axios.post(`/api/axiosproxy`, options)
  } catch (err) {
    console.log(err)
    throw err.response
  }
}

// Check the status of a single quota by name
export const checkQuota = async (quota_name, checkQuotaHandler) => {
  if (!quota_name) {
    throw new Error(`checkQuota(${quota_name}): missing quota name`)
  }
  let interpolator = new Interpolator()
  quota_name = interpolator.render(quota_name)

  let { mode } = store.getState().internalState
  if (mode === 'production') {
    return await checkQuotaHandler(quota_name)
  } else {
    return { name: '', target: 0, value: 0, active: true, closed: false }
  }
}

/**
 * Get arbitrary interview data from the specified survey
 *
 * getData(survey_name, match, columns)
 *    @param survey_name string
 *    @param match Object
 *    @params columns Array
 *
 *    @return data Array
 *
 *    es.: getData("survey_name", {P1.Q1._rep: 1, exit_type: "COMPLETED"}, ["Reg", P1.Q2._rep])
 *    => [
 *        {id_interview: '', ...columns},
 *        {id_interview: '', ...columns},
 *        {id_interview: '', ...columns},
 *       ]
 */
export const getData = async (survey, match, columns) => {
  if (!survey) {
    throw new Error(`getData(survey, match, columns): missing survey_name`)
  }
  if (!match || typeof match !== 'object' || !Object.keys(match).length) {
    throw new Error(`getData(survey, match, columns): wrong match parameter`)
  }
  if (!columns || !Array.isArray(columns) || !columns.length) {
    throw new Error(`getData(survey, match, columns): wrong columns parameter`)
  }

  let pathTks = window.location.pathname.split('/')
  let surveyUid = pathTks[pathTks.length - 1].split('-')[0]

  let interpolator = new Interpolator()
  for (let m in match) {
    if (Interpolator.needInterpolation(match[m])) {
      match[m] = interpolator.render(match[m])
    }
  }

  let { mode } = store.getState().internalState
  if (mode === 'production') {
    let response = await axios({
      url: `/api/get_survey_data`,
      method: 'POST',
      data: { surveyUid, survey, match, columns },
      validateStatus: null,
    })
    if (response.status === 200) {
      return response.data.data
    } else {
      console.log(response)
      throw new Error(response.data)
    }
  } else {
    return []
  }
}

//Search record into CSV/XLSX uploaded on filemanager
export const search = async (filename, search_params = {}, case_sensitive = false, exact_match = false) => {
  filename = filename.replace(/^\/media\//, '')

  let interpolator = new Interpolator()
  for (let p in search_params) {
    search_params[p] = interpolator.render(search_params[p])
    console.log(search_params[p])
  }

  try {
    let response = await axios({
      method: 'post',
      url: `/resources/media/search/${filename}`,
      data: { search_params, case_sensitive, exact_match },
      validateStatus: null,
    })
    return response.data
  } catch (err) {
    console.log(err)
    throw err.response
  }
}

export const goBack = tks => {
  let { internalState, data } = store.getState()
  let page = ''
  if (tks.length === 1) {
    page = tks[0]
  } else {
    tks.push('_rep')
    page = get(data, tks, '')
  }

  let currentIndex = internalState.currentPageIdx
  let gotoPageIdx = getPageIndexByName(store, page)
  if (gotoPageIdx === -1) {
    throw new Error(`goBack(${page}): page not found`)
  }
  let direction = gotoPageIdx <= currentIndex ? 'back' : 'next'
  if (direction !== 'back') {
    throw new Error(`goBack(${page}): only backward jumps are allowed`)
  }

  store.dispatch(setGotoPage(page))
}

export const sendMail = async (to, templateName) => {
  if (!to || !templateName) {
    throw new Error('sendMail(to, templateName): missing required arguments')
  }

  let interpolator = new Interpolator()
  to = interpolator.render(to)
  if (to && !isEmail(to)) {
    throw new Error('sendMail(to, templateName): email not valid')
  }

  let { data } = store.getState()
  let pathTks = window.location.pathname.split('/')
  let surveyUid = pathTks[pathTks.length - 1].split('-')[0]
  let idInterview = data.PRESEED.__uid

  if (['preview', 'retrace'].indexOf(surveyUid) !== -1) {
    console.log(`Send email to ${to} with template ${templateName}`)
    return Promise.resolve(1)
  } else {
    let response = await axios({
      url: `/api/sendmail`,
      method: 'POST',
      data: { surveyUid, idInterview, to, templateName, dataSlice: data },
      validateStatus: null,
    })
    if (response.status === 200) {
      return response.data.status
    } else {
      throw new Error(response.data.status)
    }
  }
}

//Force redux rerender on slice "data"
export const forceUpdate = () => {
  store.dispatch(_forceUpdate())
}
