import * as React from 'react'
import { connect } from 'react-redux'

export const connectField = Component => {
  return class connectFieldHOC extends React.PureComponent {
    constructor(props) {
      super(props)
      let { currentPageName, questionName, jsonref, name, isMSelect } = props
      let replyName = typeof props['data-name'] !== 'undefined' ? props['data-name'] : name

      let mstp = state => {
        let data = state.data[currentPageName][questionName]

        let hasError = false
        let questionStatus = state['validators']['questionStatuses'][currentPageName][questionName]

        if (questionStatus === 'INVALID') {
          let errors = state['validators']['errorList'][currentPageName][questionName]
          let tKey = jsonref.split('.').slice(2).join('.') // remove the first 2 tokens from jsonref
          let replyEnabled = !!state.toggleables[currentPageName][questionName]['repliesStatus'][tKey]
          hasError = isMSelect ? errors.indexOf(jsonref) !== -1 : replyEnabled && errors.indexOf(jsonref) !== -1
        }

        return {
          replyValue: data[replyName],
          hasError,
          emptyComment: data[replyName + '_comment'] === '',
        }
      }

      this.Component = connect(mstp)(Component)
    }

    render() {
      return <this.Component {...this.props} />
    }
  }
}

export default connectField
