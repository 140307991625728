import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import { NumberField as _NumberField } from '../../formfields/number-field'
import HTMLText from '../../system-wide/html-text'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'
import MatrixCell from '../matrix/cell'
import Summary from './summary'

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.jsonref.split('.')[1]]['_rowcomment'] }
}
const MatrixCellWithComment = connect(mstp)(MatrixCell)
const NumberField = connectField(_NumberField)
const TableRow = Table.Row
const TableCell = Table.Cell

const cla = { textAlign: 'left' }

class _MatrixVerticalRow extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    let columns = []
    columns.push(
      <TableCell key={'vrow-0'} style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.qtext} />
        </span>
      </TableCell>
    )
    this.props.cells.forEach(cellObject => {
      let cell = cellObject.cell
      let qName = cell.jsonref.split('.')[1]

      const itemKey = qName.split('_row_')[1]
      const valueKey = cell.key
      const cellKey = `cell-${itemKey}-${valueKey}`

      const cbProps = {
        itemKey: cell.key,
        name: cell.key,
        jsonref: cell.jsonref,
        disabled: this.props.disabled,
        questionName: qName,
        currentPageName: this.props.currentPageName,
        className: `matrix-cell ${cellKey}`,
        labelClassName: 'hidden-label',
        format: this.props.format,
        prefix: this.props.prefix,
        suffix: this.props.suffix,
      }
      let childQuestionJsonrefArr = cell.jsonref.split('.')
      childQuestionJsonrefArr.pop()
      let childQuestionJsonref = childQuestionJsonrefArr.join('.')
      let Cell = cellObject.hasComment ? MatrixCellWithComment : MatrixCell
      columns.push(
        <Cell
          key={`item_${cell.jsonref}`}
          jsonref={childQuestionJsonref}
          parentDisabled={this.props.disabled}
          questionName={qName}
          currentPageName={this.props.currentPageName}
          component={NumberField}
          childProps={cbProps}
        />
      )
    })

    let summaryTokens = this.props.cells.reduce((acc, cellObject) => {
      return [...acc, `${cellObject.cell.jsonref}`]
    }, [])

    let summaries = []
    if (typeof this.props.options.colsum !== 'undefined') summaries.push('colsum')
    if (typeof this.props.options.colprod !== 'undefined') summaries.push('colprod')
    if (typeof this.props.options.colavg !== 'undefined') summaries.push('colavg')

    summaries.forEach(type => {
      columns.push(
        <Summary
          key={this.props.currentPageName + '.' + this.props.questionName + '.' + type}
          id={this.props.currentPageName + '.' + this.props.questionName + '.' + type}
          disabled={this.props.disabled}
          questionName={this.props.jsonref.split('.')[1]}
          currentPageName={this.props.currentPageName}
          label={this.props.options[type] || ''}
          format={this.props.options.format || '00000'}
          tokens={summaryTokens || []}
          labelWidth={10}
          inputWidth={10}
          type={type}
        />
      )
    })
    return <TableRow style={withToggleStyle}>{columns}</TableRow>
  }
}
_MatrixVerticalRow.displayName = '_MatrixVerticalRow'

export default withToggle(_MatrixVerticalRow)
