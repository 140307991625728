import * as React from 'react'
import { connect } from 'react-redux'
import { Search, Grid } from 'semantic-ui-react'
import HTMLText from '../system-wide/html-text'
import debounce from 'lodash.debounce'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import strings from '../../locales'

const textFieldStyle = { marginTop: '0.3rem' }
const initialState = { isLoading: false, results: [] }
const onlyItems = k => !/(__qtype|_comment|skipped|label)$/.test(k)
const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

const mapStateToProps = (state, ownProps) => {
  let data = state.data[ownProps.currentPageName][ownProps.questionName]
  let replyValue = data[`${ownProps.name}_label`]

  // already selected items
  let selectedValues = Object.keys(data)
    .filter(onlyItems)
    .filter(k => k !== ownProps.name)
    .map(i => {
      return data[`${i}_label`]
    })

  return {
    data,
    selectedValues,
    replyValue,
  }
}

class _SearchField extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.interpolationIds.toString() !== prevProps.interpolationIds.toString() &&
      this.props.interpolationIds.indexOf(this.props.data[this.props.name]) !== -1
    ) {
      this.props.dispatch(
        handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, null, '')
      )
    }
  }

  resetData = e => {
    if (typeof e === 'undefined' || e.which === 13) {
      if (
        this.props.data[this.props.name] === this.props.notFound &&
        (this.props.selectedValues.indexOf(this.props.replyValue) !== -1 ||
          this.props.interpolationValues.findIndex(el => el.title === this.props.replyValue) !== -1)
      ) {
        this.props.dispatch(
          handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, null, '')
        )
      }
    }
  }
  handleOnBlur = () => {
    this.resetData()
  }

  handleChange = (value, isLoading) => {
    this.setState({ isLoading })
    let item = this.props.values.find(val => val.title === value)

    let selected = {
      id: value !== '' ? this.props.notFound : null,
      code: value !== '' ? this.props.notFound : null,
      title: value,
    }

    if (
      typeof item !== 'undefined' &&
      this.props.selectedValues.indexOf(value) === -1 &&
      this.props.interpolationValues.findIndex(el => el.title === value) === -1
    ) {
      selected = item
    }

    try {
      this.props.dispatch(
        handleDataChange(
          this.props.currentPageName,
          this.props.questionName,
          this.props.name,
          selected.code,
          selected.title
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  handleResultSelect = (e, { result }) => {
    this.handleChange(result.title, false)
  }

  handleSearchChange = (e, { value }) => {
    this.handleChange(value, true)

    setTimeout(() => {
      if (this.props.replyValue.length < 1) return this.setState(initialState)

      const re = new RegExp(escapeRegExp(this.props.replyValue), 'i')
      const isMatch = result => re.test(result.title)

      this.setState({
        isLoading: false,
        results: this.props.values.filter(isMatch).filter(item => {
          return (
            this.props.selectedValues.indexOf(item.title) === -1 &&
            this.props.interpolationValues.findIndex(el => el.title === item.title) === -1
          )
        }),
      })
    }, 300)
  }

  render() {
    const { isLoading, results } = this.state
    let { labelWidth, inputWidth } = Constants.getDefaultFieldWidths(this.props.questionWidth)
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    let textStyle = { opacity: this.props.disabled ? '0.45' : '1' }

    return (
      <React.Fragment>
        {this.props.label && (
          <Grid.Column
            className="multiple"
            {...Constants.getWidths(labelWidth)}
            style={{ ...textFieldStyle, ...withToggleStyle }}>
            <span style={textStyle}>
              <HTMLText value={this.props.label} />
            </span>
          </Grid.Column>
        )}
        <Grid.Column className="multiple" {...Constants.getWidths(inputWidth)} style={withToggleStyle}>
          <Search
            className={`searchfield ${this.props.hasError ? 'error' : ''} ${this.props.disabled ? 'disabled' : ''}`}
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={debounce(this.handleSearchChange, 200, {
              leading: true,
            })}
            noResultsMessage={`${strings.search_no_results_msg}`}
            onBlur={this.handleOnBlur}
            results={results}
            value={this.props.replyValue}
            onKeyPress={this.resetData}
            disabled={this.props.disabled || this.props.emptyComment === true}
          />
        </Grid.Column>
      </React.Fragment>
    )
  }
}
_SearchField.displayName = '_SearchField'

export const SearchField = connect(mapStateToProps)(_SearchField)
