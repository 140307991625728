import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import { RadioField as _RadioField } from '../../formfields/radio-field.js'

import HTMLText from '../../system-wide/html-text'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'
import MatrixCell from '../matrix/cell'

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.jsonref.split('.')[1]]['_rowcomment'] }
}

const MatrixCellWithComment = connect(mstp)(MatrixCell)
const RadioField = connectField(_RadioField)

const TableRow = Table.Row
const TableCell = Table.Cell

const cla = { textAlign: 'left' }

class _MatrixVerticalRow extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    let columns = []
    columns.push(
      <TableCell key={'vrow-0'} style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.qtext} />
        </span>
      </TableCell>
    )
    this.props.cells.forEach(cellObject => {
      let cell = cellObject.cell
      let questionName = cell.jsonref.split('.')[1]
      let radioJsonref = `${this.props.currentPageName}.${questionName}._rep.:${cell.key}`
      let cellJsonref = `${this.props.currentPageName}.${questionName}`

      const itemKey = questionName.split('_row_')[1]
      const valueKey = cell.key
      const cellKey = `cell-${itemKey}-${valueKey}`

      const radioProps = {
        itemKey: cell.key,
        label: cell.value,
        name: `${questionName}-_rep`,
        'data-name': '_rep',
        jsonref: radioJsonref,
        disabled: this.props.disabled || this.props.rowComment === '',
        questionName,
        currentPageName: this.props.currentPageName,
        className: `matrix-cell ${cellKey}`,
        labelClassName: 'hidden-label',
        hasComment: cell.type === 'comment' || cell.type === 'optionalcomment',
      }
      let Cell = cellObject.hasComment ? MatrixCellWithComment : MatrixCell
      columns.push(
        <Cell
          key={`item_${cell.jsonref}`}
          jsonref={cellJsonref}
          parentDisabled={this.props.disabled}
          questionName={questionName}
          currentPageName={this.props.currentPageName}
          component={RadioField}
          childProps={radioProps}
        />
      )
    })

    return <TableRow style={withToggleStyle}>{columns}</TableRow>
  }
}
_MatrixVerticalRow.displayName = '_MatrixVerticalRow'

export default withToggle(_MatrixVerticalRow)
