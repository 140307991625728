import * as React from 'react'
import { connect } from 'react-redux'
import get from 'lodash.get'
import axios from 'axios'
import { Dropdown } from 'semantic-ui-react'

import Resurvey from 'resurvey-player'
import { sendData, retrieveSurvey, clearSessionStorage } from './store/backend-integration'
import strings from './locales'

import './index.css'

const mapStateToProps = state => {
  return {
    status: state.backend.status,
    errorMsg: state.backend.errorMsg,
    data: state.backend.data,
    definition: state.backend.definition,
    outofquota: state.backend.outofquota,
    snapshot: state.backend.snapshot,
    mode: state.backend.mode,
    survey: state.backend.survey,
    preseed: state.backend.preseed,
    isTestLink: state.backend.isTestLink,
    isFixLink: state.backend.isFixLink,
  }
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.rsRef = React.createRef()
  }

  onPageSubmit = async (data, snapshot) => {
    return await this.props.dispatch(sendData(snapshot))
  }

  onSurveyCompleted = async () => {
    if (this.isSubSurvey === true) window.parent.postMessage('CLOSE_SUB_SURVEY', '*')
    clearSessionStorage(this.isSubSurvey)
  }

  onSurveyStopped = async (data, snapshot) => {
    await this.props.dispatch(sendData(snapshot))
    if (this.isSubSurvey === true) window.parent.postMessage('CLOSE_SUB_SURVEY', '*')
    clearSessionStorage(this.isSubSurvey)
  }

  onCheckQuota = async quota_name => {
    let response = await axios.post(`/api/check_quotas/${this.props.survey}`, { quotaNames: [quota_name] })
    let data = response.data.data
    if (!data.length) {
      throw new Error(`checkQuotas(${quota_name}): quota not found`)
    }
    let target = parseInt(data[0].target)
    let value = parseInt(data[0].value)
    return {
      name: data[0].name,
      active: data[0].active,
      target,
      value,
      closed: value >= target,
    }
  }

  sendChangePage = (e, { value }) => {
    this.rsRef.current.forcePage(value)
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search)
    this.isSubSurvey = urlParams.get('isSubSurvey') === 'true'
    this.props.dispatch(retrieveSurvey(this.isSubSurvey))
  }

  render() {
    let { definition } = this.props
    // HIDE STOP BUTTON IF ISSUBSURVEY
    if (this.isSubSurvey === true) {
      let content = definition.content || []
      content.forEach(page => {
        page['page_options'] = { ...page['page_options'], show_stop_button: false }
      })
    }

    let options = get(this.props.definition, ['content'], [])
      .filter(p => p.type === 'page' && ['__outofquota_survey', '__end_survey', '__stop_survey'].indexOf(p.name) === -1)
      .filter(p => typeof p.page_options.execute === 'undefined')
      .map(p => (
        <Dropdown.Item key={p.name} value={p.name} onClick={this.sendChangePage}>
          {p.name}
        </Dropdown.Item>
      ))

    let renderProps = this.props.isFixLink
      ? { theme: 'admin-preview', forceTitles: true, hideStopButton: true }
      : { cssUrl: '/api/less-css/index.css' }

    return (
      <React.Fragment>
        {this.props.status === 'SUCCESS' && (
          <div className="service-container">
            {(this.props.mode === 'test' || this.props.isTestLink === true) && (
              <div className="test-mode theme ready">
                <div style={{ display: 'flex', alignItems: 'center', padding: '0.25rem' }}>
                  {strings.test_mode}
                  <Dropdown
                    className="icon"
                    icon="fast forward"
                    text="Vai a pagina.."
                    floating
                    labeled
                    button
                    scrolling
                    style={{
                      width: '11rem',
                      float: 'right',
                      marginLeft: 'auto',
                      fontSize: '0.8rem',
                    }}>
                    <Dropdown.Menu>{options}</Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
            <Resurvey
              ref={this.rsRef}
              definition={definition}
              outofquota={this.props.outofquota}
              data={this.props.data}
              snapshot={this.props.snapshot}
              onPageSubmit={this.onPageSubmit}
              onSurveyCompleted={this.onSurveyCompleted}
              onSurveyStopped={this.onSurveyStopped}
              onCheckQuota={this.onCheckQuota}
              preseed={this.props.preseed}
              {...renderProps}
            />
          </div>
        )}
        {this.props.status === 'ERROR' && (
          <React.Fragment>
            <div className="service-error-container">
              <b>{strings[this.props.errorMsg]}</b>
            </div>
            <style>
              {`.main-loader {
              display: none;
            }`}
            </style>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(App)
