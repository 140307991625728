import React from 'react'

import TextQuestionBody from './text'
import MemoQuestionBody from './memo'
import NumberQuestionBody from './number'
import RadiolistQuestionBody from './radiolist'
import CheckboxlistQuestionBody from './checkboxlist'
import SelectQuestionBody from './select'
import RatingQuestionBody from './rating'
import SearchQuestionBody from './search'
import DateQuestionBody from './date'
import TimeQuestionBody from './time'
import DragndropQuestionBody from './dragndrop'
import RankingQuestionBody from './ranking'
import SearchtableQuestionBody from './searchtable'
import VideoQuestionBody from './video'
import MatrixQuestionBody from './matrix'
import MMatrixQuestionBody from './mmatrix'
import MRatingQuestionBody from './mrating'
import MTextQuestionBody from './mtext'
import MNumberQuestionBody from './mnumber'
import MRankingQuestionBody from './mranking'
import MSelectQuestionBody from './mselect'
import SubsurveyQuestionBody from './subsurvey'
import GeolocationQuestionBody from './geolocation'
import EmptyQuestionBody from './empty-body'

const Mockup = () => <React.Fragment />

const bodies = {
  text: TextQuestionBody,
  memo: MemoQuestionBody,
  number: NumberQuestionBody,
  radiolist: RadiolistQuestionBody,
  checkboxlist: CheckboxlistQuestionBody,
  select: SelectQuestionBody,
  rating: RatingQuestionBody,
  search: SearchQuestionBody,
  date: DateQuestionBody,
  time: TimeQuestionBody,
  dragndrop: DragndropQuestionBody,
  ranking: RankingQuestionBody,
  matrix: MatrixQuestionBody,
  mmatrix: MMatrixQuestionBody,
  mrating: MRatingQuestionBody,
  mnumber: MNumberQuestionBody,
  mtext: MTextQuestionBody,
  mranking: MRankingQuestionBody,
  mselect: MSelectQuestionBody,
  end: EmptyQuestionBody,
  stop: EmptyQuestionBody,
  outofquota: EmptyQuestionBody,
  info: Mockup,
  compslider: Mockup,
  searchtable: SearchtableQuestionBody,
  subsurvey: SubsurveyQuestionBody,
  video: VideoQuestionBody,
  geolocation: GeolocationQuestionBody,
}

export const getQuestionBody = type => bodies[type]
export default bodies
