import * as React from 'react'
import { connect } from 'react-redux'

import { setRespondentTrackerState } from '../store/survey-respondent-tracker'
import { store } from '../store'

const generateMapStateToProps = () => {
  let { internalState } = store.getState()
  let { items, expressions } = internalState
  let pages = Object.keys(items).filter(q => ['__end_survey', '__stop_survey', '__outofquota_survey'].indexOf(q) === -1)

  let pageExpressions = pages.reduce((acc, qname) => {
    let pageExprs = Object.keys(expressions[qname])
      .map(expr => expr.split('.'))
      .filter(tks => tks.length === 2)
      .map(tks => tks.pop())
    return { ...acc, [qname]: pageExprs }
  }, {})

  let code = pages.reduce((acc, qname) => {
    let value = 'true'
    if (pageExpressions[qname].length > 0) {
      value = pageExpressions[qname]
        .map(token => {
          return token === 'displayif' || token === 'enableif'
            ? `state.internalState.expressionValues["${qname}"]["${qname}.${token}"] === true`
            : `state.internalState.expressionValues["${qname}"]["${qname}.${token}"] === false`
        })
        .join(' && ')
    }

    return [...acc, `"${qname}": ${value}`]
  }, [])
  code = `
    if (state.internalState.pages.length === 0) return {}
    return {
      ${code.join(', ')}
    }
  `

  // eval'd mapStateToProps function
  return new Function('state', code) // eslint-disable-line
}

class RespondentTracker extends React.Component {
  componentDidMount() {
    let { toggleables } = store.getState()
    let pages = Object.keys(toggleables)
    let questionStatuses = pages
      .filter(p => ['__end_survey', '__outofquota_survey', '__stop_survey'].indexOf(p) === -1)
      .reduce((acc, page) => {
        return {
          ...acc,
          ...Object.keys(toggleables[page]).reduce(
            (acc, q) => ({ ...acc, [`${page}$${q}`]: toggleables[page][q].questionStatus }),
            {}
          ),
        }
      }, {})
    let { dispatch, ...otherProps } = this.props
    this.props.dispatch(setRespondentTrackerState({ ...otherProps, ...questionStatuses }))
  }

  shouldComponentUpdate(nextProps) {
    let shouldUpdate = false
    for (let k in nextProps) {
      if (nextProps[k] !== this.props[k]) {
        shouldUpdate = true
        let { dispatch, ...otherProps } = nextProps
        this.props.dispatch(setRespondentTrackerState(otherProps))
        break
      }
    }
    return shouldUpdate
  }

  render() {
    return null
  }
}

const generateRespondentTracker = () => {
  return connect(generateMapStateToProps())(RespondentTracker)
}

export default generateRespondentTracker
