import * as React from 'react'
import { shallowEqualObjects } from 'shallow-equal'
import memoizeOne from 'memoize-one'
import { connect } from 'react-redux'
import get from 'lodash.get'

import {
  setToggleableState,
  setMatrixToggleableState,
  setMselectRlitemsToggleableState,
} from '../../store/survey-toggleable'
import { store } from '../../store'

export class ToggleableWrapper extends React.Component {
  qName
  rName
  unsubscribe
  visible
  disabled
  active

  constructor(props) {
    super(props)
    let tks = props.childProps.jsonref.split('.')
    this.qName = tks[1]
    if (tks.length === 4) {
      this.rName = this.props.childType !== 'ReplyItem' ? '' : `${tks[2]}.${tks[3]}`
    } else {
      this.rName = this.props.childType !== 'ReplyItem' ? '' : tks[2]
    }

    let { questionSkipped, parentDisabled, forceVisible } = props.childProps
    let { enableif, disableif, displayif, hideif } = props
    this.evaluateState(enableif, disableif, displayif, hideif, questionSkipped, parentDisabled, forceVisible)
  }

  evaluateState = memoizeOne(
    (
      enableif = true,
      disableif = false,
      displayif = true,
      hideif = false,
      questionSkipped,
      parentDisabled,
      forceVisible
    ) => {
      //DISABLED
      let disabled = false
      if (typeof parentDisabled !== 'undefined' && parentDisabled) {
        disabled = true
      } else {
        disabled = disableif || !enableif

        if (this.props.childType === 'ReplyItem') {
          disabled = !!questionSkipped || disabled
        }
      }

      //VISIBLE
      let visible = true
      if (typeof forceVisible !== 'undefined') {
        visible = forceVisible
      } else {
        visible = displayif && !hideif
      }

      this.visible = visible
      this.disabled = disabled
      this.active = visible && !disabled
    }
  )

  setToggleableState = () => {
    let { data, toggleables, internalState } = store.getState()
    let replies = data[this.props.childProps.currentPageName][this.qName]
    let togs = toggleables[this.props.childProps.currentPageName][this.qName]

    switch (this.props.childType) {
      case 'CommentItem': {
        let itemKey = this.props.childProps.name.replace('_comment', '')
        let prevActive = togs['repliesStatus'][itemKey]
        if (replies[this.props.childProps.name] === '' && prevActive) {
          store.dispatch(setToggleableState(this.props.childProps.currentPageName, this.qName, itemKey, false))
        } else if (replies[this.props.childProps.name] !== '' && !prevActive) {
          store.dispatch(setToggleableState(this.props.childProps.currentPageName, this.qName, itemKey, true))
        }
        break
      }

      case 'ReplyItem': {
        let prevActive = togs['repliesStatus'][this.rName]
        if (prevActive !== this.active) {
          store.dispatch(setToggleableState(this.props.childProps.currentPageName, this.qName, this.rName, this.active))
        }
        break
      }

      case 'Question': {
        let prevActive = togs['questionStatus']
        if (prevActive !== this.active) {
          store.dispatch(setToggleableState(this.props.childProps.currentPageName, this.qName, this.rName, this.active))
        }
        break
      }

      case 'MatrixColumn': {
        let { jsonref, group, currentPageName } = this.props.childProps
        let colKey = jsonref.replace(/^.*_col_/, '')
        let pageTogs = { ...toggleables[currentPageName] }
        let changed = 0
        let rows = Object.keys(pageTogs).filter(e => e.indexOf(group + '_row_') !== -1)
        rows.forEach(row => {
          let key = `_rep.:${colKey}` in pageTogs[row]['repliesStatus'] ? `_rep.:${colKey}` : colKey
          let questionStatus = pageTogs[row]['questionStatus']
          let repliesStatus = pageTogs[row]['repliesStatus'][key]
          let prevActive = questionStatus && repliesStatus
          if (prevActive !== this.active) {
            changed++
            pageTogs[row]['repliesStatus'][key] = this.active
          }
        })
        if (changed > 0) store.dispatch(setMatrixToggleableState(currentPageName, pageTogs))
        break
      }

      case 'MatrixRow': {
        let prevActive = togs['questionStatus']
        if (prevActive !== this.active) {
          if (!this.active) {
            store.dispatch(
              setToggleableState(this.props.childProps.currentPageName, this.qName, this.rName, this.active)
            )
          } else {
            let { questionName, currentPageName, group } = this.props.childProps
            let pageTogs = { ...toggleables[currentPageName] }
            pageTogs[questionName]['questionStatus'] = this.active
            this.props.childProps.values.forEach(value => {
              let { key } = value
              let exprRef = `${currentPageName}.${group}_col_${key}`

              let page = internalState.expressionValues[currentPageName]
              let disableif = get(page, [`${exprRef}.disableif`], false)
              let enableif = get(page, [`${exprRef}.enableif`], true)
              let hideif = get(page, [`${exprRef}.hideif`], false)
              let displayif = get(page, [`${exprRef}.displayif`], true)
              let replyKey = `_rep.:${key}` in pageTogs[questionName]['repliesStatus'] ? `_rep.:${key}` : key
              let active = enableif && !disableif && displayif && !hideif
              pageTogs[questionName]['repliesStatus'][replyKey] = active
            })
            store.dispatch(setMatrixToggleableState(currentPageName, pageTogs))
          }
        }
        break
      }

      case 'MSelectReplistItem': {
        let rlk = this.props.childProps.jsonref.split('.')[2]
        let { currentPageName, group } = this.props.childProps
        let pageTogs = { ...toggleables[currentPageName] }
        let rows = Object.keys(pageTogs).filter(e => e.indexOf(group + '_row_') !== -1)
        let firstRowTogs = pageTogs[rows[0]]['repliesStatus']
        let rlitemKeys = Object.keys(firstRowTogs).filter(k => k.endsWith(`.:${rlk}`))
        let prevActive = firstRowTogs[rlitemKeys[0]]
        if (prevActive !== this.active) {
          let newValues = rlitemKeys.reduce((acc, k) => ({ ...acc, [k]: this.active }), {})
          rows.forEach(row => {
            store.dispatch(setMselectRlitemsToggleableState(currentPageName, row, newValues))
          })
        }
        break
      }

      default:
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!shallowEqualObjects(nextProps, this.props)) {
      let { props } = this
      let { questionSkipped, parentDisabled, forceVisible } = nextProps.childProps
      let { enableif, disableif, displayif, hideif } = nextProps
      this.evaluateState(enableif, disableif, displayif, hideif, questionSkipped, parentDisabled, forceVisible)

      let toggleablesPropsChanged =
        questionSkipped !== props.questionSkipped ||
        parentDisabled !== props.parentDisabled ||
        forceVisible !== props.forceVisible ||
        enableif !== props.enableif ||
        disableif !== props.disableif ||
        displayif !== props.displayif ||
        hideif !== props.hideif

      if (props.childType === 'CommentItem' || (props.hasExpressions && toggleablesPropsChanged)) {
        this.setToggleableState()
      }
      return true
    }
    return false
  }

  componentDidMount() {
    if (this.props.hasExpressions || this.props.childType === 'CommentItem') this.setToggleableState()
  }

  render() {
    let ChildComponent = this.props.childComponent
    let { disabled, visible, active } = this
    return <ChildComponent {...this.props.childProps} disabled={disabled} visible={visible} active={active} />
  }
}

/* CONNECTED TOGGLEABLE WRAPPER - STANDARD redux IMPLEMENTATION */
const mstp = (state, props) => {
  let jsonref = props.childProps.jsonref
  let page = state.internalState.expressionValues[props.childProps.currentPageName]

  return {
    disableif: page[`${jsonref}.disableif`],
    enableif: page[`${jsonref}.enableif`],
    hideif: page[`${jsonref}.hideif`],
    displayif: page[`${jsonref}.displayif`],
  }
}

export const ConnectedToggleableWrapper = connect(mstp)(ToggleableWrapper)
