import * as React from 'react'
import { Grid } from 'semantic-ui-react'

import Constants from '../../utils/constants'
import withSkippable from '../../enhancers/skippable'
import { CommentItemToggleableHook } from '../../enhancers/toggleable'
import { SelectField } from '../formfields'

class SelectQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  render() {
    let selects = []
    this.props.items.forEach(item => {
      selects.push(
        <Grid.Row key={item.key} className="resurvey question body">
          <SelectField
            key={`item-${item.jsonref}`}
            name={item.key}
            label={item.value}
            selectOptions={item.values}
            questionWidth={this.props.options.width || Constants.FULLWIDTH}
            parentDisabled={this.props.disabled}
            hasComment={item.has_comment}
            currentPageName={this.props.currentPageName}
            questionName={this.props.name}
            jsonref={item.jsonref}
            questionSkipped={this.props.questionSkipped}
          />
          {item.has_comment === true && (
            <CommentItemToggleableHook
              currentPageName={this.props.currentPageName}
              questionName={this.props.name}
              name={item.key + '_comment'}
              jsonref={item.jsonref + '_comment'}
            />
          )}
        </Grid.Row>
      )
    })

    if (this.props.reverseItems) selects.reverse()
    return selects
  }
}

export default withSkippable(SelectQuestionBody)
