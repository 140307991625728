import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

import { RadioField as _RadioField } from '../../formfields/radio-field'
import { CommentItemField } from '../../formfields/comment-field'
import Qtext from '../../qtext.js'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'

const RadioField = withToggle(connectField(_RadioField))

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.name]['_rowcomment'] }
}
class _List extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const rowKey = this.props.name.split('_row_')[1]

    return (
      <Grid.Row style={withToggleStyle}>
        {this.props.items.map(item => (
          <RadioField
            key={`item-${item.jsonref}`}
            itemKey={item.key}
            label={item.value}
            jsonref={`${this.props.currentPageName}.${this.props.group}_col_${item.key}`}
            parentDisabled={this.props.parentDisabled || this.props.disabled || this.props.rowComment === ''}
            name={this.props.name + '-_rep'}
            data-name="_rep"
            questionName={this.props.name}
            currentPageName={this.props.currentPageName}
            hasComment={item.type === 'comment' || item.type === 'optionalcomment'}
            questionSkipped={this.props.questionSkipped || false}
            className={`matrix-cell cell-${rowKey}-${item.key}`}
          />
        ))}
      </Grid.Row>
    )
  }
}
_List.displayName = '_List'

const RadioListWithComment = withToggle(connect(mstp)(_List))
const RadioList = withToggle(_List)

const MobileBody = props => {
  let childQuestions = props.childQuestions || []
  return childQuestions.map(q => {
    const childProps = {
      key: `key-${q.name}`,
      name: q.name,
      group: props.name,
      parentDisabled: props.questionSkipped || props.disabled,
      currentPageName: props.currentPageName,
      jsonref: `${props.currentPageName}.${q.name}`,
      items: q.items[0].values,
      qtext: q.qtext[0],
      hasComment: typeof q.has_comment !== 'undefined',
      withToggleStyle: props.visible ? {} : { display: 'none' },
    }

    return <MobileMatrixItem {...childProps} />
  })
}

const MobileMatrixItem = withToggle(props => {
  let { qtext, hasComment, withToggleStyle, ...listProps } = props
  let List = hasComment ? RadioListWithComment : RadioList

  return (
    <React.Fragment key={props.name}>
      <Grid.Row style={withToggleStyle}>
        <Grid.Column>
          <Qtext {...qtext} {...props} />
          {hasComment === true && (
            <CommentItemField
              label=""
              disabled={props.questionSkipped || props.disabled}
              visible={props.visible}
              name={`_rowcomment`}
              questionName={props.name}
              currentPageName={props.currentPageName}
              jsonref={`${props.currentPageName}.${props.name}.comment_matrix`}
            />
          )}
        </Grid.Column>
      </Grid.Row>

      <List {...listProps} />
    </React.Fragment>
  )
})

export default MobileBody
