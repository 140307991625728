import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

import { TextField as _TextField } from '../../formfields/text-field'
import { CommentItemField } from '../../formfields/comment-field'
import Qtext from '../../qtext.js'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'
import Constants from '../../../utils/constants'

const TextField = withToggle(connectField(_TextField))

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.name]['_rowcomment'] }
}
class _List extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const rowKey = this.props.name.split('_row_')[1]

    return (
      <Grid.Row style={withToggleStyle}>
        {this.props.items.map(item => (
          <TextField
            key={`item-${item.jsonref}`}
            label={item.value}
            name={item.key}
            parentDisabled={this.props.parentDisabled || this.props.disabled || this.props.rowComment === ''}
            jsonref={`${this.props.currentPageName}.${this.props.group}_col_${item.key}`}
            questionName={this.props.name}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped || false}
            maxlength={this.props.maxlength || Constants.DEFAULTS.TEXT_MAX_LENGTH}
            className={`matrix-cell cell-${rowKey}-${item.key}`}
          />
        ))}
      </Grid.Row>
    )
  }
}
_List.displayName = '_List'

const TextListWithComment = withToggle(connect(mstp)(_List))
const TextList = withToggle(_List)

const MobileBody = props => {
  let childQuestions = props.childQuestions || []

  return childQuestions.map(q => {
    const childProps = {
      key: `key-${q.name}`,
      name: q.name,
      group: props.name,
      parentDisabled: props.questionSkipped || props.disabled,
      currentPageName: props.currentPageName,
      jsonref: q.jsonref,
      items: q.items,
      maxlength: props.options.maxlength,
      qtext: q.qtext[0],
      hasComment: typeof q.has_comment !== 'undefined',
      withToggleStyle: props.visible ? {} : { display: 'none' },
    }

    return <MobileMTextItem {...childProps} />
  })
}

const MobileMTextItem = withToggle(props => {
  let { qtext, hasComment, withToggleStyle, ...listProps } = props
  let List = hasComment ? TextListWithComment : TextList
  return (
    <React.Fragment key={props.name}>
      <Grid.Row style={withToggleStyle}>
        <Grid.Column>
          <Qtext {...qtext} {...props} />
          {hasComment === true && (
            <CommentItemField
              label=""
              disabled={props.questionSkipped || props.disabled}
              visible={props.visible}
              name={`_rowcomment`}
              questionName={props.name}
              currentPageName={props.currentPageName}
              jsonref={`${props.currentPageName}.${props.name}.comment_matrix`}
            />
          )}
        </Grid.Column>
      </Grid.Row>

      <List {...listProps} />
    </React.Fragment>
  )
})

export default MobileBody
