import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'

import Interpolator from '../../utils/interpolator'

const interpolator = new Interpolator()

const mapStateToProps = (state, ownProps) => {
  return {
    url: interpolator.render(ownProps.options.url),
    mode: state.internalState.mode,
  }
}

class SubsurveyQuestionBody extends React.PureComponent {
  static defaultProps = {
    options: { url: '#' },
  }

  onMessage = e => {
    if (e.data === 'CLOSE_SUB_SURVEY') {
      document.querySelector('button.right').click()
    }
  }

  componentDidMount() {
    let url = this.props.url.includes('?') ? `${this.props.url}&isSubSurvey=true` : `${this.props.url}?isSubSurvey=true`
    let iframe = document.createElement('iframe')
    iframe.setAttribute('id', 'subsurvey-iframe')
    iframe.setAttribute('src', url)
    document.querySelector('.main.container').appendChild(iframe)
    this.iframe = iframe
    window.addEventListener('message', this.onMessage, false)
  }

  componentWillUnmount() {
    this.iframe.remove()
    window.removeEventListener('message', this.onMessage)
  }

  render() {
    return (
      <React.Fragment>
        <style>
          {`
            #subsurvey-iframe,
            .resurvey.container::after {
              content: ' ';
              position: absolute;
              top: 0;
              left: 0;
              background: #efefef;
              z-index: 9999;
              width: 100%;
              height: 100%;
            }
            #subsurvey-iframe {
              z-index: 100000;
              border: none;
            }
          `}
        </style>
        <Grid.Row className="resurvey question body"></Grid.Row>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(SubsurveyQuestionBody)
