export const generateTranspileFn = async definition => {
  let hasScripts =
    definition.content.filter(p => {
      return 'page_options' in p && 'execute' in p.page_options && p.page_options.execute === 'script'
    }).length > 0

  if (hasScripts) {
    let babel = await import('@babel/standalone')
    let plugins = [
      'syntax-async-generators',
      'syntax-class-properties',
      'syntax-do-expressions',
      'syntax-function-bind',
      'syntax-function-sent',
      'syntax-object-rest-spread',
      'syntax-optional-catch-binding',
      'proposal-async-generator-functions',
      'proposal-class-properties',
      'proposal-do-expressions',
      'proposal-function-bind',
      'proposal-function-sent',
      'proposal-json-strings',
      'proposal-logical-assignment-operators',
      'proposal-nullish-coalescing-operator',
      'proposal-numeric-separator',
      'proposal-object-rest-spread',
      'proposal-optional-catch-binding',
      'proposal-optional-chaining',
      'proposal-throw-expressions',
      'proposal-unicode-property-regex',
      'transform-async-to-generator',
      'transform-arrow-functions',
      'transform-block-scoped-functions',
      'transform-block-scoping',
      'transform-computed-properties',
      'transform-destructuring',
      'transform-dotall-regex',
      'transform-duplicate-keys',
      'transform-exponentiation-operator',
      'transform-for-of',
      'transform-function-name',
      'transform-instanceof',
      'transform-jscript',
      'transform-literals',
      'transform-member-expression-literals',
      'transform-new-target',
      'transform-object-assign',
      'transform-object-super',
      'transform-object-set-prototype-of-to-assign',
      'transform-parameters',
      'transform-property-literals',
      'transform-property-mutators',
      'transform-proto-to-assign',
      'transform-regenerator',
      'transform-reserved-words',
      'transform-shorthand-properties',
      'transform-spread',
      'transform-sticky-regex',
      'transform-strict-mode',
      'transform-template-literals',
      'transform-typeof-symbol',
      'transform-unicode-regex',
    ]
    const babelOptions = {
      plugins,
      babelrc: false,
      ast: false,
    }
    return program => {
      let { code } = babel.transform(program, babelOptions)
      return code
    }
  } else {
    return code => code
  }
}

export default generateTranspileFn
