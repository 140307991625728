import Loadable from 'react-loadable'
import ThemeCustom from './theme-custom'

const lazyLoad = imp => {
  return Loadable({
    loader: imp,
    loading: () => null,
  })
}

// Add new themes here!
export const Themes = {
  default: lazyLoad(() => import('./theme-default')),
  'admin-preview': lazyLoad(() => import('./theme-admin-preview')),
  gmc: lazyLoad(() => import('./theme-gmc')),
  pharmagrin: lazyLoad(() => import('./theme-pharmagrin')),
  iqvia: lazyLoad(() => import('./theme-iqvia')),
  doxapharma: lazyLoad(() => import('./theme-doxapharma')),
  solarized: lazyLoad(() => import('./theme-solarized')),
  custom: ThemeCustom,
}

export default Themes
