import cloneDeep from 'lodash.clonedeep'
import get from 'lodash.get'
import { resetData, resetMatrixColumnData, resetMSelectReplistItemData } from './survey-data'
import { updateSteplist, UPDATE_STEPLIST } from './update-steplist'

// Action types
export const SET_CURRENT_PAGE = 'survey/SET_CURRENT_PAGE'
export const SET_GOTO_PAGE = 'survey/SET_GOTO_PAGE'
export const EMPTY_GOTO_PAGE = 'survey/EMPTY_GOTO_PAGE'
export const SET_EXPRESSIONS = 'survey/SET_EXPRESSIONS'
export const SET_PAGES = 'survey/SET_PAGES'
export const SET_ITEMS = 'survey/SET_ITEMS'
export const SET_UNIQUE_GROUPS = 'survey/SET_UNIQUE_GROUPS'
export const EVALUATE_EXPRESSION = 'survey/EVALUATE_EXPRESSION'
export const INIT_EXPRESSION_VALUES = 'survey/INIT_EXPRESSION_VALUES'
export const SET_PAGE_REQUEST_IDX = 'survey/SET_PAGE_REQUEST_IDX'
export const SET_DIRECTION = 'survey/SET_DIRECTION'
export const SET_PAGE_SUBMIT_STATUS = 'survey/SET_PAGE_SUBMIT_STATUS'
export const SET_EXIT_STATUS = 'survey/SET_EXIT_STATUS'
export const SET_STOP_STATUS = 'survey/SET_STOP_STATUS'
export const SET_MODE = 'survey/SET_MODE'
export const SET_OOQ = 'survey/SET_OOQ'
export const UPDATE_SURVEY_VERSIONS = 'survey/UPDATE_SURVEY_VERSIONS'
export const SET_STEPLIST = 'survey/SET_STEPLIST'
export const SET_RANDOMIZATIONS_GROUPS = 'survey/SET_RANDOMIZATIONS_GROUPS'

// Initial state
export const initialState = {
  currentPageIdx: -1,
  currentPageName: '',
  gotoPageName: '',
  requestedPageIdx: -1,
  direction: '',
  pageSubmitStatus: 'COMPLETED',
  expressions: {},
  expressionValues: {},
  randomizations: { groups: {}, permutations: {} },
  pages: [],
  items: {},
  uniqueGroups: {},
  exitType: '',
  exitCode: '',
  customEndPage: '',
  stopStatus: 'HIDE',
  stepList: [],
  surveyVersions: [],
  mode: 'production',
  outofquota: false,
}

// Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPageIdx: action.payload,
        currentPageName: state.pages[action.payload].name,
      }
    case SET_GOTO_PAGE:
      return { ...state, gotoPageName: action.payload }
    case EMPTY_GOTO_PAGE:
      return { ...state, gotoPageName: '' }
    case UPDATE_STEPLIST:
      return { ...state, stepList: [...state.stepList, ...action.payload] }
    case SET_STEPLIST:
      return { ...state, stepList: action.payload }
    case UPDATE_SURVEY_VERSIONS:
      return { ...state, surveyVersions: action.payload }
    case SET_PAGE_REQUEST_IDX:
      return { ...state, requestedPageIdx: action.payload }
    case SET_DIRECTION:
      return { ...state, direction: action.payload }
    case SET_PAGE_SUBMIT_STATUS:
      return { ...state, pageSubmitStatus: action.payload }
    case SET_EXPRESSIONS:
      return { ...state, expressions: action.payload }
    case SET_PAGES:
      return { ...state, pages: action.payload }
    case SET_ITEMS:
      return { ...state, items: action.payload }
    case SET_UNIQUE_GROUPS:
      return { ...state, uniqueGroups: action.payload }
    case SET_RANDOMIZATIONS_GROUPS:
      return { ...state, randomizations: action.payload }
    case INIT_EXPRESSION_VALUES:
      return { ...state, expressionValues: action.payload }
    case EVALUATE_EXPRESSION:
      return {
        ...state,
        expressionValues: {
          ...state.expressionValues,
          [action.payload.page]: {
            ...state.expressionValues[action.payload.page],
            [action.payload.expression]: action.payload.value,
          },
        },
      }
    case SET_EXIT_STATUS:
      return {
        ...state,
        exitType: action.payload.exitType,
        exitCode: action.payload.exitCode,
        customEndPage: action.payload.customEndPage,
      }
    case SET_STOP_STATUS:
      return { ...state, stopStatus: action.payload }

    case SET_MODE:
      return { ...state, mode: action.payload }
    case SET_OOQ:
      return { ...state, outofquota: action.payload }
    default:
      return state
  }
}

// Action creators

export const setCurrentPage = currentPageIdx => (dispatch, getState) => {
  let page = getState().internalState.pages[currentPageIdx]
  if (!isPageExecutable({ getState }, currentPageIdx) && !isEndPage(page)) {
    dispatch(updateSteplist([page.name]))
  }
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: currentPageIdx,
  })
}

export const setGotoPage = page => ({
  type: SET_GOTO_PAGE,
  payload: page,
})

export const emptyGotoPage = () => ({
  type: EMPTY_GOTO_PAGE,
})

export const setPageRequestIdx = pageIdx => ({
  type: SET_PAGE_REQUEST_IDX,
  payload: pageIdx,
})

export const setDirection = direction => ({
  type: SET_DIRECTION,
  payload: direction,
})

export const setPageSubmitStatus = status => ({
  type: SET_PAGE_SUBMIT_STATUS,
  payload: status,
})

export const setExpressions = expressions => ({
  type: SET_EXPRESSIONS,
  payload: expressions,
})

export const setPages = pages => ({
  type: SET_PAGES,
  payload: pages,
})

export const setItems = items => ({
  type: SET_ITEMS,
  payload: items,
})

export const setUniqueGroups = uniqueGroups => ({
  type: SET_UNIQUE_GROUPS,
  payload: uniqueGroups,
})

export const setExitStatus = (exitType, exitCode, customEndPage = '') => ({
  type: SET_EXIT_STATUS,
  payload: { exitType, exitCode, customEndPage },
})

export const setStopStatus = stopStatus => ({
  type: SET_STOP_STATUS,
  payload: stopStatus,
})

export const setMode = mode => ({
  type: SET_MODE,
  payload: mode,
})

export const setSteplist = steplist => ({
  type: SET_STEPLIST,
  payload: steplist,
})

export const setRandomizationsGroups = randomizations => ({
  type: SET_RANDOMIZATIONS_GROUPS,
  payload: randomizations,
})

export const setOutOfQuota =
  (outofquota, onSurveyCompleted = () => {}) =>
  async (dispatch, getState) => {
    dispatch({ type: SET_OOQ, payload: outofquota })
    if (outofquota) {
      let state = getState()
      onSurveyCompleted(state.data, state)
    }
  }

export const updateSurveyVersions = versions => ({
  type: UPDATE_SURVEY_VERSIONS,
  payload: versions,
})

export const updateExpressionValue = (page, expression, value) => ({
  type: EVALUATE_EXPRESSION,
  payload: { page, expression, value },
})

export const evaluateExpression = (page, expression, value) => async (dispatch, getState) => {
  await dispatch(updateExpressionValue(page, expression, value)) //await to dispatch al setToggleables before resetData

  let ar = expression.split('.')
  let operation = ar.pop()
  let jsonref = ar.join('.')

  let isMatrixColumnExpression = /_col_/.test(expression)
  let isMselectReplistItemExpression = /_rlitem\./.test(expression)
  let qtype = ''

  if (ar.length > 2) {
    qtype = get(getState(), ['data', ar[0], ar[1], '__qtype'])
  } else if (isMatrixColumnExpression) {
    qtype = get(getState(), ['data', ar[0], ar[1].split('_col_')[0], '__qtype'])
  }

  // do not dispath resetData if question is a dragndrop or ranking
  if (['dragndrop', 'ranking', 'mranking'].includes(qtype)) return
  if (/__qtext/.test(jsonref)) return

  if (
    (operation === 'hideif' && value === true) ||
    (operation === 'displayif' && value === false) ||
    (operation === 'enableif' && value === false) ||
    (operation === 'disableif' && value === true)
  ) {
    if (isMatrixColumnExpression) {
      dispatch(resetMatrixColumnData(jsonref))
    } else if (isMselectReplistItemExpression) {
      dispatch(resetMSelectReplistItemData(jsonref))
    } else {
      dispatch(resetData(jsonref))
    }
  }
}

export const initExpressionValues = initialValues => ({
  type: INIT_EXPRESSION_VALUES,
  payload: initialValues,
})

// Selectors
export const isPageVisible = (store, idx) => {
  let state = store.getState()
  let { name } = state.internalState.pages[idx]

  let displayif = get(state.internalState, ['expressionValues', name, `${name}.displayif`], true)
  let hideif = get(state.internalState, ['expressionValues', name, `${name}.hideif`], false)
  let enableif = get(state.internalState, ['expressionValues', name, `${name}.enableif`], true)
  let disableif = get(state.internalState, ['expressionValues', name, `${name}.disableif`], false)

  return displayif && !hideif && enableif && !disableif
}

export const isPageExecutable = (store, idx) => {
  let page = store.getState().internalState.pages[idx]
  return typeof page.page_options.execute !== 'undefined'
}

export const isEndPage = page => {
  return page.page_options.is_end_page === true || page.name === '__outofquota_survey'
}

export const getNextPageIdx = (store, direction, currentIndex = store.getState().internalState.currentPageIdx) => {
  let pages = store.getState().internalState.pages
  let gotoPageName = get(store.getState(), ['internalState', 'gotoPageName'], '')
  let gotoPageIdx = -1
  if (gotoPageName !== '') {
    gotoPageIdx = getPageIndexByName(store, gotoPageName)
    direction = 'next'
  }

  let index = 0
  if (direction === 'back') {
    let idx = gotoPageIdx === -1 ? currentIndex - 1 : gotoPageIdx
    while (idx >= 0 && (!isPageVisible(store, idx) || isPageExecutable(store, idx))) {
      if (--idx < 0) break
    }
    index = idx >= 0 ? idx : -1
  } else if (direction === 'next') {
    let idx = gotoPageIdx === -1 ? currentIndex + 1 : gotoPageIdx
    while (idx < pages.length && !isPageVisible(store, idx)) {
      if (++idx === pages.length) break
    }
    index = idx < pages.length ? idx : -2
  } else {
    index = -3
  }
  return index
}

export const resetDataForNextPages = index => (dispatch, getState) => {
  let pages = getState().internalState.pages
  if (pages[index].page_options.is_end_page) {
    for (let i = index + 1; i < pages.length; i++) {
      dispatch(resetData(pages[i].jsonref))
    }
  }
}

export const prepareSnapshot = (store, index) => {
  // prepare snapthot for submit
  let currentPageIdx = store.getState().internalState.currentPageIdx
  let pages = store.getState().internalState.pages
  let snapshot = cloneDeep(store.getState())

  // FIX: previous page for stopped interviews
  let _index = index
  if (index === getPageIndexByName(store, '__stop_survey')) {
    let { stepList } = store.getState().internalState
    _index = stepList.length > 1 ? getPageIndexByName(store, stepList[stepList.length - 2]) : 0
  }

  let snapshotIdx = _index > currentPageIdx ? getNextPageIdx(store, 'back', _index) : _index

  snapshot.internalState.pageSubmitStatus = 'COMPLETED'
  snapshot.internalState.requestedPageIdx = -1
  snapshot.internalState.currentPageIdx = snapshotIdx
  snapshot.internalState.currentPageName = pages[snapshotIdx].name
  snapshot.internalState.stopStatus = 'HIDE'

  // Reduce snapshot size removing definition-related content
  delete snapshot.internalState.expressions
  delete snapshot.internalState.items
  delete snapshot.internalState.uniqueGroups
  snapshot.internalState.pages.forEach(p => (p.content = []))

  let { parser, internalState, data, toggleables, respondent_tracker } = snapshot
  return { parser, internalState, data, toggleables, respondent_tracker }
}

export const getPageIndexByName = (store, name) => {
  return store.getState().internalState.pages.findIndex(page => {
    return page.name === name
  })
}

export default reducer
