import * as rsch from './rscode-helpers'
import { store } from '../store'
import parse from './jsparser'

class Interpolator {
  //https://stackoverflow.com/questions/977251/regular-expressions-and-negating-a-whole-character-group/977294#977294
  static needInterpolation = text => {
    return /%\((?:(?!\)%).)+\)%/gm.test(text)
  }

  static getTokens = text => {
    let _regexp = /%\((?:(?!\)%).)+\)%/gm
    let matches = text.match(_regexp)
    return matches ? matches.map(t => t.replace('%(', '').replace(')%', '')) : []
  }

  tokenize(text, throwError = false) {
    let _regexp = /%\((?:(?!\)%).)+\)%/gm
    let staticTokens = text.split(_regexp)
    let dynamicTokens = []
    text.replace(_regexp, m => {
      try {
        let expr = m.replace('%(', '').replace(')%', '')
        let { code } = parse(expr, false)
        dynamicTokens.push(this.generateExprFn(code, throwError))
      } catch (e) {
        console.log(throwError, text, e)
        if (throwError) {
          let err = new Error(`Syntax error: ${e.message}`)
          err.name = 'InterpolatorError'
          throw err
        } else {
          console.log(e)
          dynamicTokens.push(`<span style="color: red">Syntax error: ${e.message}</span>`)
        }
      }
      return ''
    })

    let toks = staticTokens.reduce(function (acc, v, i) {
      return dynamicTokens[i] ? [...acc, v, dynamicTokens[i]] : [...acc, v]
    }, [])
    return toks
  }

  generateExprFn = (expr, throwError = false) => {
    let program = `
    try {
      return ${expr}
    } catch(e) {
      if (${throwError}) {
        var err = new Error("Runtime error: " + e.message)
        err.name= "InterpolatorError"
        throw err
      } else {
        console.log(e)
        return '<span style="color: red">Runtime error: ' + e.message + '</span>'
      }
    };`

    return new Function('data', 'toggleables', ...rsch.helperNames, program) //eslint-disable-line
  }

  interpolate = (text, throwError = false) => {
    let { data, toggleables } = store.getState()
    let tokens = this.tokenize(text, throwError)

    let msg = tokens.reduce((acc, v, i) => {
      switch (typeof v) {
        case 'string':
          return acc + v
        case 'function':
          let s = v(data, toggleables, ...rsch.helpers)
          s = Array.isArray(s) ? s.join(', ') : s
          s = typeof s === 'number' ? s.toString() : s
          return acc + (s ? s.toString() : '...')
        default:
          return acc
      }
    }, '')

    return msg
  }

  render = (text, throwError = false) => {
    let str = this.interpolate(text, throwError)
    while (Interpolator.needInterpolation(str)) {
      str = this.interpolate(str, throwError)
    }
    return str
  }

  renderUrl = url => this.render(url, true).replace(/\.\.\./g, '')
}
export default Interpolator
