import _MobileDetect from 'mobile-detect'

let md = new _MobileDetect(navigator.userAgent, 600)

export const MobileDetect = {
  isMobile: md.mobile() !== null,
  isPhone: md.phone() !== null,
  isTablet: md.tablet() !== null,
  userAgent: md.userAgent(),
  os: md.os(),
  is: md.is,
  version: md.version,
  versionStr: md.versionStr,
  match: md.match,
}

export default MobileDetect
