import * as React from 'react'
import { connect } from 'react-redux'
import get from 'lodash.get'

import * as rsch from '../utils/rscode-helpers.js'
import Interpolator from '../utils/interpolator'

import {
  isActive,
  isAnswered,
  matchMinimum,
  matchMaximum,
  matchExactSum,
  matchMaxSum,
  matchMinSum,
  matchExactChecked,
  matchMaxChecked,
  matchMinChecked,
  matchEmail,
  matchUrl,
  matchPattern,
  matchRepsLimit,
  //matchWatchLimit,
} from '../utils/validation-helpers'
import { setQuestionValidationStatus, setQuestionErrorList } from '../store/survey-validation'
import expressionBuilder from '../utils/exprbuilder'

import { store } from '../store'

const generateMapStateToProps = (page, question, group) => {
  let expressionObjects = {}
  let deps = []
  store.getState().validators.configs[page][question].forEach(v => {
    if (group) {
      deps = [`${page}.${group}`]
    }
    if (
      [
        'min',
        'max',
        'min_sum',
        'max_sum',
        'exact_sum',
        'min_checked',
        'max_checked',
        'exact_checked',
        'min_reps',
        'min_watch',
      ].indexOf(v.type) !== -1
    ) {
      let exprKey = `${page}.${question}.${v.type}`
      let depTks = Interpolator.getTokens(v.value)
      let expr = expressionBuilder(depTks, exprKey)
      let dps = []
      if ('deps' in expr.exprObject) {
        dps = expr.exprObject.deps.map(dep => dep.split('.').splice(0, 2).join('.'))
      }
      deps = [...deps, ...dps]
    }
    if (['valid_if', 'invalid_if'].indexOf(v.type) !== -1) {
      v.value.forEach((vItem, i) => {
        let exprKey = `${page}.${question}.${v.type}_${i}`
        let expr = expressionBuilder(vItem.expr, exprKey)
        expressionObjects[exprKey] = { ...expr.exprObject, errors: expr.errors }
        let dps = expr.exprObject.deps.map(dep => dep.split('.').splice(0, 2).join('.'))
        deps = [...deps, ...dps]
      })
    }
  })

  deps = [...new Set(deps)].map(dep => dep.split('.'))

  let tks = [page, question]

  return state => {
    let { data, toggleables } = state
    let depData = [tks, ...deps].reduce((acc, dep_tks) => {
      const depData = get(data, dep_tks, {})
      return { ...acc, [dep_tks.join('_')]: depData }
    }, {})

    let depToggleables = toggleables[page][question]

    return {
      page,
      question,
      group,
      tks,
      expressionObjects,
      isPageSubmitted: state.validators.pageStatuses[page] === 'SUBMITTED',
      depData: JSON.stringify(depData),
      questionStatus: depToggleables.questionStatus,
      repliesStatus: JSON.stringify(depToggleables.repliesStatus),
    }
  }
}

class Validator extends React.PureComponent {
  interpolator

  constructor(props) {
    super(props)
    this.interpolator = new Interpolator()
  }

  validate = () => {
    let questionStatus = 'VALID'
    let errors = []
    let errored = []

    let { page, question, tks } = this.props
    let state = store.getState()
    let {
      required_msg = '',
      required_comment_msg = '',
      number_min_msg = '',
      number_max_msg = '',
      video_min_watch_msg = '',
    } = state.definition
    let { configs, errors: oldErrors } = state.validators

    configs[page][question].forEach(v => {
      switch (v.type) {
        case 'required':
          if (v.value) {
            // required === true
            let validator = isAnswered(this.props.tks, v.for)
            if (isActive(tks, v.for) && !validator.status) {
              let msg = required_msg
              if (typeof v.msg !== 'undefined') msg = v.msg
              if (typeof v.for !== 'undefined' && v.for.match(/.*_comment$/)) msg = required_comment_msg
              if (errors.indexOf(msg) === -1) errors.push(msg)
              questionStatus = 'INVALID'
              errored = [...errored, ...validator.errored]
            }
          }
          break

        case 'min': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value.toString())) {
            value = this.interpolator.render(v.value.toString())
          }
          value = parseFloat(value)
          value = isNaN(value) ? 0 : value
          let validator = matchMinimum(tks, value, v.for)

          if (isActive(tks, v.for) && !validator.status) {
            let msg = number_min_msg
            msg = msg.replace('%(MIN)%', v.value.toString())
            if (errors.indexOf(msg) === -1) errors.push(msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'max': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value.toString())) {
            value = this.interpolator.render(v.value.toString())
          }
          value = parseFloat(value)
          value = isNaN(value) ? Infinity : value
          let validator = matchMaximum(tks, value, v.for)

          if (isActive(tks, v.for) && !validator.status) {
            let msg = number_max_msg
            msg = msg.replace('%(MAX)%', v.value.toString())
            if (errors.indexOf(msg) === -1) errors.push(msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }

        case 'min_sum': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value)) {
            value = this.interpolator.render(v.value)
          }
          value = parseFloat(value)
          value = isNaN(value) ? 0 : value
          let validator = matchMinSum(tks, value)

          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'max_sum': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value)) {
            value = this.interpolator.render(v.value)
          }
          value = parseFloat(value)
          value = isNaN(value) ? Infinity : value
          let validator = matchMaxSum(tks, value)

          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'exact_sum': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value)) {
            value = this.interpolator.render(v.value)
          }
          value = parseFloat(value)
          value = isNaN(value) ? Infinity : value
          let validator = matchExactSum(tks, value)

          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'min_checked': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value)) {
            value = this.interpolator.render(v.value)
          }
          value = parseInt(value)
          value = isNaN(value) ? 0 : value
          let validator = matchMinChecked(tks, value)

          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'max_checked': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value)) {
            value = this.interpolator.render(v.value)
          }
          value = parseInt(value)
          value = isNaN(value) ? Infinity : value
          let validator = matchMaxChecked(tks, value)

          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'exact_checked': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value)) {
            value = this.interpolator.render(v.value)
          }
          value = parseInt(value)
          value = isNaN(value) ? Infinity : value
          let validator = matchExactChecked(tks, value)

          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'matchemail': {
          let validator = matchEmail(tks)
          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'matchurl': {
          let validator = matchUrl(tks)
          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'matchpattern': {
          let validator = matchPattern(tks, v.pattern, v.flags)
          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'min_reps': {
          let value = v.value
          if (Interpolator.needInterpolation(v.value.toString())) {
            value = this.interpolator.render(v.value.toString())
          }
          value = parseInt(value)
          value = isNaN(value) ? 0 : value
          let validator = matchRepsLimit(tks, value)

          if (isActive(tks, v.for) && !validator.status) {
            if (errors.indexOf(v.msg) === -1) errors.push(v.msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'min_watch': {
          let value = v.value

          if (typeof v.value !== 'undefined' && Interpolator.needInterpolation(v.value.toString())) {
            value = this.interpolator.render(v.value.toString())
          }
          value = parseInt(value)
          value = isNaN(value) ? 10 : value

          //let validator = matchWatchLimit(tks, value)
          let validator = isAnswered(tks, v.for)

          if (isActive(tks, v.for) && !validator.status) {
            let msg = typeof v.msg !== 'undefined' ? v.msg : video_min_watch_msg
            msg = msg.replace('%(MIN_WATCH)%', value)
            if (errors.indexOf(msg) === -1) errors.push(msg)
            questionStatus = 'INVALID'
            errored = [...errored, ...validator.errored]
          }
          break
        }
        case 'valid_if':
        case 'invalid_if': {
          let helpers = [...rsch.helpers]
          helpers[0] = rsch.isAnsweredWithMatrixRowsWorkaround
          let { data, toggleables } = store.getState()

          v.value.forEach((vItem, i) => {
            let exprKey = `${page}.${question}.${v.type}_${i}`
            let { exprFunction, errors: exprErrors } = this.props.expressionObjects[exprKey]

            if (exprErrors.length > 0) {
              // Expression building error.. invalidate question
              errors = [...errors, ...exprErrors]
              questionStatus = 'INVALID'
            } else {
              if (!isActive(tks, v.for)) return
              let ret = exprFunction(data, toggleables, ...helpers)
              ret = v.type === 'valid_if' ? ret : !ret
              if (!ret) {
                errors.push(vItem.msg)
                questionStatus = 'INVALID'
              }
            }
          })
          if (questionStatus === 'INVALID' && !/_row_/.test(question)) {
            let jsonRefs = Object.keys(toggleables[page][question]['repliesStatus']).map(
              k => `${page}.${question}.${k}`
            )
            errored = [...errored, ...jsonRefs]
          }
          break
        }

        default:
          break
      }
    })

    if (
      state.validators.questionStatuses[page][question] !== questionStatus ||
      oldErrors.length !== errors.length ||
      oldErrors.toString() !== errors.toString()
    ) {
      this.props.dispatch(setQuestionValidationStatus(page, question, questionStatus, errors))
    }

    return errored
  }

  componentDidUpdate(oldProps) {
    if (!this.props.questionStatus && oldProps.questionStatus) {
      this.props.dispatch(setQuestionValidationStatus(this.props.page, this.props.question, 'VALID', []))
    }
    if (this.props.isPageSubmitted && this.props.questionStatus) {
      let errored = [...new Set(this.validate())]
      this.props.dispatch(setQuestionErrorList(this.props.page, this.props.question, errored))
    }
  }

  render() {
    return null
  }
}

const generateValidator = (page, question, group) => {
  const mstp = generateMapStateToProps(page, question, group)
  return connect(mstp)(Validator)
}

export default generateValidator
