import * as React from 'react'
import { Grid } from 'semantic-ui-react'

import withSkippable from '../../enhancers/skippable'
import { DragndropField } from '../formfields'

class DragndropQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  render() {
    let items = [...this.props.items]
    if (this.props.reverseItems) items.reverse()

    return (
      <Grid.Row key={this.props.name} className="resurvey question body">
        <DragndropField
          name={this.props.name}
          key={`item-${this.props.jsonref}`}
          questionName={this.props.name}
          label={this.props.value}
          parentDisabled={this.props.disabled}
          jsonref={this.props.jsonref}
          max_reps={this.props.options.max_reps}
          currentPageName={this.props.currentPageName}
          questionSkipped={this.props.questionSkipped}
          items={items}
        />
      </Grid.Row>
    )
  }
}

export default withSkippable(DragndropQuestionBody)
