import * as React from 'react'
import { Grid, Form, Label } from 'semantic-ui-react'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import { CommentItemField } from './comment-field'

const rg1 = /[,]+/g
const rg2 = /[^0-9.]+/g
const rg3 = /^0\d+/g
const rg4 = /^\.(.*)$/
const rg5 = /(\d*\.\d*)(\.)+/

const numberFieldStyle = { marginTop: '0.3rem' }
export class NumberField extends React.Component {
  static defaultProps = {
    format: Constants.DEFAULTS.NUMBER_FORMAT,
    hasComment: false,
    hasError: false,
  }

  constructor(props) {
    super(props)
    let format = this.props.format ? this.props.format : Constants.DEFAULTS.NUMBER_FORMAT
    let int = format.split('.')[0].length
    let dec = (format.split('.')[1] || '').length
    let placeholder = `${'_ '.repeat(int).trim()}${dec > 0 ? ' . ' + '_ '.repeat(dec).trim() : ''}`
    this.state = {
      value: this.props.replyValue !== null ? this.props.replyValue : '',
      int,
      dec,
      placeholder,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.replyValue !== this.props.replyValue && nextState.value === this.state.value) {
      this.setState({ value: nextProps.replyValue !== null ? nextProps.replyValue : '' })
    }
    return true
  }

  onChange = (e, { value = '' }) => {
    if (/^0\.\d+/.test(this.state.value) && /^\.\d+/.test(value)) {
      value = ''
    } else {
      value = value
        .toString()
        .replace(rg1, '.')
        .replace(rg2, '')
        .replace(rg3, '0')
        .replace(rg4, '0.$1')
        .replace(rg5, '$1')
      if (value.indexOf('.') === -1) {
        //INTEGER
        value = value.substring(0, this.state.int)
      } else {
        //FLOAT
        let parts = value.split('.')
        value = parts[0].substring(0, this.state.int)
        if (this.state.dec) value = `${value}.${(parts[1] || '').substring(0, this.state.dec)}`
      }
    }
    if (value !== this.state.value) {
      let n = value === '' ? null : isNaN(Number(value)) ? null : Number(value)
      if (n !== this.props.replyValue) {
        this.props.dispatch(handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, n))
      }
    }
    this.setState({ value })
  }

  onBlur = e => {
    let value = e.target.value
    let n = value === '' ? null : isNaN(Number(value)) ? null : Number(value)
    this.setState({ value: n !== null ? n.toString() : '' })
  }

  render() {
    let { labelWidth, inputWidth } = Constants.getDefaultFieldWidths(this.props.questionWidth)
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }

    const labelProps = {}
    const labelPosition = this.props.prefix !== '' ? 'left' : this.props.suffix !== '' ? 'right' : ''
    if (labelPosition !== '') {
      labelProps.labelPosition = labelPosition
    }

    const iw = (this.props.format || Constants.DEFAULTS.NUMBER_FORMAT).replace('.', '').length

    return (
      <React.Fragment>
        {this.props.label && (
          <Grid.Column
            className="multiple"
            {...Constants.getWidths(labelWidth)}
            style={{ ...numberFieldStyle, ...withToggleStyle }}>
            <span style={textStyle}>
              <HTMLText value={this.props.label} />
            </span>
            {this.props.hasComment === true && (
              <CommentItemField
                disabled={this.props.disabled}
                visible={this.props.visible}
                currentPageName={this.props.currentPageName}
                questionName={this.props.questionName}
                name={this.props.name + '_comment'}
                jsonref={this.props.jsonref + '_comment'}
              />
            )}
          </Grid.Column>
        )}
        <Grid.Column
          className={`multiple ${this.props.className ?? ''}`}
          {...Constants.getWidths(inputWidth)}
          style={withToggleStyle}>
          <Form.Input
            {...labelProps}
            {...(this.props.hasError ? { error: true } : {})}
            name={this.props.name}
            style={this.props.hasComment ? { marginTop: '1.35rem' } : {}}
            type="tel"
            className={`input-width-${iw}`}
            fluid
            maxLength="15"
            value={this.state.value}
            min={this.props.min}
            max={this.props.max}
            disabled={this.props.disabled || this.props.emptyComment === true}
            onChange={this.onChange}
            placeholder={this.state.placeholder}
            onBlur={this.onBlur}
            prefix={this.props.prefix}
            suffix={this.props.suffix}>
            {this.props.prefix !== '' && (
              <Label>
                <HTMLText value={this.props.prefix} />
              </Label>
            )}
            <input />
            {this.props.suffix !== '' && (
              <Label>
                <HTMLText value={this.props.suffix} />
              </Label>
            )}
          </Form.Input>
        </Grid.Column>
      </React.Fragment>
    )
  }
}
NumberField.displayName = 'NumberField'
