import * as React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import Loadable from 'react-loadable'

const ThemeCustomFallback = Loadable({
  loader: () => import('./theme-custom-fallback'),
  loading: () => null,
})

class ThemeCustom extends React.Component {
  state = {
    status: 'PENDING',
    css: '',
  }

  static defaultProps = {
    themeConfigs: {},
    cssUrl: 'index.css',
  }

  async componentDidMount() {
    try {
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: JSON.stringify(this.props.themeConfigs),
        url: this.props.cssUrl,
      }
      let response = await axios(options)
      this.setState({ status: 'SUCCESS', css: response.data })
    } catch (err) {
      this.setState({ status: 'ERROR', css: '' })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.status === 'SUCCESS' && (
          <Helmet>
            <style>{this.state.css}</style>
          </Helmet>
        )}
        {this.state.status === 'ERROR' && <ThemeCustomFallback />}
      </React.Fragment>
    )
  }
}

export default ThemeCustom
