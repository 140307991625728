import * as React from 'react'
import { Table } from 'semantic-ui-react'

import HTMLText from '../../system-wide/html-text'
import { CommentItemField } from '../../formfields/comment-field.js'
import withToggle from '../../../enhancers/toggleable'

const TableHeaderCell = Table.HeaderCell
const TableHeader = Table.Header
const TableRow = Table.Row

class _MatrixHeaderCell extends React.Component {
  static defaultProps = {
    hasComment: false,
  }

  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    const qName = `${this.props.questionName}_row_${this.props.itemKey}`
    return (
      <TableHeaderCell width={this.props.width} className={this.props.className} style={withToggleStyle}>
        <div style={textStyle}>
          <HTMLText style={{ textAlign: 'center', width: '100%', display: 'inline-block' }} value={this.props.value} />
        </div>
        {this.props.hasComment === true && (
          <CommentItemField
            label={this.props.value}
            name={`_rowcomment`}
            visible={this.props.visible}
            disabled={this.props.disabled}
            questionName={qName}
            currentPageName={this.props.currentPageName || ''}
            jsonref={`${this.props.currentPageName}.${qName}.comment_matrix`}
          />
        )}
      </TableHeaderCell>
    )
  }
}
_MatrixHeaderCell.displayName = '_MatrixHeaderCell'
const MatrixHeaderCell = withToggle(_MatrixHeaderCell)

const MatrixHeader = props => {
  let columns = []
  columns.push(<TableHeaderCell className="matrix left cell" key="0-0" />)

  const mod = props.isVertical ? 'row' : 'col'
  const tableId = `#table-${props.currentPageName}-${props.questionName}`

  let colWidth = 6.5
  let className = 'mrating-as-select'

  if (props.renderAs === 'rating') {
    colWidth = props.maxRating * 2
    className = 'mrating-as-rating'
  }
  if (props.renderAs === 'score') {
    colWidth = Math.max(11, props.maxRating * 1.1)
    className = 'mrating-as-score'
  }

  props.elements.forEach(elem => {
    let jsonref = `${props.currentPageName}.${props.questionName}_${mod}_${elem.key}`
    columns.push(
      <MatrixHeaderCell
        className="matrix cell"
        key={elem.key}
        itemKey={elem.key}
        value={elem.value}
        jsonref={jsonref}
        parentDisabled={props.parentDisabled}
        currentPageName={props.currentPageName}
        questionName={props.questionName}
        hasComment={elem.has_comment ? elem.has_comment : false}
      />
    )
  })
  return (
    <React.Fragment>
      <style>{`${tableId} th:not(:first-child) { min-width: ${colWidth}rem;}`}</style>
      <TableHeader className={className}>
        <TableRow>{columns}</TableRow>
      </TableHeader>
    </React.Fragment>
  )
}

export default MatrixHeader
