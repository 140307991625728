import { handleRankingDataChange } from '../../../store/survey-data'

const onlyItems = k => !/(__qtype|_comment|_rowcomment|_label|skipped)$/.test(k)

export const prepareData = props => {
  let newData = {}
  Object.keys(props.data).forEach(k => {
    switch (true) {
      case k === '__qtype':
      case k === 'skipped':
      case k === 'skipped_label':
        newData[k] = props.data[k]
        break

      case /_comment|_rowcomment/.test(k):
        newData[k] = props.data[k]
        break

      case /_label/.test(k):
        newData[k] = ''
        break

      default:
        newData[k] = null
    }
  })
  return newData
}

export const onToggleablesChange = props => {
  //RIORDINA GLI ELEMENTI IN BASE AI TOGGLEABLES
  let inactive = []
  Object.keys(props.toggleables).forEach(k => {
    if (props.toggleables[k] === false) {
      inactive.push(k)
    }
  })
  let newData = prepareData(props)
  let i = 1
  Object.keys(props.data)
    .filter(onlyItems)
    .forEach(k => {
      if (inactive.indexOf(k) !== -1 && typeof props.data[`${k}_comment`] !== 'undefined') {
        newData[`${k}_comment`] = ''
      }

      if (inactive.indexOf(props.data[k]) !== -1) {
        newData[k] = null
        newData[`${k}_label`] = ''
        // if (typeof props.data[`${props.data[k]}_comment`] !== 'undefined') newData[`${props.data[k]}_comment`] = ''
      } else if (props.data[k] !== null) {
        newData[i] = props.data[k]
        newData[`${i}_label`] = props.data[`${k}_label`]
        if (typeof props.data[`${props.data[k]}_comment`] !== 'undefined')
          newData[`${props.data[k]}_comment`] = props.data[`${props.data[k]}_comment`]
        i++
      }
    })
  props.dispatch(handleRankingDataChange(props.currentPageName, props.questionName, newData))
}

export const onMaxRepsChanged = props => {
  let newData = { ...props.data }
  Object.keys(newData)
    .filter(onlyItems)
    .forEach(k => {
      if (parseInt(k) > parseInt(props.max)) {
        newData[k] = null
        newData[`${k}_label`] = ''
      }
    })
  props.dispatch(handleRankingDataChange(props.currentPageName, props.questionName, newData))
}
export const handleChange = function(e, { name, value, isComment }) {
  try {
    let newData
    if (isComment === true) {
      newData = { ...this.props.data }
      newData[`${name}_comment`] = value
      if (value === '') {
        let index = Object.keys(newData)
          .filter(onlyItems)
          .find(k => newData[k] === name)
        if (typeof index !== 'undefined') {
          newData[index] = null
          newData[`${index}_label`] = ''
          let i = 1
          Object.keys(newData)
            .filter(onlyItems)
            .forEach(k => {
              if (newData[k] !== null) {
                newData[i] = this.props.data[k]
                newData[`${i}_label`] = this.props.data[`${k}_label`]
                i++
              }
            })
        }
      }
    } else {
      newData = prepareData(this.props)
      Object.keys(this.props.data)
        .filter(onlyItems)
        .forEach(k => {
          if (k === value) {
            newData[k] = name
            newData[`${k}_label`] = this.props.items.filter(i => i.key === name)[0].value
          } else if (this.props.data[k] === name) {
            newData[k] = null
            newData[`${k}_label`] = ''
          } else {
            newData[k] = this.props.data[k]
            newData[`${k}_label`] = this.props.data[`${k}_label`]
          }
        })

      // svuoto il comment con max_reps raggiunto
      let checked = Object.keys(newData)
        .filter(onlyItems)
        .filter(key => newData[key] !== null).length

      if (checked === parseInt(this.props.max)) {
        let values = Object.keys(newData)
          .filter(onlyItems)
          .reduce((acc, k) => {
            return [...acc, newData[k]]
          }, [])
        Object.keys(newData)
          .filter(k => /(_comment)/.test(k))
          .forEach(i => {
            if (values.indexOf(i.split('_')[0]) === -1) {
              newData[i] = ''
            }
          })
      }
    }

    this.props.dispatch(handleRankingDataChange(this.props.currentPageName, this.props.questionName, newData))
  } catch (e) {
    this.setState(() => {
      throw e
    })
  }
}
