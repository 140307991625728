import * as React from 'react'
import { Grid, Form } from 'semantic-ui-react'

import { handleDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import HTMLText from '../system-wide/html-text'
import DebounceInput from './utils/debounce-input'
import { CommentItemField } from './comment-field'

const textAreaStyle = { marginTop: '0.3rem' }
export class TextareaField extends React.PureComponent {
  static defaultProps = {
    hasComment: false,
  }

  handleChange = e => {
    try {
      this.props.dispatch(
        handleDataChange(this.props.currentPageName, this.props.questionName, this.props.name, e.target.value)
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    return (
      <React.Fragment>
        {this.props.label && (
          <Grid.Column
            className="multiple"
            {...Constants.getWidths(Constants.LABEL.FULLWIDTH + Constants.INPUT.FULLWIDTH)}
            style={{ ...textAreaStyle, ...withToggleStyle }}>
            <span style={textStyle}>
              <HTMLText value={this.props.label} />
            </span>
            {this.props.hasComment === true && (
              <CommentItemField
                disabled={this.props.disabled}
                visible={this.props.visible}
                currentPageName={this.props.currentPageName}
                questionName={this.props.questionName}
                name={this.props.name + '_comment'}
                jsonref={this.props.jsonref + '_comment'}
              />
            )}
          </Grid.Column>
        )}
        <Grid.Column
          className="multiple"
          {...Constants.getWidths(Constants.LABEL.FULLWIDTH + Constants.INPUT.FULLWIDTH)}
          style={withToggleStyle}>
          <DebounceInput
            debounceTimeout={Constants.DEBOUNCE_TIMEOUT}
            element={Form.TextArea}
            name={this.props.name}
            value={this.props.replyValue}
            {...(this.props.hasError ? { error: true } : {})}
            rows={3}
            maxLength={this.props.maxlength}
            disabled={this.props.disabled || this.props.emptyComment === true}
            onChange={this.handleChange}
          />
        </Grid.Column>
      </React.Fragment>
    )
  }
}
TextareaField.displayName = 'TextareaField'
