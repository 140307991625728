import * as React from 'react'
import HTMLText from '../system-wide/html-text'

class Divider extends React.PureComponent {
  render() {
    let horizontal = this.props.value ? 'horizontal' : ''
    return (
      <div
        className={`ui ${horizontal} field divider ${this.props.className || ''} ${
          this.props.disabled ? 'disabled' : ''
        }`}>
        <HTMLText value={this.props.value || ''} />
      </div>
    )
  }
}

export default Divider
