import * as React from 'react'
import { Message } from 'semantic-ui-react'

import HTMLText from './html-text'

import './error-message.css'

const MessageHeader = Message.Header
const MessageList = Message.List
const MessageItem = Message.Item

const ErrorMessage = props => {
  return (
    <Message error className={props.className || ''}>
      <MessageHeader>{props.title || ''}</MessageHeader>
      <MessageList style={{ marginTop: '.2rem' }}>
        {props.errors.map((e, i) => (
          <MessageItem key={`item-${i}`}>
            <HTMLText value={e} />
          </MessageItem>
        ))}
      </MessageList>
    </Message>
  )
}

export default ErrorMessage
