import * as React from 'react'
import { Table } from 'semantic-ui-react'

import MatrixVerticalRow from './vrow'
import { MatrixRow, MatrixRowWithComment } from './row'

const TableBody = Table.Body

const MatrixBody = props => {
  let tableRows = []
  if (props.isVertical) {
    props.childQuestions[0].items[0].values.forEach((v, i) => {
      let cells = []
      props.childQuestions.forEach(q => {
        cells.push({ cell: q.items[0].values[i], hasComment: q.has_comment })
      })
      tableRows.push(
        <MatrixVerticalRow
          key={i}
          qtext={v.value}
          cells={cells}
          parentDisabled={props.parentDisabled}
          questionName={props.questionName}
          currentPageName={props.currentPageName}
          group={props.questionName}
          isVertical={props.isVertical}
          jsonref={`${props.currentPageName}.${props.questionName}_col_${v.key}`}
        />
      )
    })
  } else {
    props.childQuestions.forEach(q => {
      let Row = q.has_comment ? MatrixRowWithComment : MatrixRow
      tableRows.push(
        <Row
          key={q.name}
          qtext={q.qtext[0].value}
          items={q.items}
          parentDisabled={props.parentDisabled}
          questionName={q.name}
          currentPageName={props.currentPageName}
          group={props.questionName}
          isVertical={props.isVertical}
          jsonref={q.jsonref}
          hasComment={q.has_comment}
        />
      )
    })
  }

  return <TableBody>{tableRows}</TableBody>
}

export default MatrixBody
