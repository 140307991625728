import * as React from 'react'
import { Grid } from 'semantic-ui-react'

import Constants from '../../utils/constants'
import withSkippable from '../../enhancers/skippable'
import { VideoField } from '../formfields'
import Interpolator from '../../utils/interpolator'

let interpolator = new Interpolator()

class VideoQuestionBody extends React.PureComponent {
  static defaultProps = {
    options: {},
  }

  render() {
    let source = interpolator.render(this.props.options.src)
    let videos = this.props.items.map(r => {
      return (
        <Grid.Row key={r.key} className="resurvey question body">
          <VideoField
            name={r.key}
            key={`item-${r.jsonref}`}
            questionName={this.props.name}
            questionWidth={this.props.options.width || Constants.FULLWIDTH}
            label={r.value}
            parentDisabled={this.props.disabled}
            jsonref={r.jsonref}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped}
            src={source}
            minWatch={this.props.options.min_watch.value}
          />
        </Grid.Row>
      )
    })

    if (this.props.reverseItems) videos.reverse()
    return videos
  }
}

export default withSkippable(VideoQuestionBody)
