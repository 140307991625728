import * as React from 'react'
import { Form } from 'semantic-ui-react'

import { handleDataChange, handleItemCommentDataChange } from '../../store/survey-data'
import Constants from '../../utils/constants'
import DebounceInput from './utils/debounce-input'
import connectField from '../../enhancers/connect-field'
import withToggle from '../../enhancers/toggleable'

const commentStyle = { marginBottom: '.75rem' }
export class UnconnectedCommentField extends React.PureComponent {
  static defaultProps = {
    handleDataChangeCallback: handleDataChange,
  }
  handleChange = e => {
    try {
      this.props.dispatch(
        this.props.handleDataChangeCallback(
          this.props.currentPageName,
          this.props.questionName,
          this.props.name,
          e.target.value
        )
      )
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    return (
      <DebounceInput
        debounceTimeout={Constants.DEBOUNCE_TIMEOUT}
        style={{ ...commentStyle, ...withToggleStyle, ...this.props.style }}
        element={Form.Input}
        {...(this.props.hasError ? { error: true } : {})}
        fluid
        name={this.props.name}
        value={this.props.replyValue}
        maxLength={80}
        disabled={this.props.disabled}
        onChange={this.handleChange}
      />
    )
  }
}

const commentItem = OriginalComponent => props => (
  <OriginalComponent {...props} handleDataChangeCallback={handleItemCommentDataChange} />
)

export const CommentField = withToggle(connectField(UnconnectedCommentField), 'ReplyItem')
export const CommentItemField = commentItem(connectField(UnconnectedCommentField))
