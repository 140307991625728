import * as React from 'react'
import { Table } from 'semantic-ui-react'
import get from 'lodash.get'

import MatrixHeader from './header'
import MatrixBody from './body'

const Matrix = props => {
  const skipped = props.questionSkipped || false
  const tableStyle = props.disabled || skipped ? { opacity: '.55' } : {}
  const max_reps = get(props, ['options', 'max_reps', 'value'], props.items[0].values.length.toString())
  const min_reps = get(props, ['options', 'min_reps', 'value'], '1')
  return (
    <Table
      id={`table-${props.currentPageName}-${props.name}`}
      definition
      unstackable
      size="small"
      style={tableStyle}
      textAlign="center">
      <MatrixHeader
        elements={props.items[0].values || []}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        maxReps={max_reps}
        minReps={min_reps}
        isVertical={false}
      />
      <MatrixBody
        childQuestions={props.childQuestions || []}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        maxReps={max_reps}
        minReps={min_reps}
        isVertical={false}
      />
    </Table>
  )
}

export default Matrix
