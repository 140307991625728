import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import range from 'lodash.range'
import { _NativeSelect as NativeSelect } from '../../formfields/ranking-field'
import {
  prepareData,
  onToggleablesChange,
  onMaxRepsChanged,
  handleChange,
} from '../../formfields/ranking-field/helpers'
import { handleRankingDataChange } from '../../../store/survey-data'
import HTMLText from '../../system-wide/html-text'
import withToggle from '../../../enhancers/toggleable'
import MatrixCell from './cell.js'
import Interpolator from '../../../utils/interpolator'

const mapStateToProps = (state, props) => {
  let qName = props.jsonref.split('.')[1]
  let data = state.data[props.currentPageName][qName]
  let toggleables = state.toggleables[props.currentPageName][qName]['repliesStatus']
  let enabledItems = Object.keys(toggleables).filter(key => toggleables[key] === true).length

  let interpolator = new Interpolator()
  let max_reps = typeof props.maxReps !== 'undefined' ? interpolator.render(props.maxReps) : false

  let max = max_reps && max_reps <= enabledItems ? max_reps : enabledItems
  let parentDisabled = props.parentDisabled
  let disabled = props.disabled || enabledItems === 0
  let questionStatus = state['validators']['questionStatuses'][props.currentPageName][qName]
  let errors = state['validators']['errorList'][props.currentPageName][qName]
  let hasError = errors.indexOf(props.currentPageName + '.' + qName) !== -1 && questionStatus === 'INVALID'

  let items = props.items
  let value = Object.keys(data).find(k => data[k] === props.itemKey) || ''
  let rowComment = props.has_comment ? state.data[props.currentPageName][qName]['_rowcomment'] || '' : undefined

  return {
    data,
    items,
    toggleables,
    enabledItems,
    max,
    parentDisabled,
    disabled,
    hasError,
    value,
    questionName: qName,
    rowComment,
  }
}

class _RankingSelect extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = handleChange.bind(this)
  }

  componentDidUpdate(oldProps) {
    if (this.props.enabledItems < oldProps.enabledItems) {
      onToggleablesChange(this.props)
    } else if (this.props.enabledItems === oldProps.enabledItems && parseInt(oldProps.max) > parseInt(this.props.max)) {
      // remove last replies if max_reps is changed due to interpolation
      onMaxRepsChanged(this.props)
    } else if (this.props.rowComment === '' && oldProps.rowComment !== '') {
      let newData = prepareData(this.props)
      this.props.dispatch(handleRankingDataChange(this.props.currentPageName, this.props.questionName, newData))
    }
  }

  render() {
    return (
      <NativeSelect
        {...this.props}
        disabled={this.props.disabled || this.props.rowComment === ''}
        parentDisabled={this.props.rowComment === ''}
        onChange={this.handleChange}
        options={range(1, parseInt(this.props.max) + 1)}
      />
    )
  }
}
const RankingSelect = connect(mapStateToProps)(_RankingSelect)

const TableRow = Table.Row
const TableCell = Table.Cell

const cla = { textAlign: 'left' }

class _MatrixVerticalRow extends React.PureComponent {
  render() {
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    let columns = []
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    columns.push(
      <TableCell key={'vrow-0'} style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.qtext} />
        </span>
      </TableCell>
    )

    this.props.cells.forEach(cellObject => {
      let cell = cellObject.cell
      let qName = cell.jsonref.split('.')[1]

      const itemKey = qName.split('_row_')[1]
      const valueKey = cell.key
      const cellKey = `cell-${itemKey}-${valueKey}`

      const cbProps = {
        itemKey: cell.key,
        label: cell.value,
        name: cell.key,
        key: cell.key,
        jsonref: cell.jsonref,
        items: cellObject.items,
        fluid: true,
        questionName: qName,
        currentPageName: this.props.currentPageName,
        className: `matrix-cell ${cellKey}`,
        labelClassName: 'hidden-label',
        onChange: this.handleChange,
        has_comment: cellObject.has_comment,
        placeholder: '---',
        questionSkipped: this.props.questionSkipped,
        questionWidth: this.props.questionWidth,
        questionDisabled: this.props.disabled,
        maxReps: this.props.maxReps,
      }

      let childQuestionJsonrefArr = cell.jsonref.split('.')
      childQuestionJsonrefArr.pop()
      let childQuestionJsonref = childQuestionJsonrefArr.join('.')

      columns.push(
        <MatrixCell
          key={`item_${cell.jsonref}`}
          jsonref={childQuestionJsonref}
          questionName={qName}
          currentPageName={this.props.currentPageName}
          component={RankingSelect}
          childProps={cbProps}
        />
      )
    })

    return <TableRow style={withToggleStyle}>{columns}</TableRow>
  }
}
_MatrixVerticalRow.displayName = '_MatrixVerticalRow'

export default withToggle(_MatrixVerticalRow)
