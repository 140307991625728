import * as React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Grid } from 'semantic-ui-react'

import Constants from '../../utils/constants'
import Interpolator from '../../utils/interpolator'
import strings from '../../locales'
import withSkippable from '../../enhancers/skippable'
import { SearchField } from '../formfields'

const interpolator = new Interpolator()

const mapStateToProps = (state, ownProps) => {
  let interpolations = []
  if (typeof ownProps.options.filter !== 'undefined') {
    interpolations = ownProps.options.filter
      .split(',')
      .map(item => {
        return interpolator.render(item.trim())
      })
      .filter(v => v !== '...')
  }
  return {
    field: ownProps.values[0].value,
    interpolations,
    mode: state.internalState.mode,
  }
}
export class SearchQuestionBody extends React.Component {
  static defaultProps = {
    items: [],
    options: {},
  }

  state = {
    values: null,
    origData: [],
    interpolationValues: [],
    initialized: false,
  }

  async componentDidMount() {
    let url = interpolator.render(this.props.options.src)
    let tks = url.split('/')

    try {
      if (tks[0] !== '' || tks[1] !== 'media' || tks[2] === '') {
        this.throwError(`SEARCH: ${strings.error_invalid_src}`)
      }

      let data = []
      if (this.props.mode === 'test') {
        // generate mock data
        for (let i = 1; i <= 100; i++) {
          data.push({ id: `${i}`, [this.props.field]: `${this.props.field}_${i}` })
        }
      } else {
        let response = await axios({
          method: 'post',
          url: `/resources/${url}`,
          data: { fields: [this.props.field] },
          validateStatus: null,
        })
        if (response.status !== 200) {
          this.throwError(`SEARCH: ${strings[response.data.status] || strings.network_error_msg}`)
        }
        data = response.data
      }

      if (!data.length) this.throwError(`SEARCH: ${strings.error_empty_datafile}`)

      // transform data to suit semantic-ui needs ({id: <unique>, title: ...})
      // id needs to be unique, so its value is saved indo 'code'
      data = data.map((el, i) => {
        el.title = el[this.props.field]
        el.code = el.id
        el.id = i
        delete el[this.props.field]
        return el
      })

      this.applyFilters(data)
      this.setState({ initialized: true })
    } catch (e) {
      this.throwError(`SEARCH: ${strings.network_error_msg}`)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.interpolations.toString() !== prevProps.interpolations.toString()) {
      this.applyFilters()
    }
  }

  applyFilters = (origData = this.state.origData) => {
    // filter by interpolated citations
    let newValues = origData
    let interpolationValues = []
    if (this.props.interpolations.length !== 0) {
      interpolationValues = origData.filter(el => this.props.interpolations.indexOf(el.code) !== -1)
      newValues = origData.filter(el => this.props.interpolations.indexOf(el.code) === -1)
    }
    this.setState({ origData, values: newValues, interpolationValues })
  }

  throwError = msg => {
    this.setState(() => {
      throw new Error(msg)
    })
  }

  render() {
    let searches = this.props.items.map(r => {
      return (
        <Grid.Row key={r.key} className="resurvey question body">
          <SearchField
            name={r.key}
            key={`item-${r.jsonref}`}
            questionName={this.props.name}
            questionWidth={this.props.options.width || Constants.FULLWIDTH}
            label={r.value}
            parentDisabled={this.props.disabled}
            jsonref={r.jsonref}
            values={this.state.values}
            interpolationValues={this.state.interpolationValues}
            interpolationIds={this.props.interpolations}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped}
            notFound={this.props.options.notfound}
            filter={this.props.options.filter}
          />
        </Grid.Row>
      )
    })

    if (this.props.reverseItems) searches.reverse()
    return <>{searches}</>
  }
}

export default withSkippable(connect(mapStateToProps)(SearchQuestionBody))
