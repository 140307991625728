import * as React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'semantic-ui-react'
import range from 'lodash.range'
import get from 'lodash.get'
import withToggle from '../../../enhancers/toggleable'
import { CommentItemField } from '../../formfields/comment-field'
import { _NativeSelect as RankingSelect } from '../../formfields/ranking-field'
import {
  prepareData,
  onToggleablesChange,
  onMaxRepsChanged,
  handleChange,
} from '../../formfields/ranking-field/helpers'
import { handleRankingDataChange } from '../../../store/survey-data'
import Interpolator from '../../../utils/interpolator'
import Qtext from '../../qtext.js'

const RankingField = withToggle(RankingSelect)

const mstp = (state, props) => {
  let data = state.data[props.currentPageName][props.name]
  let toggleables = state.toggleables[props.currentPageName][props.name]['repliesStatus']
  let enabledItems = Object.keys(toggleables).filter(key => toggleables[key] === true).length

  let interpolator = new Interpolator()
  let max_reps = typeof props.maxReps !== 'undefined' ? interpolator.render(props.maxReps) : false
  let max = max_reps && max_reps <= enabledItems ? max_reps : enabledItems

  let parentDisabled = props.parentDisabled
  let disabled = props.disabled || enabledItems === 0
  let questionStatus = state['validators']['questionStatuses'][props.currentPageName][props.name]
  let errors = state['validators']['errorList'][props.currentPageName][props.name]
  let hasError = errors.indexOf(props.jsonref) !== -1 && questionStatus === 'INVALID'

  let rowComment = props.hasComment ? state.data[props.currentPageName][props.name]['_rowcomment'] || '' : undefined

  return {
    data,
    items: props.items,
    enabledItems,
    max,
    disabled,
    parentDisabled,
    hasError,
    toggleables,
    questionName: props.name,
    rowComment,
  }
}

class _List extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = handleChange.bind(this)
  }

  componentDidUpdate(oldProps) {
    if (this.props.enabledItems < oldProps.enabledItems) {
      onToggleablesChange(this.props)
    } else if (this.props.enabledItems === oldProps.enabledItems && parseInt(oldProps.max) > parseInt(this.props.max)) {
      // remove last replies if max_reps is changed due to interpolation
      onMaxRepsChanged(this.props)
    } else if (this.props.rowComment === '' && oldProps.rowComment !== '') {
      let newData = prepareData(this.props)
      this.props.dispatch(handleRankingDataChange(this.props.currentPageName, this.props.questionName, newData))
    }
  }

  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const rowKey = this.props.name.split('_row_')[1]

    return (
      <Grid.Row style={withToggleStyle}>
        {this.props.items.map(item => (
          <RankingField
            key={`item-${item.jsonref}`}
            label={item.value}
            name={item.key}
            parentDisabled={this.props.parentDisabled || this.props.disabled || this.props.rowComment === ''}
            jsonref={item.jsonref}
            questionName={this.props.name}
            currentPageName={this.props.currentPageName}
            questionSkipped={this.props.questionSkipped || false}
            fluid={true}
            disabled={this.props.disabled}
            className={`matrix-cell cell-${rowKey}-${item.key}`}
            options={range(1, parseInt(this.props.max) + 1)}
            onChange={this.handleChange}
            hasError={item.hasError}
            value={Object.keys(this.props.data).find(k => this.props.data[k] === item.key) || ''}
            placeholder={'---'}
          />
        ))}
      </Grid.Row>
    )
  }
}
_List.displayName = '_List'

const RankingList = withToggle(connect(mstp)(_List))

const MobileBody = props => {
  let childQuestions = props.childQuestions || []
  const max_reps = get(props, ['options', 'max_reps', 'value'], props.items[0].values.length.toString())
  const min_reps = get(props, ['options', 'min_reps', 'value'], '1')

  return childQuestions.map((q, i) => {
    const childProps = {
      key: `item-${i}`,
      name: q.name,
      group: props.name,
      parentDisabled: props.questionSkipped || props.disabled,
      currentPageName: props.currentPageName,
      jsonref: q.jsonref,
      items: q.items,
      maxReps: max_reps,
      minReps: min_reps,
      qtext: q.qtext[0],
      hasComment: typeof q.has_comment !== 'undefined',
      withToggleStyle: props.visible ? {} : { display: 'none' },
    }
    return <MobileMRankingItem {...childProps} />
  })
}

const MobileMRankingItem = withToggle(props => {
  let { qtext, hasComment, withToggleStyle, ...listProps } = props

  return (
    <React.Fragment key={props.name}>
      <Grid.Row style={withToggleStyle}>
        <Grid.Column>
          <Qtext {...qtext} {...props} />
          {hasComment === true && (
            <CommentItemField
              label=""
              disabled={props.questionSkipped || props.disabled}
              visible={props.visible}
              name={`_rowcomment`}
              questionName={props.name}
              currentPageName={props.currentPageName}
              jsonref={`${props.currentPageName}.${props.name}.comment_matrix`}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <RankingList {...listProps} hasComment={hasComment} />
    </React.Fragment>
  )
})

export default MobileBody
