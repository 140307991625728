import * as React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import { TextField as _TextField } from '../../formfields/text-field'
import HTMLText from '../../system-wide/html-text'
import withToggle from '../../../enhancers/toggleable'
import connectField from '../../../enhancers/connect-field'
import MatrixCell from '../matrix/cell'
import Constants from '../../../utils/constants'

const mstp = (state, props) => {
  return { rowComment: state.data[props.currentPageName][props.jsonref.split('.')[1]]['_rowcomment'] }
}
const MatrixCellWithComment = connect(mstp)(MatrixCell)
const TextField = connectField(_TextField)
const TableRow = Table.Row
const TableCell = Table.Cell

const cla = { textAlign: 'left' }

class _MatrixVerticalRow extends React.PureComponent {
  render() {
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }
    const textStyle = { opacity: this.props.disabled ? '0.45' : '1' }
    let columns = []
    columns.push(
      <TableCell key={'vrow-0'} style={cla}>
        <span style={textStyle}>
          <HTMLText value={this.props.qtext} />
        </span>
      </TableCell>
    )
    this.props.cells.forEach(cellObject => {
      let cell = cellObject.cell
      let qName = cell.jsonref.split('.')[1]

      const itemKey = qName.split('_row_')[1]
      const valueKey = cell.key
      const cellKey = `cell-${itemKey}-${valueKey}`

      const cbProps = {
        itemKey: cell.key,
        name: cell.key,
        jsonref: cell.jsonref,
        disabled: this.props.disabled,
        questionName: qName,
        currentPageName: this.props.currentPageName,
        className: `matrix-cell ${cellKey}`,
        labelClassName: 'hidden-label',
        maxlength: this.props.options.maxlength || Constants.DEFAULTS.TEXT_MAX_LENGTH,
      }
      let childQuestionJsonrefArr = cell.jsonref.split('.')
      childQuestionJsonrefArr.pop()
      let childQuestionJsonref = childQuestionJsonrefArr.join('.')
      let Cell = cellObject.hasComment ? MatrixCellWithComment : MatrixCell
      columns.push(
        <Cell
          key={`item_${cell.jsonref}`}
          jsonref={childQuestionJsonref}
          parentDisabled={this.props.disabled}
          questionName={qName}
          currentPageName={this.props.currentPageName}
          component={TextField}
          childProps={cbProps}
        />
      )
    })

    return <TableRow style={withToggleStyle}>{columns}</TableRow>
  }
}
_MatrixVerticalRow.displayName = '_MatrixVerticalRow'

export default withToggle(_MatrixVerticalRow)
