import * as React from 'react'
import { Table } from 'semantic-ui-react'

import MatrixVerticalRow from './vrow'
import { MatrixRow, MatrixRowWithComment } from './row'

const TableBody = Table.Body

const MatrixBody = props => {
  let tableRows = []
  if (props.isVertical) {
    props.childQuestions[0].items.forEach((item, i) => {
      let cells = []
      props.childQuestions.forEach(q => {
        cells.push({ cell: q.items[i], items: q.items, has_comment: q.has_comment })
      })
      tableRows.push(
        <MatrixVerticalRow
          key={i}
          qtext={item.value}
          cells={cells}
          group={props.questionName}
          parentDisabled={props.parentDisabled}
          questionName={props.questionName}
          currentPageName={props.currentPageName}
          isVertical={props.isVertical}
          jsonref={`${props.currentPageName}.${props.questionName}_col_${item.key}`}
          maxReps={props.maxReps}
          minReps={props.minReps}
        />
      )
    })
  } else {
    props.childQuestions.forEach(q => {
      let Row = q.has_comment ? MatrixRowWithComment : MatrixRow
      tableRows.push(
        <Row
          key={q.name}
          qtext={q.qtext[0].value}
          items={q.items}
          group={props.questionName}
          parentDisabled={props.parentDisabled}
          questionName={q.name}
          currentPageName={props.currentPageName}
          isVertical={props.isVertical}
          jsonref={q.jsonref}
          maxReps={props.maxReps}
          minReps={props.minReps}
          hasComment={q.has_comment}
        />
      )
    })
  }
  return <TableBody>{tableRows}</TableBody>
}

export default MatrixBody
