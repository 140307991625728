import * as React from 'react'
import { Table } from 'semantic-ui-react'

import MatrixHeader from './header'
import MatrixBody from './body'

const Matrix = props => {
  const skipped = props.questionSkipped || false
  const tableStyle = props.disabled || skipped ? { opacity: '.55' } : {}
  return (
    <Table
      id={`table-${props.currentPageName}-${props.name}`}
      definition
      unstackable
      size="small"
      style={tableStyle}
      textAlign="center">
      <MatrixHeader
        elements={props.items[0].values || []}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        isVertical={false}
      />
      <MatrixBody
        childQuestions={props.childQuestions || []}
        parentDisabled={props.disabled || skipped}
        questionName={props.name}
        currentPageName={props.currentPageName}
        isVertical={false}
        options={props.options}
      />
    </Table>
  )
}

export default Matrix
