import * as React from 'react'
import './loader.css'

const style = { backgroundColor: 'rgba(255,255,255,.75)' }
const fixed = { position: 'fixed', top: '40%', left: 0, textAlign: 'center', width: '100%' }

const Dimmer = props => {
  if (props.active) {
    return (
      <div className="full-page-loader-container" style={style}>
        <div style={fixed}>{props.children}</div>
      </div>
    )
  }
  return null
}

export default Dimmer
