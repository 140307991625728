import * as React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { Grid, Header } from 'semantic-ui-react'

import Constants from '../utils/constants'
import { QtextList } from './qtext'
import { getQuestionBody } from './questions'
import ErrorMessage from './system-wide/error-message'
import generateValidator from './validator'
import MetaMatrix from './questions/matrix/meta'
import MetaMMatrix from './questions/mmatrix/meta'
import MetaMRating from './questions/mrating/meta'
import MetaMNumber from './questions/mnumber/meta'
import MetaMText from './questions/mtext/meta'
import MetaMRanking from './questions/mranking/meta'
import MetaMSelect from './questions/mselect/meta'
import Interpolator from '../utils/interpolator'

import withToggle from '../enhancers/toggleable'
import { isFirstInvalidQuestion } from '../utils/validation-helpers'

import './question.css'

export class Question extends React.Component {
  errorMessageRef = { current: null }

  constructor(props) {
    super(props)
    this.errorMessageRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPageSubmitted === false && this.props.isPageSubmitted === true) {
      let elem = ReactDOM.findDOMNode(this.errorMessageRef.current)
      if (elem && isFirstInvalidQuestion(this.props.currentPageName, this.props.name)) {
        elem.scrollIntoView()
      }
    }
  }

  render() {
    let width =
      typeof this.props.options !== 'undefined' && typeof this.props.options.width !== 'undefined'
        ? this.props.options.width
        : Constants.FULLWIDTH
    let QuestionBody = getQuestionBody(this.props.type)
    let showQuestionName = this.props.type !== 'info' && this.props.showQuestionNames
    let endPadding = this.props.type === 'end' ? { marginTop: '1rem' } : {}
    const withToggleStyle = this.props.visible ? {} : { display: 'none' }

    if (this.props.type === 'geolocation') {
      return <QuestionBody {...this.props} />
    }

    return (
      <Grid.Column
        id={`${this.props.currentPageName}-${this.props.name}`}
        style={withToggleStyle}
        className={`resurvey question ${this.props.type === 'info' ? 'info' : ''}`}
        {...Constants.getWidths(width)}>
        <Grid padded>
          {showQuestionName === true && (
            <Grid.Row style={{ marginBottom: '-.5rem' }}>
              <Grid.Column mobile={Constants.FULLWIDTH}>
                <Header as="h3" className={this.props.disabled ? 'disabled' : ''}>
                  {this.props.name}
                </Header>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row style={endPadding}>
            <Grid.Column mobile={Constants.FULLWIDTH} disabled={this.props.disabled}>
              <span ref={this.errorMessageRef} />
              <QtextList
                items={this.props.qtext}
                currentPageName={this.props.currentPageName}
                parentDisabled={this.props.disabled}
              />
            </Grid.Column>
          </Grid.Row>
          {this.props.isPageSubmitted && this.props.validationErrors.length !== 0 && (
            <Grid.Row>
              <Grid.Column mobile={Constants.FULLWIDTH}>
                <ErrorMessage className="noborder" errors={this.props.validationErrors} />
              </Grid.Column>
            </Grid.Row>
          )}
          <QuestionBody {...this.props} width={width} />
        </Grid>
      </Grid.Column>
    )
  }
}

const ToggleableQuestion = withToggle(Question, 'Question')

const interpolator = new Interpolator()
const noErrors = []
const mapStateToProps = (state, ownProps) => {
  if (
    ownProps.type === 'mmatrix' ||
    ownProps.type === 'mrating' ||
    ownProps.type === 'mranking' ||
    ownProps.type === 'matrix' ||
    ownProps.type === 'mnumber' ||
    ownProps.type === 'mtext' ||
    ownProps.type === 'mselect'
  ) {
    let childQuestions = ownProps.childQuestions || []
    let validationErrors = childQuestions.reduce(
      (acc, q) => [...acc, ...state.validators.errors[ownProps.currentPageName][q.name]],
      []
    )
    let isPageSubmitted = state.validators.pageStatuses[ownProps.currentPageName] === 'SUBMITTED'
    return {
      isPageSubmitted,
      validationErrors: isPageSubmitted ? [...new Set(validationErrors)] : noErrors,
      reverseItems: false, //TODO
    }
  } else {
    let reverseItems = false
    let reverseItemsValue = ownProps?.options?.reverse_items
    if (Interpolator.needInterpolation(reverseItemsValue)) {
      reverseItems = interpolator.interpolate(reverseItemsValue) === 'true'
    } else {
      reverseItems = reverseItemsValue === 'true'
    }

    return {
      isPageSubmitted: state.validators.pageStatuses[ownProps.currentPageName] === 'SUBMITTED',
      validationErrors: state.validators.errors[ownProps.currentPageName][ownProps.name],
      reverseItems,
    }
  }
}

export class ValidatedQuestion extends React.PureComponent {
  constructor(props) {
    super(props)
    switch (props.type) {
      case 'mmatrix':
      case 'mrating':
      case 'mtext':
      case 'matrix':
      case 'mnumber':
      case 'mranking':
      case 'mselect':
        let childQuestions = props.childQuestions || []
        childQuestions.forEach(q => {
          const Validator = generateValidator(props.currentPageName, q.name, q.group)
          this.Validators.push(<Validator key={`validator_${props.currentPageName}_${q.name}`} />)
        })
        break
      default:
        const Validator = generateValidator(props.currentPageName, props.name)
        this.Validators.push(<Validator key={`validator_${props.currentPageName}_${props.name}`} />)
    }
  }
  Validators = []

  render() {
    return (
      <React.Fragment>
        {this.Validators}
        {this.props.type === 'matrix' && <MetaMatrix {...this.props} />}
        {this.props.type === 'mmatrix' && <MetaMMatrix {...this.props} />}
        {this.props.type === 'mrating' && <MetaMRating {...this.props} />}
        {this.props.type === 'mnumber' && <MetaMNumber {...this.props} />}
        {this.props.type === 'mtext' && <MetaMText {...this.props} />}
        {this.props.type === 'mranking' && <MetaMRanking {...this.props} />}
        {this.props.type === 'mselect' && <MetaMSelect {...this.props} />}
        <ToggleableQuestion {...this.props} />
      </React.Fragment>
    )
  }
}
export default connect(mapStateToProps)(ValidatedQuestion)
