import * as React from 'react'
import { Grid } from 'semantic-ui-react'

import Constants from '../../utils/constants'
import withSkippable from '../../enhancers/skippable'
import { CommentItemToggleableHook } from '../../enhancers/toggleable'
import { TextareaField } from '../formfields'

class MemoQuestionBody extends React.PureComponent {
  static defaultProps = {
    items: [],
    options: {},
  }

  render() {
    let memos = this.props.items.map(r => {
      return (
        <Grid.Row key={r.key} className="resurvey question body">
          <TextareaField
            name={r.key}
            key={`item-${r.jsonref}`}
            questionName={this.props.name}
            label={r.value}
            parentDisabled={this.props.disabled}
            hasComment={r.has_comment}
            jsonref={r.jsonref}
            currentPageName={this.props.currentPageName}
            maxlength={this.props.options.maxlength || Constants.DEFAULTS.MEMO_MAX_LENGTH}
            questionSkipped={this.props.questionSkipped}
          />
          {r.has_comment && (
            <CommentItemToggleableHook
              currentPageName={this.props.currentPageName}
              questionName={this.props.name}
              name={r.key + '_comment'}
              jsonref={r.jsonref + '_comment'}
            />
          )}
        </Grid.Row>
      )
    })

    if (this.props.reverseItems) memos.reverse()
    return memos
  }
}

export default withSkippable(MemoQuestionBody)
